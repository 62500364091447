import { format } from "date-fns";
import { enUS, fr, nl, it, de } from "date-fns/locale";

export const setFocus = (className: string) => (document.querySelector(`.${className}`) as any).focus();

export const formatLocalizedDate = (date: Date, pattern: string, localeString?: "en" | "fr" | "nl" | "it" | "de") => {
    let locale;
    switch (localeString) {
        case "de":
            locale = de;
            break;
        case "fr":
            locale = fr;
            break;
        case "it":
            locale = it;
            break;
        case "nl":
            locale = nl;
            break;
        default:
            locale = enUS;
            break;
    }
    return format(date, pattern, { locale: locale ?? enUS });
};

export function checkEmpty(value: string | undefined, fallback: string): string {
    if (value && value.length > 0) {
        return value;
    }
    return fallback;
}
