var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { getCurrentSession } from "../utils/login.utils";
import axios from "axios";
export function get(url, session, params = {}, isFile = false) {
    var _a;
    return __awaiter(this, void 0, void 0, function* () {
        const headers = yield getHeaders(session, params);
        try {
            const response = yield axios.get(url, Object.assign(Object.assign({}, headers), { responseType: isFile ? "blob" : "json" }));
            if (response.status !== 200) {
                throw response;
            }
            return response.data;
        }
        catch (e) {
            if ((_a = e === null || e === void 0 ? void 0 : e.response) === null || _a === void 0 ? void 0 : _a.data) {
                throw e.response.data;
            }
            else {
                throw e;
            }
        }
    });
}
export function getHeaders(session, params = {}) {
    return __awaiter(this, void 0, void 0, function* () {
        const currentSession = !session ? yield getCurrentSession() : session;
        return {
            headers: Object.assign(Object.assign(Object.assign({}, (currentSession ? { Authorization: "Bearer " + currentSession.accessToken } : {})), { "Content-Type": "application/json" }), params)
        };
    });
}
export function del(url) {
    var _a;
    return __awaiter(this, void 0, void 0, function* () {
        const headers = yield getHeaders();
        const response = yield axios.delete(url, Object.assign(Object.assign({}, headers), { responseType: "json" }));
        try {
            if (response.status !== 200 && response.status !== 204) {
                //204 = No content
                throw response;
            }
            return response.data;
        }
        catch (e) {
            if ((_a = e === null || e === void 0 ? void 0 : e.response) === null || _a === void 0 ? void 0 : _a.data) {
                throw e.response.data;
            }
            else {
                throw e;
            }
        }
    });
}
export function post(url, body = {}, session, params = {}) {
    var _a;
    return __awaiter(this, void 0, void 0, function* () {
        const headers = yield getHeaders(session, params);
        try {
            const response = yield axios.post(url, body, Object.assign(Object.assign({}, headers), { responseType: "json" }));
            if (response.status !== 200 && response.status !== 201) {
                throw response;
            }
            return response.data;
        }
        catch (e) {
            if ((_a = e === null || e === void 0 ? void 0 : e.response) === null || _a === void 0 ? void 0 : _a.data) {
                throw e.response.data;
            }
            else {
                throw e;
            }
        }
    });
}
export function put(url, body = {}, session) {
    var _a;
    return __awaiter(this, void 0, void 0, function* () {
        const headers = yield getHeaders(session);
        try {
            const response = yield axios.put(url, body, Object.assign(Object.assign({}, headers), { responseType: "json" }));
            if (response.status !== 200 && response.status !== 201) {
                throw response;
            }
            return response.data;
        }
        catch (e) {
            if ((_a = e === null || e === void 0 ? void 0 : e.response) === null || _a === void 0 ? void 0 : _a.data) {
                throw e.response.data;
            }
            else {
                throw e;
            }
        }
    });
}
export function patch(url, body = {}) {
    var _a;
    return __awaiter(this, void 0, void 0, function* () {
        const headers = yield getHeaders();
        try {
            const response = yield axios.patch(url, body, Object.assign(Object.assign({}, headers), { responseType: "json" }));
            if (response.status !== 200 && response.status !== 201) {
                throw response;
            }
            return response.data;
        }
        catch (e) {
            if ((_a = e === null || e === void 0 ? void 0 : e.response) === null || _a === void 0 ? void 0 : _a.data) {
                throw e.response.data;
            }
            else {
                throw e;
            }
        }
    });
}
