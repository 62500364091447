import { inject, observer } from "mobx-react";
import { HostActivity, Request, RequestResponse, RequestStatus } from "@hoverflo/shared";
import classNames from "classnames";
import { Stores } from "../../../models";
import React, { useCallback, useMemo } from "react";
import { nanoid } from "nanoid/non-secure";
import { CancelRounded } from "@mui/icons-material";

interface InboxProps extends Stores {
    requests: Array<RequestResponse>;
    filterStatus?: RequestStatus;
    setFilterStatus: (status: RequestStatus) => void;
    setFocused: (request: Request) => void;
    focused?: Request;
}

export const Inbox = inject("rootStore")(
    observer((props: InboxProps) => {
        const { requestStore, searchStore, loadingStore, hostStore } = props.rootStore;

        const uniqueStatus = useMemo(() => Array.from(new Set(props.requests.map(r => r.status))), [props.requests]);

        const findHostActivity = useCallback(
            (activityId: string, locationId: string): HostActivity | undefined =>
                hostStore.host?.activities
                    ?.filter(ac => ac.activity?.id === activityId)
                    .map(
                        ac => ({ ...ac, locations: ac.locations.filter(loc => loc.id === locationId) } as HostActivity)
                    )?.[0],
            [hostStore.host]
        );

        const requests = useMemo(() => {
            return [
                ...props.requests.map(
                    item =>
                        ({
                            ...item,
                            user: item.userDto,
                            hostActivity: {
                                ...findHostActivity(item.selectedActivityId, item.selectedLocationId),
                                host: hostStore.host?.generalInfo
                            } as HostActivity
                        } as Request)
                )
            ];
        }, [props.requests, requestStore.hosts]);

        return (
            <>
                <div className="request-overview-header">
                    <h2>INBOX</h2>
                </div>
                <div className="request-overview-info">
                    <div className="status-type-col">
                        {uniqueStatus.map(status => (
                            <div
                                key={`status_${status}`}
                                className={`${getThemeColor(status)} status-name`}
                                onClick={() => {
                                    if (props.filterStatus === status) {
                                        props.setFilterStatus(undefined);
                                    } else {
                                        props.setFilterStatus(status);
                                    }
                                }}
                            >
                                {status}
                                {props.filterStatus === status && (
                                    <CancelRounded fontSize="small" titleAccess="Clear" style={{ marginTop: "8px" }} />
                                )}
                            </div>
                        ))}
                    </div>
                    <div className="request-info-col">
                        {requests.map(request => {
                            const selectedLocation = request.hostActivity?.locations?.[0];
                            return (
                                <div
                                    className={classNames("container flex-row", {
                                        selected: request.id === props.focused?.id
                                    })}
                                    key={request.id ?? nanoid()}
                                    onClick={() => {
                                        loadingStore.triggerLoading();
                                        props.setFocused(request);
                                    }}
                                    title="Select this booking request"
                                >
                                    {(request.status === RequestStatus.OFFER ||
                                        request.status === RequestStatus.COMPLETED) && (
                                        <div className="request-status">
                                            {request.status === RequestStatus.OFFER && <div className="badge">1</div>}
                                            {request.status === RequestStatus.COMPLETED && (
                                                <div>
                                                    <img src="assets/Icons/Icon_lock.svg" className="icon_style_lock" />
                                                </div>
                                            )}
                                        </div>
                                    )}
                                    <div className="request-details flex-row">
                                        <div className="detail-section">
                                            <div className="h6">{request.user?.displayName}</div>
                                            <div className="h7">
                                                {selectedLocation?.location?.address?.city},{" "}
                                                {selectedLocation?.location?.address?.country}
                                            </div>
                                            <div className="flex-row">
                                                <div className="h8">DATE: </div>
                                                {request.requestDate && (
                                                    <div className="h9">
                                                        {new Date(request.requestDate).toLocaleDateString()}
                                                    </div>
                                                )}
                                            </div>
                                            <div className="flex-row">
                                                <div className="h8">PRODUCT: </div>
                                                <div className="h9">{request.product}</div>
                                            </div>
                                            <div className="flex-row">
                                                <div className="h8">PERSON: </div>
                                                <div className="h9">{request.numberOfPeople}</div>
                                            </div>
                                            <div className="flex-row">
                                                <div className="h8">MATERIAL: </div>
                                                <div className="h9">{request.material ? "Yes incl." : "No"}</div>
                                            </div>
                                        </div>
                                        <div
                                            className={classNames({
                                                "button-section disable_all_content":
                                                    request.status === RequestStatus.COMPLETED,
                                                "button-section": request.status !== RequestStatus.COMPLETED
                                            })}
                                        >
                                            <div
                                                className={
                                                    request.status === RequestStatus.COMPLETED
                                                        ? "btn btn-status-gray-rounded"
                                                        : "btn btn-status-white-rounded"
                                                }
                                            >
                                                {request.status}
                                            </div>
                                            {request.price !== undefined && request.price > 0 && (
                                                <div>
                                                    <button
                                                        className={
                                                            request.status === RequestStatus.COMPLETED
                                                                ? "btn btn-status-gray-rounded"
                                                                : "btn btn-status-white-rounded"
                                                        }
                                                    >
                                                        € {request.price}
                                                    </button>
                                                </div>
                                            )}
                                            {request.status === RequestStatus.OFFER && (
                                                <div className="expire-text">Expires in 24 hours</div>
                                            )}
                                            {request.status === RequestStatus.UNSENT && (
                                                <div>
                                                    <button
                                                        className="btn btn-remove-white-rounded"
                                                        onClick={() => {
                                                            if (
                                                                window.confirm("Are sure you want to delete this item?")
                                                            ) {
                                                                const result = searchStore.selectedResults.find(
                                                                    r =>
                                                                        r.idHostActivityLocation ===
                                                                            request.hostActivity.locations[0].id &&
                                                                        r.host.id === request.hostActivity.host.id
                                                                );
                                                                if (result) {
                                                                    searchStore.deselectResult(result);
                                                                }
                                                            }
                                                        }}
                                                    >
                                                        REMOVE
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </>
        );
    })
);

function getThemeColor(status: RequestStatus): string {
    switch (status) {
        case RequestStatus.REQUESTED:
            return "earth";
        case RequestStatus.OFFER:
            return "alpine";
        case RequestStatus.CONFIRMED:
            return "water";
        case RequestStatus.COMPLETED:
            return "air";
        case RequestStatus.CANCELLED:
            return "host";
        default:
            return "water";
    }
}
