import { inject, observer } from "mobx-react";
import { Stores } from "../../../models";
import { Formik } from "formik";
import { PaymentInfoDto } from "@hoverflo/shared/api/models/host";
import { TextBox } from "../../common/TextBox";
import { updatePaymentInfo } from "@hoverflo/shared/api/services/host.service";
import { errorHandler } from "../../../utils/errorHandler";

interface HostPaymentProps extends Stores {
    disabled: boolean;
    onSuccess: () => void;
}

export const HostPayment = inject("rootStore")(
    observer((props: HostPaymentProps) => {
        const { hostStore, loadingStore, messagesStore } = props.rootStore;

        const onSubmit = async (values: Partial<PaymentInfoDto>) => {
            try {
                loadingStore.triggerLoading();
                const hostPayment = await updatePaymentInfo(hostStore.host.generalInfo!, values as PaymentInfoDto);
                hostStore.updatePaymentInfo(hostPayment);
                loadingStore.stopLoading();
                messagesStore.success("Payment details successfully saved");
                props.onSuccess();
            } catch (e) {
                loadingStore.stopLoading();
                messagesStore.error(e.message);
                await errorHandler(e, props.rootStore);
            }
        };

        return (
            <Formik
                initialValues={{
                    paymentCompanyName: hostStore.host.paymentInfo?.paymentCompanyName ?? "",
                    bankAccount: hostStore.host.paymentInfo?.bankAccount ?? "",
                    bicSwift: hostStore.host.paymentInfo?.bicSwift ?? "",
                    invoiceCompanyName: hostStore.host.paymentInfo?.invoiceCompanyName ?? "",
                    invoiceAddress: hostStore.host.paymentInfo?.invoiceAddress ?? "",
                    invoiceCountry: hostStore.host.paymentInfo?.invoiceCountry ?? "",
                    vatNumber: hostStore.host.paymentInfo?.vatNumber ?? "",
                    chamberOfCommerceNumber: hostStore.host.paymentInfo?.chamberOfCommerceNumber ?? ""
                }}
                validate={values => {
                    let errors: PaymentInfoDto = {} as PaymentInfoDto;
                    if (!values.paymentCompanyName) {
                        errors.paymentCompanyName = "Required";
                    }
                    if (!values.bankAccount) {
                        errors.bankAccount = "Required";
                    }
                    if (!values.bicSwift) {
                        errors.bicSwift = "Required";
                    }
                    if (!values.invoiceCompanyName) {
                        errors.invoiceCompanyName = "Required";
                    }
                    if (!values.invoiceAddress) {
                        errors.invoiceAddress = "Required";
                    }
                    if (!values.invoiceCountry) {
                        errors.invoiceCountry = "Required";
                    }
                    if (!values.vatNumber) {
                        errors.vatNumber = "Required";
                    }
                    if (!values.chamberOfCommerceNumber) {
                        errors.chamberOfCommerceNumber = "Required";
                    }
                    return errors;
                }}
                enableReinitialize={true}
                onSubmit={onSubmit}
            >
                {({ values, errors, handleChange, handleBlur, handleSubmit }) => (
                    <form className="payment-form" onSubmit={handleSubmit}>
                        <h1>Payment Information:</h1>
                        <hr />
                        <h2>Pay out details:</h2>
                        <div className="flex-row">
                            <label className="middle-align">Company Name</label>
                            <TextBox
                                disabled={props.disabled}
                                rounded={false}
                                invalid={errors && !!errors.paymentCompanyName}
                                name="paymentCompanyName"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.paymentCompanyName}
                                placeholder="Company name"
                            />
                        </div>
                        <div className="flex-row">
                            <label className="middle-align">Bank Account</label>
                            <TextBox
                                disabled={props.disabled}
                                rounded={false}
                                invalid={errors && !!errors.bankAccount}
                                name="bankAccount"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.bankAccount}
                                placeholder="Bank account number"
                            />
                        </div>
                        <div className="flex-row">
                            <label className="middle-align">BIC / SWIFT</label>
                            <TextBox
                                disabled={props.disabled}
                                rounded={false}
                                invalid={errors && !!errors.bicSwift}
                                name="bicSwift"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.bicSwift}
                                placeholder="BIC/Swift Number"
                            />
                        </div>
                        <hr />
                        <h2>Invoice details</h2>
                        <div className="flex-row">
                            <label className="middle-align">Company Name</label>
                            <TextBox
                                disabled={props.disabled}
                                rounded={false}
                                invalid={errors && !!errors.invoiceCompanyName}
                                name="invoiceCompanyName"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.invoiceCompanyName}
                                placeholder="Invoice Company Name"
                            />
                        </div>
                        <div className="flex-row">
                            <label className="middle-align">Address</label>
                            <TextBox
                                disabled={props.disabled}
                                rounded={false}
                                invalid={errors && !!errors.invoiceAddress}
                                name="invoiceAddress"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.invoiceAddress}
                                placeholder="Invoice Address"
                            />
                        </div>
                        <div className="flex-row">
                            <label className="middle-align">Country</label>
                            <TextBox
                                disabled={props.disabled}
                                rounded={false}
                                invalid={errors && !!errors.invoiceCountry}
                                name="invoiceCountry"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.invoiceCountry}
                                placeholder="Invoice Country"
                            />
                        </div>
                        <div className="flex-row">
                            <label className="middle-align">Vat Number</label>
                            <TextBox
                                disabled={props.disabled}
                                rounded={false}
                                invalid={errors && !!errors.vatNumber}
                                name="vatNumber"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.vatNumber}
                                placeholder="VAT Number"
                            />
                        </div>
                        <div className="flex-row">
                            <label className="middle-align">Chamber of commerce no.</label>
                            <TextBox
                                disabled={props.disabled}
                                rounded={false}
                                invalid={errors && !!errors.chamberOfCommerceNumber}
                                name="chamberOfCommerceNumber"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.chamberOfCommerceNumber}
                                placeholder="Chamber of commerce number"
                            />
                        </div>
                        <div className="save">
                            <button
                                disabled={props.disabled || Object.keys(errors).length > 0}
                                type="submit"
                                className="btn btn-orange-rounded"
                            >
                                SAVE
                            </button>
                        </div>
                    </form>
                )}
            </Formik>
        );
    })
);
