import { OptionsObject, SnackbarKey, SnackbarMessage } from "notistack";

export enum MessageType {
    SUCCESS = "success",
    INFO = "info",
    WARNING = "warning",
    ERROR = "error"
}

export class MessagesStore {
    messageHandler: (message: SnackbarMessage, options?: OptionsObject) => SnackbarKey;

    setMessageHandler(messageHandler: (message: SnackbarMessage, options?: OptionsObject) => SnackbarKey) {
        this.messageHandler = messageHandler;
    }

    success(message: string) {
        this.messageHandler(message, {
            variant: MessageType.SUCCESS
        });
    }

    info(message: string) {
        this.messageHandler(message, {
            variant: MessageType.INFO
        });
    }

    error(message: string) {
        this.messageHandler(message, {
            variant: MessageType.ERROR
        });
    }

    warning(message: string) {
        this.messageHandler(message, {
            variant: MessageType.WARNING
        });
    }
}
