import { Request, RequestStatus } from "@hoverflo/shared";
import React, { useState } from "react";
import classNames from "classnames";
import { ChatView } from "../../layout/chat/ChatView";
import { Map } from "./Map";
import { NewRequest } from "./NewRequest";

interface RequestDetailsProps {
    request: Request;
    onSubmitRequest: (request: Request) => void;
}

export function RequestDetails(props: RequestDetailsProps) {
    const [tab, setTab] = useState("chat");
    return (
        <>
            <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item">
                    <a
                        className={classNames("nav-link", { active: tab === "chat" })}
                        id="chat-tab"
                        data-toggle="tab"
                        aria-selected="true"
                        onClick={() => setTab("chat")}
                        style={{ cursor: "pointer" }}
                    >
                        CHAT
                    </a>
                </li>
                <li className="nav-item">
                    <a
                        className={classNames("nav-link", { active: tab === "map" })}
                        id="map-tab"
                        data-toggle="tab"
                        aria-selected="false"
                        onClick={() => setTab("map")}
                        style={{ cursor: "pointer" }}
                    >
                        MAP
                    </a>
                </li>
            </ul>
            <div className="tab-content" id="myTabContent">
                <div
                    className={classNames("tab-pane chat fade", { "show active": tab === "chat" })}
                    id="chat"
                    role="tabpanel"
                    aria-labelledby="chat-tab"
                >
                    {props.request && props.request.status === RequestStatus.UNSENT && (
                        <NewRequest request={props.request} onSubmitRequest={props.onSubmitRequest} />
                    )}
                    {props.request && props.request.status !== RequestStatus.UNSENT && (
                        <ChatView request={props.request} />
                    )}
                </div>
                <div
                    className={classNames("tab-pane map fade", { "show active": tab === "map" })}
                    id="map"
                    role="tabpanel"
                    aria-labelledby="map-tab"
                >
                    {tab === "map" && props.request && (
                        <div className="map">
                            <Map request={props.request}></Map>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}
