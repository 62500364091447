import React, { Suspense, useRef } from "react";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import { inject, observer, Provider } from "mobx-react";
import { syncHistoryWithStore } from "mobx-react-router";

import "./App.scss";
import { Routes } from "./components/Routes";
import { Header } from "./components/layout/header/Header";
import { RootStore } from "./stores/root.store";
import { LazyLoading, Loading } from "./components/utils/Loading";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en.json";
import nl from "javascript-time-ago/locale/nl.json";
import de from "javascript-time-ago/locale/de.json";
import fr from "javascript-time-ago/locale/fr.json";
import it from "javascript-time-ago/locale/it.json";
import { closeSnackbar, SnackbarProvider } from "notistack";
import { MessagesCenter } from "./components/utils/MessagesCenter";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import { ReactKeycloakProvider } from "@react-keycloak/web";
import keycloak from "./utils/keycloak";
import { Stores } from "./models";
import classNames from "classnames";
import { ThemeType } from "@hoverflo/shared";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { SessionChecker } from "./components/utils/SessionChecker";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { themeColors } from "@hoverflo/shared/api/utils/constants";

TimeAgo.addDefaultLocale(en);
TimeAgo.addLocale(nl);
TimeAgo.addLocale(de);
TimeAgo.addLocale(fr);
TimeAgo.addLocale(it);

export function App() {
    return (
        <Suspense fallback={<LazyLoading />}>
            <Home />
        </Suspense>
    );
}

export function Home() {
    const rootStore = useRef(new RootStore());
    const browserHistory = useRef(createBrowserHistory());
    const history = syncHistoryWithStore(browserHistory.current, rootStore.current.routerStore);

    return (
        <ReactKeycloakProvider
            authClient={keycloak}
            initOptions={{
                pkceMethod: "S256",
                onLoad: "check-sso"
            }}
        >
            <Provider rootStore={rootStore.current}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Router history={history}>
                        <SnackbarProvider
                            maxSnack={5}
                            autoHideDuration={10000}
                            action={snackbarId => (
                                <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                        closeSnackbar(snackbarId);
                                    }}
                                >
                                    <CloseIcon fontSize="inherit" />
                                </IconButton>
                            )}
                        >
                            <Loading />
                            <MessagesCenter />
                            <ThemedApp />
                        </SnackbarProvider>
                    </Router>
                </LocalizationProvider>
            </Provider>
        </ReactKeycloakProvider>
    );
}

export const ThemedApp = inject("rootStore")(
    observer((props: Stores) => {
        const { searchStore, routingStore, routerStore } = props.rootStore;
        const theme = createTheme({
            palette: {
                primary: {
                    main: themeColors.EARTH.background
                },
                secondary: {
                    main: themeColors.EARTH.backgroundSecondary
                }
            }
        });
        return (
            <ThemeProvider theme={theme}>
                <div
                    className={classNames("app-background-black", {
                        // "app-background-blue": searchStore.activity?.type === ThemeType.WATER,
                        // "app-background-green": searchStore.activity?.type === ThemeType.EARTH,
                        // "app-background-orange": searchStore.activity?.type === ThemeType.ALPINE,
                        // "app-background-creme": searchStore.activity?.type === ThemeType.AIR,
                        "app-background-yellow":
                            routingStore.route === "/become-host" || routerStore.location.pathname === "/become-host",
                        "explore-color":
                            routingStore.route === "/explore" || routerStore.location.pathname === "/explore",
                        "activities-color":
                            routingStore.route === "/activities" || routerStore.location.pathname === "/activities",
                        "locations-color":
                            routingStore.route === "/locations" || routerStore.location.pathname === "/locations",
                        "bookings-color":
                            routingStore.route === "/bookings" || routerStore.location.pathname === "/bookings",
                        "host-profile":
                            routingStore.route === "/host-profile" || routerStore.location.pathname === "/host-profile",
                        "host-offers":
                            routingStore.route === "/host-offers" || routerStore.location.pathname === "/host-offers",
                        "host-bookings":
                            routingStore.route === "/host-bookings" ||
                            routerStore.location.pathname === "/host-bookings"
                    })}
                >
                    <SessionChecker />
                    <Header />
                    <Routes />
                </div>
            </ThemeProvider>
        );
    })
);

export default App;
