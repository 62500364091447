import { inject, observer } from "mobx-react";
import { Stores } from "../../../models";

import "./SearchBar.scss";
import { getActivities } from "@hoverflo/shared/api/services/activity.service";
import React, { useEffect } from "react";
import { SearchActivities } from "./SearchActivities";
import { SearchLocations } from "./SearchLocations";
import { SearchRange } from "./SearchRange";
import { Fab } from "@mui/material";
import { findLocations } from "@hoverflo/shared/api/services/search.service";
import { errorHandler } from "../../../utils/errorHandler";

export const SearchBar = inject("rootStore")(
    observer((props: Stores & { hideTitle?: boolean }) => {
        const { searchStore, activityStore, loadingStore, messagesStore, routingStore } = props.rootStore;

        const onSearch = () => {
            if (searchStore.activity && searchStore.location?.coordination && searchStore.distance) {
                findLocations(searchStore.activity, searchStore.location?.coordination, searchStore.distance)
                    .then(results => {
                        searchStore.clearSelectedResults();
                        const resultsFiltered = results.filter(r => !!r.location?.coordination?.latitude);
                        searchStore.setSearchResults(resultsFiltered);
                        if (resultsFiltered.length === 0) {
                            messagesStore.warning("No hosts found");
                        } else {
                            routingStore.pushRoute("/");
                        }
                    })
                    .catch(async e => {
                        await errorHandler(e, props.rootStore);
                        messagesStore.error(e.message ?? "Error while retrieving results from search");
                    });
            }
        };

        useEffect(() => {
            if (activityStore.activities.length === 0) {
                loadingStore.triggerLoading();
                getActivities()
                    .then(activities => {
                        activities.sort((a, b) => a.name.localeCompare(b.name));
                        activityStore.setActivities(activities);
                        loadingStore.stopLoading();
                    })
                    .catch(async e => {
                        await errorHandler(e, props.rootStore);
                        loadingStore.stopLoading();
                        messagesStore.error(e.message);
                    });
            }
        }, [activityStore, loadingStore, messagesStore]);

        return (
            <div className="box-column">
                {!props.hideTitle && <div className="header-font-43 text-center">READY FOR ADVENTURE?</div>}
                <div className="box-row search-container">
                    <SearchActivities />
                    <SearchLocations />
                    <SearchRange />
                    <Fab className="btn-search" onClick={() => onSearch()}>
                        <img src="assets/Icons/Icon_search.svg" />
                    </Fab>
                </div>
            </div>
        );
    })
);
