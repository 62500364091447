import { HostActivity, MessageType, Request, RequestStatus } from "@hoverflo/shared";
import TextField from "@mui/material/TextField";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import React, { useCallback, useLayoutEffect, useState } from "react";
import { getRequests, newRequest } from "@hoverflo/shared/api/services/request.service";
import { postMessage } from "@hoverflo/shared/api/services/chat.service";
import { inject, observer } from "mobx-react";
import { Stores } from "../../../models";
import { errorHandler } from "../../../utils/errorHandler";

interface NewRequestProps extends Stores {
    request: Request;
    onSubmitRequest: (request: Request) => void;
}

export const NewRequest = inject("rootStore")(
    observer((props: NewRequestProps) => {
        const { loadingStore, sessionStore, messagesStore, requestStore, searchStore } = props.rootStore;
        const [activity, setActivity] = useState<string | undefined>(props.request.hostActivity.activity?.id);
        const [product, setProduct] = useState("");
        const [location, setLocation] = useState("");
        const [person, setPerson] = useState<number | undefined>(undefined);
        const [note, setNote] = useState<string>("");
        const [date, setDate] = useState<Date>(new Date());
        const activities = [
            {
                label: props.request.hostActivity?.activity?.name.toUpperCase(),
                value: props.request.hostActivity?.activity?.id
            }
        ];
        const products = [
            { label: "Lesson".toUpperCase(), value: "lesson" },
            { label: "Material".toUpperCase(), value: "material" },
            { label: "Lesson & Material".toUpperCase(), value: "both" }
        ];
        const locations = props.request?.hostActivity?.locations?.map(location => ({
            label: location.location?.address.fullAddress.toUpperCase(),
            value: location.id
        }));
        const persons = Array.from({ length: 10 }).map((_, index) => ({
            label: (index + 1).toString(),
            value: index + 1
        }));

        const findHostActivity = useCallback(
            (hostId: string, activityId: string, locationId: string): HostActivity | undefined =>
                requestStore?.hosts
                    ?.find(host => host.generalInfo?.id === hostId)
                    ?.activities?.filter(ac => ac.activity?.id === activityId)
                    .map(
                        ac => ({ ...ac, locations: ac.locations.filter(loc => loc.id === locationId) } as HostActivity)
                    )?.[0],
            [requestStore.hosts]
        );

        const onSubmit = async () => {
            try {
                if (sessionStore.logged) {
                    loadingStore.triggerLoading();
                    const selectedLocation = props.request?.hostActivity?.locations?.find(f => f.id === location);
                    if (selectedLocation) {
                        const request = await newRequest({
                            product,
                            requestDate: date,
                            lesson: product === "lesson" || product === "both",
                            material: product === "material" || product === "both",
                            price: 0,
                            status: RequestStatus.REQUESTED,
                            numberOfPeople: person ?? 0,
                            hostActivityLocationDto: selectedLocation
                        });
                        if (note) {
                            await postMessage({
                                body: note,
                                requestId: request.id,
                                receiver: props.request.hostActivity!.host!.id!,
                                type: MessageType.TEXT
                            });
                        }
                        const requests = await getRequests();
                        requestStore.setRequests(requests.content);
                        const selectedResult = searchStore.selectedResults.find(
                            r => r.idHostActivityLocation === props.request.hostActivity?.locations?.[0]?.id
                        );
                        if (selectedResult) {
                            searchStore.deselectResult(selectedResult);
                        }
                        const newSelectedRequest = requestStore.requests.find(r => r.id === request.id);
                        props.onSubmitRequest({
                            ...newSelectedRequest,
                            user: newSelectedRequest.userDto,
                            hostActivity: {
                                ...findHostActivity(
                                    newSelectedRequest.hostGeneralInfoDto.id,
                                    newSelectedRequest.selectedActivityId,
                                    newSelectedRequest.selectedLocationId
                                ),
                                host: requestStore.hosts.find(
                                    host => host.generalInfo?.id === newSelectedRequest.hostGeneralInfoDto.id
                                )?.generalInfo
                            } as HostActivity
                        } as Request);
                        messagesStore.success("Request successfully submitted.");
                    }
                    loadingStore.stopLoading();
                } else {
                    messagesStore.error("Please sign in before contact a host.");
                }
            } catch (e) {
                await errorHandler(e, props.rootStore);
                loadingStore.stopLoading();
                messagesStore.error("An error occurred while contacting host.");
            }
        };

        useLayoutEffect(() => {
            loadingStore.stopLoading();
        }, []);

        return (
            <div className="chat-request">
                <form>
                    <h1>START WITH SETTING UP YOUR REQUEST</h1>
                    <hr />
                    <div className="flex-row">
                        <label className="middle-align">Activities*</label>
                        <select
                            className="form-control"
                            value={activity}
                            onChange={event => setActivity(event.target.value)}
                        >
                            <option value={undefined}>Select</option>
                            {activities.map(ac => (
                                <option key={ac.value} value={ac.value}>
                                    {ac.label}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="flex-row">
                        <label className="middle-align">Product</label>
                        <select
                            className="form-control"
                            value={product}
                            onChange={event => setProduct(event.target.value)}
                        >
                            <option value={undefined}>Select</option>
                            {products.map(prod => (
                                <option key={prod.value} value={prod.value}>
                                    {prod.label}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="flex-row">
                        <label className="middle-align">Location</label>
                        <select
                            className="form-control"
                            value={location}
                            onChange={event => setLocation(event.target.value)}
                        >
                            <option value={undefined}>Select</option>
                            {locations?.map(loc => (
                                <option key={loc.value} value={loc.value}>
                                    {loc.label}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="flex-row">
                        <div className="flex-row left-side date-picker">
                            <label className="middle-align">Date*</label>
                            <DesktopDatePicker
                                inputFormat="MM/dd/yyyy"
                                value={date}
                                onChange={date => setDate(date)}
                                renderInput={params => (
                                    <TextField className="date-picker__input" variant="standard" {...params} />
                                )}
                            />
                        </div>

                        <div className="flex-row right-side">
                            <label className="middle-align">Persons*</label>
                            <select
                                className="form-control"
                                value={person}
                                onChange={event => setPerson(Number(event.target.value))}
                            >
                                <option value={undefined}>Select</option>
                                {persons.map(person => (
                                    <option key={person.value} value={person.value}>
                                        {person.label}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <h2>NOTE:</h2>
                    <div className="flex-row">
                        <textarea
                            rows={6}
                            cols={50}
                            placeholder="Type message..."
                            value={note}
                            onChange={event => setNote(event.target.value)}
                        ></textarea>
                    </div>
                    <h3>*required fields</h3>
                    <hr />
                    <div className="buttons">
                        <button
                            type="button"
                            className="btn btn-rounded"
                            disabled={!activity || !location || !product || !person || !date}
                            onClick={onSubmit}
                        >
                            SUBMIT REQUEST
                        </button>
                    </div>
                </form>
            </div>
        );
    })
);
