import { inject, observer } from "mobx-react";
import { Stores } from "../../../models";
import { setFocus } from "../../../utils";

const rangeList = [1, 2, 5, 10, 15, 30, 50, 100];

export const SearchRange = inject("rootStore")(
    observer((props: Stores) => {
        const { searchStore } = props.rootStore;

        return (
            <div className="range-field" onTouchStart={() => setFocus("range-field")} tabIndex={0} role="button">
                <div className="middle-align medium-text selected-item">+ {searchStore.distance} KM</div>
                <div className="dropdown-style">
                    <hr />
                    <div className="list-max-length">
                        <ul>
                            {rangeList.map(range => (
                                <li
                                    key={`${range}_KM`}
                                    onClick={() => {
                                        searchStore.setDistance(range);
                                    }}
                                    role="button"
                                    style={{ cursor: "pointer" }}
                                    title="Select range"
                                >
                                    + {range} KM
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        );
    })
);
