import { del, get, patch, post } from "./base.service";
import { config } from "../utils/constants";
export function getHosts() {
    return get(`${config.url}/api/host`);
}
export function getHost(id) {
    return get(`${config.url}/api/host/id/${id}`);
}
export function updateGeneralInfo(host) {
    return patch(`${config.url}/api/host/general-info`, host);
}
export function deleteImage(image) {
    return del(`${config.url}/api/host-image/${image.id}`);
}
export function getImages() {
    return get(`${config.url}/api/host-image`);
}
export function getImage(id) {
    return get(`${config.url}/api/host-image/${id}`);
}
export function addImage(image) {
    return post(`${config.url}/api/host-image`, Object.assign(Object.assign({}, image), { id: undefined }));
}
export function editImage(image) {
    return patch(`${config.url}/api/host-image/${image.id}`, image);
}
export function getTermsAndConditions(id) {
    return get(`${config.url}/api/host-terms-and-conditions/${id}`);
}
export function addTermsAndConditions(tc) {
    return post(`${config.url}/api/host-terms-and-conditions`, Object.assign(Object.assign({}, tc), { id: undefined }));
}
export function updateTermsAndConditions(tc) {
    return patch(`${config.url}/api/host-terms-and-conditions/${tc.id}`, tc);
}
export function deleteTermsAndConditions(tc) {
    return del(`${config.url}/api/host-terms-and-conditions/${tc.id}`);
}
export function updateActivity(host, activity) {
    return patch(`${config.url}/api/host/${host.id}/activity`, activity);
}
export function deleteActivity(activity) {
    return del(`${config.url}/api/host/activity/${activity.id}`);
}
export function updateActivities(host, activities) {
    return patch(`${config.url}/api/host/${host.id}/activities`, activities);
}
export function updatePaymentInfo(host, payment) {
    return patch(`${config.url}/api/host/${host.id}/payment-info`, payment);
}
export function getActivityLocation(id) {
    return get(`${config.url}/api/host-activity-location/${id}`);
}
export function addActivityLocation(location) {
    return post(`${config.url}/api/host-activity-location`, Object.assign(Object.assign({}, location), { id: undefined }));
}
export function editActivityLocation(location) {
    return patch(`${config.url}/api/host-activity-location/${location.id}`, location);
}
export function deleteActivityLocation(location) {
    return del(`${config.url}/api/host-activity-location/${location.id}`);
}
export function getLocation(id) {
    return get(`${config.url}/api/host-location/${id}`);
}
export function addLocation(location) {
    return post(`${config.url}/api/host-location`, Object.assign(Object.assign({}, location), { id: undefined }));
}
export function editLocation(location) {
    return patch(`${config.url}/api/host-location/${location.id}`, location);
}
export function deleteLocation(location) {
    return del(`${config.url}/api/host-location/${location.id}`);
}
export function deleteHost(id) {
    return del(`${config.url}/api/host/${id}`);
}
