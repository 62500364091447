var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { post } from "./base.service";
import { config, keycloakConfig } from "../utils/constants";
export function performLogin(loginRequest) {
    return __awaiter(this, void 0, void 0, function* () {
        const body = new URLSearchParams();
        body.append("username", loginRequest.email);
        body.append("password", navigator && navigator.product === "ReactNative"
            ? encodeURIComponent(loginRequest.password)
            : loginRequest.password);
        body.append("client_id", keycloakConfig.clientId);
        body.append("grant_type", "password");
        const response = yield post(`${config.urlKeyCloak}/realms/hoverflo-realm/protocol/openid-connect/token`, body, undefined, {
            Accept: "*/*",
            "Content-Type": "application/x-www-form-urlencoded"
        });
        if (!response) {
            throw new Error("Error while performing login");
        }
        return response;
    });
}
export function refreshToken(session) {
    return __awaiter(this, void 0, void 0, function* () {
        if (!session.refreshToken) {
            throw new Error("Refresh token not present");
        }
        const body = new URLSearchParams();
        body.append("refresh_token", session.refreshToken);
        body.append("client_id", keycloakConfig.clientId);
        body.append("grant_type", "refresh_token");
        const response = yield post(`${config.urlKeyCloak}/realms/hoverflo-realm/protocol/openid-connect/token`, body, undefined, {
            Accept: "*/*",
            "Content-Type": "application/x-www-form-urlencoded"
        });
        if (!response) {
            throw new Error("Error while performing login");
        }
        return response;
    });
}
/**
 * Logout users from normal login session and social logins (Checking idToken)
 * @param session: current session
 * @param socialLogoutFn: function to be executed when login was performed using social networks
 */
export function logout(session, socialLogoutFn) {
    return __awaiter(this, void 0, void 0, function* () {
        if (session.idToken) {
            socialLogoutFn === null || socialLogoutFn === void 0 ? void 0 : socialLogoutFn();
        }
        else if (session.refreshToken) {
            const body = new URLSearchParams();
            body.append("refresh_token", session.refreshToken);
            body.append("client_id", keycloakConfig.clientId);
            yield post(`${config.urlKeyCloak}/realms/hoverflo-realm/protocol/openid-connect/logout`, body, undefined, {
                Accept: "*/*",
                Authorization: "Bearer " + session.accessToken,
                "Content-Type": "application/x-www-form-urlencoded"
            });
        }
    });
}
/**
 * Verify the email address based on the token received by email
 * @param id
 */
export function verifyEmail(id) {
    return __awaiter(this, void 0, void 0, function* () {
        yield post(`${config.url}/api/register/email-verification?id=${id}`, {});
    });
}
/**
 * Triggers the forgot email from Keykloak
 * @param email
 */
export function forgotEmail(email) {
    return __awaiter(this, void 0, void 0, function* () {
        yield post(`${config.url}/api/register/reset-password?userName=${email}`, {});
    });
}
