import { inject, observer } from "mobx-react";
import { Stores } from "../../../models";
import Map, { MapRef, Marker, NavigationControl, Popup } from "react-map-gl";
import { mapStylesUrl } from "../../../utils/constants";
import { config } from "@hoverflo/shared/api/utils/constants";
import { useEffect, useRef, useState } from "react";
import { SearchResponse } from "@hoverflo/shared";

import "./SearchResultsMap.scss";
import { Rating } from "@mui/material";
import { useOnClickOutside } from "../../hooks/useOnClickOutside";

interface SearchResultsMapProps extends Stores {
    onSelectResult: (result: SearchResponse) => void;
}

export const SearchResultsMap = inject("rootStore")(
    observer((props: SearchResultsMapProps) => {
        const { searchStore } = props.rootStore;
        const [mapRef, setMapRef] = useState<MapRef>();
        const mapContainerRef = useRef<HTMLElement>();
        const [mapLoaded, setMapLoaded] = useState(false);
        const [showPopup, setShowPopup] = useState(false);
        const [selected, setSelected] = useState<SearchResponse>();
        const [centerLatitude, setCenterLatitude] = useState(52.527203013218184);
        const [centerLongitude, setCenterLongitude] = useState(5.695452185041854);
        const [bounds, setBounds] = useState({ s: 0, w: 0, n: 0, e: 0 });
        useOnClickOutside(mapContainerRef, () => setShowPopup(false));

        const theme = searchStore.activity?.type ?? "default";

        const onSelect = (result: SearchResponse) => {
            mapRef.flyTo({ center: [result.location.coordination.longitude, result.location.coordination.latitude] });
        };

        useEffect(() => {
            if (searchStore.searchResults.length > 0) {
                const n = Math.max(...searchStore.searchResults.map(h => h.location.coordination?.longitude ?? 0));
                const e = Math.max(...searchStore.searchResults.map(h => h.location.coordination?.latitude ?? 0));
                const s = Math.min(...searchStore.searchResults.map(h => h.location.coordination?.longitude ?? 0));
                const w = Math.min(...searchStore.searchResults.map(h => h.location.coordination?.latitude ?? 0));
                const latCenter =
                    searchStore.searchResults
                        .map(h => h.location.coordination?.latitude ?? 0)
                        .reduce((prev, curr) => prev + curr, 0) / searchStore.searchResults.length;
                const lngCenter =
                    searchStore.searchResults
                        .map(h => h.location.coordination?.longitude ?? 0)
                        .reduce((prev, curr) => prev + curr, 0) / searchStore.searchResults.length;
                setCenterLatitude(latCenter);
                setCenterLongitude(lngCenter);
                setBounds({ n, e, s, w });
                if (mapRef) {
                    mapRef.setCenter([lngCenter, latCenter]);
                    mapRef.fitBounds(
                        [
                            [s, w],
                            [n, e]
                        ],
                        { padding: 25 }
                    );
                }
            }
        }, [searchStore.searchResults, mapRef]);

        useEffect(() => {
            if (mapRef && !mapLoaded) {
                setTimeout(() => {
                    // Force the map to repaint after load for the first time
                    window.dispatchEvent(new Event("resize"));
                    setMapLoaded(true);
                    if (centerLatitude > 0 && centerLongitude > 0) {
                        mapRef.setCenter([centerLongitude, centerLatitude]);
                    }
                    if (bounds.s > 0 && bounds.w > 0 && bounds.n > 0 && bounds.e > 0) {
                        mapRef.fitBounds(
                            [
                                [bounds.s, bounds.w],
                                [bounds.n, bounds.e]
                            ],
                            { padding: 25 }
                        );
                    }
                }, 1000);
            }
        }, [mapRef, centerLatitude, centerLongitude, bounds]);

        return (
            <Map
                ref={r => {
                    if (r && !mapRef) {
                        setMapRef(r);
                        mapContainerRef.current = r.getCanvasContainer();
                    }
                }}
                mapStyle={mapStylesUrl["default"]}
                mapboxAccessToken={config.mapboxKey}
                initialViewState={{ latitude: centerLatitude, longitude: centerLongitude, zoom: 5 }}
                scrollZoom={false}
                maxZoom={15}
                onClick={e => {
                    if (String(e.originalEvent.target).includes("HTMLCanvasElement")) {
                        setShowPopup(false);
                    }
                }}
            >
                {showPopup && selected && (
                    <Popup
                        longitude={selected.location.coordination.longitude}
                        latitude={selected.location.coordination.latitude}
                        anchor="bottom"
                        onClose={() => {
                            setShowPopup(false);
                            setSelected(undefined);
                        }}
                        closeOnClick={false}
                        closeOnMove={false}
                        offset={[0, searchStore.isSelected(selected) ? -42 : -16]}
                    >
                        <a
                            href={`https://maps.google.com/?q=${selected.host.name}&ll=${selected.location.coordination.latitude},${selected.location.coordination.longitude}`}
                            target="_blank"
                        >
                            <h2 className="marker-title">{selected.host.name}</h2>
                        </a>
                        <Rating value={selected.host.googleUserRatingsTotal ?? 0} color="#ffc107" disabled />
                        <h3 className="marker-reviews">{selected.host.googleUserRatingsTotal ?? 0} reviews</h3>
                    </Popup>
                )}
                {searchStore.searchResults.map(result => (
                    <Marker
                        key={result.idHostActivityLocation}
                        longitude={result.location.coordination.longitude}
                        latitude={result.location.coordination.latitude}
                        anchor="bottom"
                        offset={searchStore.isSelected(result) ? [0, -4] : [0, 0]}
                        onClick={() => {
                            setSelected(result);
                            setShowPopup(true);
                            onSelect(result);
                            if (!searchStore.isSelected(result)) {
                                searchStore.selectResult(result);
                                props.onSelectResult(result);
                            } else {
                                searchStore.deselectResult(result);
                                setShowPopup(false);
                            }
                        }}
                        style={{ cursor: "pointer" }}
                    >
                        {searchStore.isSelected(result) && (
                            <img src="/assets/Icons/marker.svg" width={32} alt="Marker" />
                        )}
                        {!searchStore.isSelected(result) && (
                            <img
                                src="/assets/Icons/Icon_marker_white.svg"
                                width={16}
                                alt="White marker"
                                onMouseOver={event => {
                                    const element = event.target as HTMLImageElement;
                                    element.src = "/assets/Icons/Icon_marker_orange.svg";
                                    element.width = 20;
                                }}
                                onMouseOut={event => {
                                    const element = event.target as HTMLImageElement;
                                    element.src = "/assets/Icons/Icon_marker_white.svg";
                                    element.width = 16;
                                }}
                            />
                        )}
                    </Marker>
                ))}
                <NavigationControl />
            </Map>
        );
    })
);
