import { action, makeAutoObservable, observable } from "mobx";
import { nanoid } from "nanoid/non-secure";
import { HostActivity, HostActivityLocation } from "@hoverflo/shared/api/models";
import { CompanyData } from "../components/screens/become-host/Step1";

export class OnboardingStore {
    @observable.deep activities: HostActivity[] = [];
    @observable companyData: CompanyData = {} as CompanyData;
    @observable visibleSteps: number = 0;

    constructor() {
        makeAutoObservable(this);
    }

    @action clean() {
        this.activities = [];
        this.companyData = {} as CompanyData;
        this.visibleSteps = 0;
    }

    @action setCompanyData(companyData: CompanyData) {
        this.companyData = companyData;
    }

    @action setVisibleSteps(step: number) {
        this.visibleSteps = step;
    }

    @action setActivities(activities: HostActivity[]) {
        this.activities = activities;
    }

    @action addActivity(activity: HostActivity) {
        this.activities.push({ id: nanoid(), ...activity, isTemporary: true } as HostActivity);
    }

    @action addLocation(activity: HostActivity) {
        const targetActivity = this.activities.find(ac => ac.id === activity.id);
        if (targetActivity) {
            const homeSpotExists = targetActivity.locations.some(l => !!l.mainSpot);
            targetActivity.locations.push({
                id: nanoid(),
                location: undefined,
                mainSpot: !homeSpotExists,
                isTemporary: true
            } as HostActivityLocation);
        }
    }

    @action updateActivity(activity: HostActivity) {
        const index = this.activities.findIndex(ac => ac.id === activity.id);
        if (index > -1) {
            this.activities[index] = { ...activity };
        }
    }

    @action updateLocation(activity: HostActivity, location: HostActivityLocation) {
        const index = this.activities.findIndex(ac => ac.id === activity.id);
        const indexLocation = activity.locations.findIndex(loc => loc.id === location.id);
        if (indexLocation > -1) {
            activity.locations[indexLocation] = location;
        }
        if (index > -1) {
            this.activities[index] = { ...activity };
        }
    }

    @action removeLocation(activity: HostActivity, location: HostActivityLocation) {
        const activities = this.activities;
        const index = activities.findIndex(ac => ac.id === activity.id);
        const indexLocation = activity.locations.findIndex(loc => loc.id === location.id);
        if (indexLocation > -1) {
            activity.locations.splice(indexLocation, 1);
        }
        if (index > -1) {
            activities[index] = { ...activity };
            this.activities = [...activities];
        }
    }

    @action removeActivity(activity: HostActivity) {
        const activities = this.activities;
        const index = activities.findIndex(ac => ac.id === activity.id);
        if (index > -1) {
            activities.splice(index, 1);

            this.activities = [...activities];
        }
    }
}
