import { Stores } from "../../../models";
import { inject, observer } from "mobx-react";
import React, { useState } from "react";
import { MessageType, Offer, OfferTemplate, Request } from "@hoverflo/shared";
import { ChooseOffer } from "./ChooseOffer";
import { MakeOffer } from "./MakeOffer";
import { postMessage } from "@hoverflo/shared/api/services/chat.service";

interface OfferProps extends Stores {
    request: Request;
    onAddOfferToChat: (offer: Offer) => void;
}

export const Offers = inject("rootStore")(
    observer((props: OfferProps) => {
        const { offerTemplateStore, chatStore, sessionStore } = props.rootStore;
        const [selectedOffer, setSelectedOffer] = useState<OfferTemplate>();
        const [newOffer, setNewOffer] = useState(false);

        return !selectedOffer && !newOffer ? (
            <ChooseOffer
                onSelectOffer={offer => {
                    setSelectedOffer(offer);
                    setNewOffer(false);
                }}
                onSelectNew={() => {
                    setSelectedOffer(undefined);
                    setNewOffer(true);
                }}
            />
        ) : (
            <MakeOffer
                onAddOfferToChat={async offer => {
                    setSelectedOffer(undefined);
                    setNewOffer(false);
                    chatStore.addMessage({
                        body: "",
                        type: MessageType.OFFER,
                        metaData: { offer: offer, request: props.request },
                        sender: sessionStore.session.user.id ?? "",
                        receiver: props.request.user?.id ?? "",
                        requestId: props.request.id,
                        created: new Date(),
                        sent: true
                    });
                    await postMessage({
                        body: "OFFER",
                        type: MessageType.OFFER,
                        metaData: { offer: offer, request: props.request },
                        receiver: props.request?.user?.id ?? "",
                        requestId: props.request.id
                    });
                    props.onAddOfferToChat(offer);
                }}
                onSave={offer => {
                    offerTemplateStore.addOffer(offer);
                    setSelectedOffer(undefined);
                    setNewOffer(false);
                }}
                offerTemplate={selectedOffer}
                onCancel={() => {
                    setNewOffer(false);
                    setSelectedOffer(undefined);
                }}
                request={props.request}
            />
        );
    })
);
