import { inject, observer } from "mobx-react";
import { Stores } from "../../../models";

import "./HostBookings.scss";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { HostActivity, Request, RequestResponse, RequestStatus } from "@hoverflo/shared/api/models";
import { getRequestsForHost } from "@hoverflo/shared/api/services/request.service";
import { getHost } from "@hoverflo/shared/api/services/host.service";
import { Inbox } from "./Inbox";
import { errorHandler } from "../../../utils/errorHandler";
import classNames from "classnames";
import { ChatView } from "../../layout/chat/ChatView";
import { Offers } from "./Offers";

export const HostBookings = inject("rootStore")(
    observer((props: Stores) => {
        const { requestStore, loadingStore, messagesStore, searchStore, sessionStore, hostStore, routingStore } =
            props.rootStore;
        const [filterStatus, setFilterStatus] = useState<RequestStatus>();
        const [focused, setFocused] = useState<Request>();
        const [tab, setTab] = useState("chat");
        const changeTab = (nextTab: string) => setTab(nextTab);

        useEffect(() => {
            if (!!sessionStore.session?.user?.hostDto?.id) {
                if (!hostStore.host?.generalInfo?.id) {
                    loadingStore.triggerLoading();
                    getHost(sessionStore.session.user.hostDto.id)
                        .then(host => {
                            hostStore.setHost(host);
                            loadingStore.stopLoading();
                            if (requestStore.requests.length === 0) {
                                loadingStore.triggerLoading();
                                getRequestsForHost(host.generalInfo?.id)
                                    .then(requests => {
                                        requestStore.setRequests(requests.content);
                                        loadingStore.stopLoading();
                                    })
                                    .catch(async e => {
                                        await errorHandler(e, props.rootStore);
                                        loadingStore.stopLoading();
                                        messagesStore.error(e.message ?? "Error while retrieving booking requests");
                                    });
                            }
                        })
                        .catch(async e => {
                            await errorHandler(e, props.rootStore);
                            loadingStore.stopLoading();
                            messagesStore.error("Error while retrieving host details.");
                        });
                } else {
                    if (hostStore.host?.generalInfo?.id && requestStore.requests.length === 0) {
                        loadingStore.triggerLoading();
                        loadingStore.triggerLoading();
                        getRequestsForHost(hostStore.host?.generalInfo?.id)
                            .then(requests => {
                                requestStore.setRequests(requests.content);
                                loadingStore.stopLoading();
                            })
                            .catch(async e => {
                                await errorHandler(e, props.rootStore);
                                loadingStore.stopLoading();
                                messagesStore.error(e.message ?? "Error while retrieving booking requests");
                            });
                    }
                }
            } else {
                messagesStore.error("User is not a host. Signing off");
                routingStore.pushRoute("/");
            }
        }, []);

        const requests = useMemo(
            () =>
                requestStore.requests.filter(request =>
                    filterStatus ? request.status === filterStatus : true
                ) as Array<RequestResponse>,
            [requestStore.requests, filterStatus, searchStore.selectedResults]
        );

        const findHostActivity = useCallback(
            (activityId: string, locationId: string): HostActivity | undefined =>
                hostStore.host?.activities
                    ?.filter(ac => ac.activity?.id === activityId)
                    .map(
                        ac => ({ ...ac, locations: ac.locations.filter(loc => loc.id === locationId) } as HostActivity)
                    )?.[0],
            [hostStore.host]
        );

        useEffect(() => {
            if (!focused && requests.length > 0 && hostStore.host) {
                const request = requests[0];
                setFocused({
                    ...request,
                    user: request.userDto,
                    hostActivity: {
                        ...findHostActivity(request.selectedActivityId, request.selectedLocationId),
                        host: hostStore.host?.generalInfo
                    } as HostActivity
                } as Request);
            }
        }, [requests, focused, hostStore.host]);

        return (
            <div className="host-bookings-container">
                <div className="host-bookings-content">
                    <div className="left-panel">
                        <Inbox
                            requests={requests}
                            filterStatus={filterStatus}
                            setFilterStatus={setFilterStatus}
                            setFocused={setFocused}
                            focused={focused}
                        />
                    </div>
                    <div className="middle-panel">
                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                            <li className="nav-item">
                                <a
                                    className={classNames("nav-link", { active: tab === "chat" })}
                                    id="chat-tab"
                                    data-toggle="tab"
                                    style={{ cursor: "pointer" }}
                                    aria-selected={tab === "chat"}
                                    onClick={() => changeTab("chat")}
                                >
                                    Chat
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    className={classNames("nav-link", { active: tab === "offer" })}
                                    id="offer-tab"
                                    data-toggle="tab"
                                    style={{ cursor: "pointer" }}
                                    aria-selected={tab === "offer"}
                                    onClick={() => changeTab("offer")}
                                >
                                    Offer
                                </a>
                            </li>
                        </ul>
                        <div className="tab-content">
                            <div
                                className={classNames("chat tab-pane fade", {
                                    show: tab === "chat",
                                    active: tab === "chat"
                                })}
                                id="chat"
                                role="tabpanel"
                                aria-labelledby="chat-tab"
                            >
                                {focused && <ChatView request={focused} onCreateOffer={() => setTab("offer")} />}
                            </div>
                            <div
                                className={classNames("offer tab-pane fade", {
                                    show: tab === "offer",
                                    active: tab === "offer"
                                })}
                                id="offer"
                                role="tabpanel"
                                aria-labelledby="offer-tab"
                            >
                                {focused && (
                                    <Offers
                                        request={focused}
                                        onAddOfferToChat={offer => {
                                            setTab("chat");
                                        }}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    })
);
