import {
    HostActivity as HostActivityType,
    HostActivity,
    HostActivityLocation,
    Location,
    OnboardingRequest
} from "@hoverflo/shared/api/models";
import { inject, observer } from "mobx-react";
import React, { useState } from "react";
import { Stores } from "../../../models";
import { InputSearchLocation } from "../../common/input-search-location/InputSearchLocation";
import { useTranslation } from "react-i18next";
import { SeasonMap } from "./SeasonMap";
import { AddCircle, RemoveCircle } from "@mui/icons-material";
import { themeColors } from "@hoverflo/shared/api/utils/constants";
import { DialogContent, DialogTitle, IconButton } from "@mui/material";
import { registerHost } from "@hoverflo/shared/api/services/onboarding.service";
import Dialog from "@mui/material/Dialog";

interface Step3Props extends Stores {
    onComplete: (email: string) => void;
}

export const Step3 = inject("rootStore")(
    observer((props: Step3Props) => {
        const { i18n } = useTranslation();
        const { onboardingStore, loadingStore, messagesStore } = props.rootStore;
        const [focusedActivity, setFocusedActivity] = useState<HostActivity | undefined>(undefined);
        const [showSeasons, setShowSeasons] = useState(false);

        const allFilledLocationSeasons = onboardingStore.activities
            .flatMap(a => a.locations)
            .every(
                l => l.location?.coordination?.latitude !== undefined
                // l.location?.fromDate !== undefined &&
                // l.location?.tillDate !== undefined
            );
        const atLeastOneLocationFilled = onboardingStore.activities.every(ac => ac.locations.length > 0);

        const onSubmit = async () => {
            const homeSpots = onboardingStore.activities.flatMap(a => a.locations.filter(l => l.mainSpot));
            const body: OnboardingRequest = {
                name: onboardingStore.companyData.companyName,
                subtitle: "",
                countryCode: "NL",
                googleRating: 0,
                googleUserRatingsTotal: 0,
                biography: "",
                contact: {
                    contactPerson: onboardingStore.companyData.contactPerson,
                    website: "",
                    email: onboardingStore.companyData.email,
                    phoneNumber: onboardingStore.companyData.telephoneNumber,
                    contactAddress: homeSpots[0].location?.address
                },
                password: onboardingStore.companyData.password,
                activities: onboardingStore.activities.map(activity => {
                    const locations = [...activity.locations];
                    locations.sort((a, b) => (a.mainSpot ? -1 : b.mainSpot ? 1 : 0));
                    return {
                        lesson: activity.lesson,
                        material: activity.material,
                        activity: activity.activity,
                        locations: locations.map(
                            l => ({ ...l, id: undefined, isTemporary: undefined } as HostActivityLocation)
                        )
                    } as HostActivityType;
                }),
                locations: homeSpots.map((spot, index) => {
                    return {
                        headquarter: index === 0,
                        location: { ...spot.location, fromDate: undefined, tillDate: undefined } as Location
                    };
                })
            };
            try {
                loadingStore.triggerLoading();
                await registerHost(body);
                const email = onboardingStore.companyData.email;
                onboardingStore.clean();
                loadingStore.stopLoading();
                props.onComplete(email);
            } catch (e: any) {
                loadingStore.stopLoading();
                messagesStore.error(e.message);
            }
        };

        return (
            <>
                <div>
                    <h3>
                        <strong>STEP 3:</strong>Locations and seasons
                    </h3>
                    <div className="input--search__wrapper">
                        <select
                            className="form-control"
                            value={focusedActivity?.id}
                            onChange={event => {
                                const activity = onboardingStore.activities.find(ac => ac.id === event.target.value);
                                setFocusedActivity(activity);
                                // if (activity.locations.find(l => l.mainSpot && l.location)) {
                                //     setShowSeasons(true);
                                // } else {
                                //     setShowSeasons(false);
                                // }
                            }}
                            placeholder="For which activity do you want to select a location?"
                        >
                            <option selected={!focusedActivity?.id} disabled>
                                For which activity do you want to select a location?
                            </option>
                            {onboardingStore.activities
                                .slice()
                                .sort((a, b) => a.activity?.name?.localeCompare(b.activity?.name))
                                .map(ac => (
                                    <option key={ac.id} value={ac.id}>
                                        {ac.activity?.name.toUpperCase()}
                                    </option>
                                ))}
                        </select>
                    </div>
                    <div className="location-header__content">
                        <div>Home Spot:</div>
                    </div>
                    <InputSearchLocation
                        disabled={!focusedActivity}
                        onSelectLocation={location => {
                            if (focusedActivity.locations.length === 0) {
                                onboardingStore.addLocation(focusedActivity);
                                const mainActivity = onboardingStore.activities.find(
                                    ac => ac.id === focusedActivity.id
                                );
                                const mainLocation = mainActivity.locations.find(l => !!l.mainSpot);
                                onboardingStore.updateLocation(mainActivity, { ...mainLocation, location });
                            } else {
                                const mainLocation = focusedActivity.locations.find(l => !!l.mainSpot);
                                onboardingStore.updateLocation(focusedActivity, { ...mainLocation, location });
                            }
                            setFocusedActivity(onboardingStore.activities.find(ac => ac.id === focusedActivity.id));
                        }}
                        country="nl"
                        placeholder={`Fill in your location ${
                            focusedActivity?.activity?.name ? `for ${focusedActivity?.activity?.name}` : ""
                        }`}
                        value={focusedActivity?.locations.find(l => l.mainSpot)?.location?.name ?? ""}
                    />
                    <button
                        type="button"
                        className="btn btn-location"
                        disabled={!focusedActivity}
                        onClick={() => {
                            onboardingStore.updateActivity(focusedActivity);
                            setShowSeasons(true);
                        }}
                    >
                        SHOW LOCATION LIST
                    </button>
                    {focusedActivity && <SeasonMap hostActivity={focusedActivity} />}
                </div>
                {focusedActivity && (
                    <Dialog open={showSeasons} onClose={() => setShowSeasons(false)} maxWidth="sm" fullWidth={true}>
                        <DialogContent className="become-host__container-dialog">
                            {/*<h3 className="location-header">*/}
                            {/*    <strong>Location List:</strong>*/}
                            {/*    <span>{focusedActivity.activity?.name}</span>*/}
                            {/*</h3>*/}
                            <div className="flex-column flex-grow-1 location-list--container">
                                <div className="location-header__content margin-bottom">
                                    <div>Home Spot:</div>
                                </div>
                                <div className="flex-row align-items-center">
                                    <div className="combined-form-picker" id="combined-picker-main-location">
                                        <InputSearchLocation
                                            disabled
                                            onSelectLocation={location => {
                                                const mainLocation = focusedActivity.locations.find(
                                                    l => l && l.mainSpot
                                                );
                                                onboardingStore.updateLocation(focusedActivity, {
                                                    ...mainLocation,
                                                    location: {
                                                        ...mainLocation.location,
                                                        ...location
                                                    }
                                                } as HostActivityLocation);

                                                setFocusedActivity(
                                                    onboardingStore.activities.find(ac => ac.id === focusedActivity.id)
                                                );
                                            }}
                                            country="nl"
                                            placeholder="Home spot"
                                            value={
                                                focusedActivity?.locations.find(l => l.mainSpot)?.location?.name ?? ""
                                            }
                                            portalId="combined-picker-main-location"
                                        />
                                        {/*<InputSeasonSelector*/}
                                        {/*    onChange={(startDate, endDate) => {*/}
                                        {/*        const mainLocation = focusedActivity.locations.find(*/}
                                        {/*            l => l && l.mainSpot*/}
                                        {/*        );*/}
                                        {/*        onboardingStore.updateLocation(focusedActivity, {*/}
                                        {/*            ...mainLocation,*/}
                                        {/*            location: {*/}
                                        {/*                ...mainLocation.location,*/}
                                        {/*                fromDate: startDate,*/}
                                        {/*                tillDate: endDate*/}
                                        {/*            }*/}
                                        {/*        } as HostActivityLocation);*/}

                                        {/*        setFocusedActivity(*/}
                                        {/*            onboardingStore.activities.find(ac => ac.id === focusedActivity.id)*/}
                                        {/*        );*/}
                                        {/*    }}*/}
                                        {/*    locale={i18n.language as any}*/}
                                        {/*    startDate={focusedActivity.locations?.[0]?.location?.fromDate}*/}
                                        {/*    endDate={focusedActivity.locations?.[0]?.location?.tillDate}*/}
                                        {/*/>*/}
                                    </div>
                                    {focusedActivity.locations.filter(l => !l.mainSpot).length < 4 && (
                                        <IconButton
                                            title="Add location"
                                            onClick={() => {
                                                onboardingStore.addLocation(focusedActivity);
                                                setFocusedActivity(
                                                    onboardingStore.activities.find(ac => ac.id === focusedActivity.id)
                                                );
                                            }}
                                        >
                                            <AddCircle sx={{ color: themeColors.EARTH.background }} />
                                        </IconButton>
                                    )}
                                </div>
                                {focusedActivity.locations
                                    .filter(l => !l.mainSpot)
                                    .map((location, index) => (
                                        <>
                                            {index === 0 && (
                                                <div className="location-header__content margin-bottom">
                                                    <div>Locations:</div>
                                                </div>
                                            )}
                                            <div className="flex-row align-items-center" key={location.id}>
                                                <div
                                                    className="combined-form-picker"
                                                    id={`combined-picker-additional-location-${index + 1}`}
                                                >
                                                    <InputSearchLocation
                                                        disabled={!focusedActivity}
                                                        onSelectLocation={newLocation => {
                                                            onboardingStore.updateLocation(focusedActivity, {
                                                                ...location,
                                                                location: {
                                                                    ...location.location,
                                                                    ...newLocation
                                                                }
                                                            } as HostActivityLocation);

                                                            setFocusedActivity(
                                                                onboardingStore.activities.find(
                                                                    ac => ac.id === focusedActivity.id
                                                                )
                                                            );
                                                        }}
                                                        country="nl"
                                                        placeholder={`Additional spot ${index + 1}`}
                                                        value={location.location?.name ?? ""}
                                                        portalId={`combined-picker-additional-location-${index + 1}`}
                                                    />
                                                    {/*<InputSeasonSelector*/}
                                                    {/*    onChange={(startDate, endDate) => {*/}
                                                    {/*        onboardingStore.updateLocation(focusedActivity, {*/}
                                                    {/*            ...location,*/}
                                                    {/*            location: {*/}
                                                    {/*                ...location.location,*/}
                                                    {/*                fromDate: startDate,*/}
                                                    {/*                tillDate: endDate*/}
                                                    {/*            }*/}
                                                    {/*        } as HostActivityLocation);*/}

                                                    {/*        setFocusedActivity(*/}
                                                    {/*            onboardingStore.activities.find(*/}
                                                    {/*                ac => ac.id === focusedActivity.id*/}
                                                    {/*            )*/}
                                                    {/*        );*/}
                                                    {/*    }}*/}
                                                    {/*    locale={i18n.language as any}*/}
                                                    {/*    startDate={location.location?.fromDate}*/}
                                                    {/*    endDate={location.location?.tillDate}*/}
                                                    {/*/>*/}
                                                </div>
                                                <div>
                                                    <IconButton
                                                        title="Remove location"
                                                        onClick={() => {
                                                            if (
                                                                window.confirm(
                                                                    "Are sure you want to delete this location?"
                                                                )
                                                            ) {
                                                                onboardingStore.removeLocation(
                                                                    focusedActivity,
                                                                    location
                                                                );
                                                                setFocusedActivity(
                                                                    onboardingStore.activities.find(
                                                                        ac => ac.id === focusedActivity.id
                                                                    )
                                                                );
                                                            }
                                                        }}
                                                    >
                                                        <RemoveCircle sx={{ color: themeColors.EARTH.background }} />
                                                    </IconButton>
                                                </div>
                                            </div>
                                        </>
                                    ))}
                            </div>
                        </DialogContent>
                    </Dialog>
                )}
                {focusedActivity && (
                    <button
                        type="button"
                        className="btn btn-complete"
                        disabled={!allFilledLocationSeasons || !atLeastOneLocationFilled}
                        onClick={onSubmit}
                    >
                        Complete Registration
                    </button>
                )}
            </>
        );
    })
);
