import { inject, observer } from "mobx-react";
import { Stores } from "../../../models";
import { AccountOverview } from "./account-overview/AccountOverview";
import CloseIcon from "@mui/icons-material/Close";
import "./Sidebar.scss";
import { Route } from "../../../stores/sidebar.store";
import { Login } from "./login/Login";
import classNames from "classnames";
import { Register } from "./register/Register";
import { RegisterWith } from "./register-with/RegisterWith";
import { ForgotPassword } from "./forgot-password/ForgotPassword";

interface SidebarProps extends Stores {
    closeDrawerAction: () => void;
}

export const Sidebar = inject("rootStore")(
    observer(({ closeDrawerAction, rootStore }: SidebarProps) => {
        const { sidebarStore } = rootStore;
        const route = sidebarStore.route;
        return (
            <div className={classNames("sidebar-container", `sidebar-container__${route}`)}>
                <a onClick={closeDrawerAction} className="close-button" style={{ cursor: "pointer" }}>
                    <CloseIcon fontSize="large" />
                </a>
                {route === Route.ACCOUNT_OVERVIEW && <AccountOverview />}
                {route === Route.LOGIN && <Login />}
                {route === Route.REGISTER && <Register />}
                {route === Route.REGISTER_WITH && <RegisterWith />}
                {route === Route.FORGOT_PASSWORD && <ForgotPassword />}
            </div>
        );
    })
);
