import { Stores } from "../../../models";
import { inject, observer } from "mobx-react";
import React, { useEffect } from "react";
import { getHost } from "@hoverflo/shared/api/services/host.service";
import { getOfferTemplates } from "@hoverflo/shared/api/services/offer.service";
import { errorHandler } from "../../../utils/errorHandler";
import { OfferTemplate } from "@hoverflo/shared";

interface ChooseOfferProps extends Stores {
    onSelectOffer: (offer: OfferTemplate) => void;
    onSelectNew: () => void;
}

export const ChooseOffer = inject("rootStore")(
    observer((props: ChooseOfferProps) => {
        const { offerTemplateStore, hostStore, sessionStore, loadingStore, messagesStore, routingStore } =
            props.rootStore;

        useEffect(() => {
            if (!!sessionStore.session?.user?.hostDto?.id) {
                if (!hostStore.host?.generalInfo?.id) {
                    loadingStore.triggerLoading();
                    getHost(sessionStore.session.user.hostDto.id)
                        .then(host => {
                            hostStore.setHost(host);
                            loadingStore.stopLoading();
                            if (offerTemplateStore.offers.length === 0) {
                                getOfferTemplates(hostStore.host.generalInfo.id)
                                    .then(offers => {
                                        offerTemplateStore.setOffers(offers ?? []);
                                        loadingStore.stopLoading();
                                    })
                                    .catch(async e => {
                                        await errorHandler(e, props.rootStore);
                                        messagesStore.error("Error while retrieving offer templates");
                                        loadingStore.stopLoading();
                                    });
                            }
                        })
                        .catch(async e => {
                            await errorHandler(e, props.rootStore);
                            loadingStore.stopLoading();
                            messagesStore.error("Error while retrieving host details.");
                        });
                } else {
                    if (hostStore.host?.generalInfo?.id && offerTemplateStore.offers.length === 0) {
                        loadingStore.triggerLoading();
                        getOfferTemplates(hostStore.host.generalInfo.id)
                            .then(offers => {
                                offerTemplateStore.setOffers(offers ?? []);
                                loadingStore.stopLoading();
                            })
                            .catch(async e => {
                                await errorHandler(e, props.rootStore);
                                messagesStore.error("Error while retrieving offer templates");
                                loadingStore.stopLoading();
                            });
                    }
                }
            } else {
                messagesStore.error("User is not a host. Signing off");
                routingStore.pushRoute("/");
            }
        }, []);

        return (
            <div className="row choose-offer">
                <div className="new col">
                    <div className="title">New Offer</div>
                    <div className="body">
                        <div className="body-content">
                            <div className="new-offer col" onClick={props.onSelectNew}>
                                <img src="assets/Icons/Icon_Host_Offer.svg" />
                                <h1>Create a New Offer</h1>
                                <h2>
                                    Make an unique offer based on the wishes of the client, or create a package to save
                                    for later and use multiple times
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="existing">
                    <div className="title">Use Existing offer template</div>
                    <div className="body">
                        <div className="body-content">
                            <div className="offer-template-list">
                                {offerTemplateStore.offers.map(offer => (
                                    <div
                                        key={offer.id}
                                        className="offer-template"
                                        onClick={() => props.onSelectOffer(offer)}
                                    >
                                        <img src="assets/Icons/Icon_Host_Offer.svg" />
                                        <h1>{offer.packageName}</h1>
                                        <h3>{offer.activityDto?.name}</h3>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    })
);
