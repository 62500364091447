import { AxiosError } from "axios";
import { setCurrentSession } from "@hoverflo/shared/api/utils/login.utils";
import { RootStore } from "../stores/root.store";
import { refreshToken } from "@hoverflo/shared/api/services/login.service";
import { Session } from "@hoverflo/shared/api/models";
import addSeconds from "date-fns/addSeconds";

export async function errorHandler(e: AxiosError, rootStore: RootStore) {
    if (/code 401|401/.test(e.message.toLowerCase())) {
        try {
            // Performing refresh token
            const currentSession = rootStore.sessionStore.currentSession;
            const sessionRenewed = await refreshToken(currentSession);
            const dateTime = new Date();
            const newSession = {
                ...currentSession,
                refreshToken: sessionRenewed.refresh_token,
                accessToken: sessionRenewed.access_token,
                expiresAt: sessionRenewed.expires_in ? addSeconds(dateTime, sessionRenewed.expires_in) : undefined,
                refreshTokenExpiresAt: sessionRenewed.refresh_expires_in
                    ? addSeconds(dateTime, sessionRenewed.refresh_expires_in)
                    : undefined
            } as Session;
            rootStore.sessionStore.setCurrentSession(newSession);
            await setCurrentSession(newSession);
        } catch (e) {
            // Performing logout
            rootStore.sessionStore.clearCurrentSession();
            rootStore.userStore.clearCurrentSession();
            await setCurrentSession();
            rootStore.routingStore.pushRoute("/");
        }
    }
}
