import { inject, observer } from "mobx-react";
import { Stores } from "../../models";
import React, { PropsWithChildren, ReactElement } from "react";

export const LoggedComponent = inject("rootStore")(
    observer(({ children, rootStore }: PropsWithChildren<Stores>): ReactElement | null => {
        return rootStore?.sessionStore.logged ? <>{children}</> : null;
    })
);

export const LoggedHostComponent = inject("rootStore")(
    observer(({ children, rootStore }: PropsWithChildren<Stores>): ReactElement | null => {
        return (rootStore?.sessionStore.logged && rootStore.sessionStore.isHost) ||
            rootStore.sessionStore.session?.user?.userType === "HOST" ? (
            <>{children}</>
        ) : null;
    })
);

export const LoggedAdminComponent = inject("rootStore")(
    observer(({ children, rootStore }: PropsWithChildren<Stores>): ReactElement | null => {
        return (rootStore?.sessionStore.logged && rootStore.sessionStore.isAdmin) ||
            rootStore.sessionStore.session?.user?.userType === "ADMIN" ? (
            <>{children}</>
        ) : null;
    })
);

export const LoggedUserComponent = inject("rootStore")(
    observer(({ children, rootStore }: PropsWithChildren<Stores>): ReactElement | null => {
        return (rootStore?.sessionStore.logged && rootStore.sessionStore.isUser) ||
            rootStore.sessionStore.session?.user?.userType === "USER" ? (
            <>{children}</>
        ) : null;
    })
);

export const NotLoggedComponent = inject("rootStore")(
    observer(({ children, rootStore }: PropsWithChildren<Stores>): ReactElement | null => {
        return !rootStore?.sessionStore.logged ? <>{children}</> : null;
    })
);
