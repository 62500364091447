import { inject, observer } from "mobx-react";
import { Stores } from "../../../models";
import React, { useEffect, useState } from "react";
import { getHost } from "@hoverflo/shared/api/services/host.service";
import { errorHandler } from "../../../utils/errorHandler";
import classNames from "classnames";
import { OfferTemplate as OfferTemplateType } from "@hoverflo/shared/api/models";

import { OfferTemplate } from "./OfferTemplate";
import { getOfferTemplates } from "@hoverflo/shared/api/services/offer.service";

import "./HostOffers.scss";

export const HostOffers = inject("rootStore")(
    observer((props: Stores) => {
        const { hostStore, loadingStore, messagesStore, sessionStore, routingStore, offerTemplateStore } =
            props.rootStore;
        const [tab, setTab] = useState("new-edit");
        const [focused, setFocused] = useState<OfferTemplateType | undefined>(undefined);
        const changeTab = (nextTab: string) => setTab(nextTab);

        useEffect(() => {
            if (!!sessionStore.session?.user?.hostDto?.id) {
                if (!hostStore.host?.generalInfo?.id) {
                    loadingStore.triggerLoading();
                    getHost(sessionStore.session.user.hostDto.id)
                        .then(host => {
                            hostStore.setHost(host);
                            loadingStore.stopLoading();
                            if (offerTemplateStore.offers.length === 0) {
                                getOfferTemplates(hostStore.host.generalInfo.id)
                                    .then(offers => {
                                        offerTemplateStore.setOffers(offers ?? []);
                                        loadingStore.stopLoading();
                                    })
                                    .catch(async e => {
                                        await errorHandler(e, props.rootStore);
                                        messagesStore.error("Error while retrieving offer templates");
                                        loadingStore.stopLoading();
                                    });
                            }
                        })
                        .catch(async e => {
                            await errorHandler(e, props.rootStore);
                            loadingStore.stopLoading();
                            messagesStore.error("Error while retrieving host details.");
                        });
                } else {
                    if (hostStore.host?.generalInfo?.id && offerTemplateStore.offers.length === 0) {
                        loadingStore.triggerLoading();
                        getOfferTemplates(hostStore.host.generalInfo.id)
                            .then(offers => {
                                offerTemplateStore.setOffers(offers ?? []);
                                loadingStore.stopLoading();
                            })
                            .catch(async e => {
                                await errorHandler(e, props.rootStore);
                                messagesStore.error("Error while retrieving offer templates");
                                loadingStore.stopLoading();
                            });
                    }
                }
            } else {
                messagesStore.error("User is not a host. Signing off");
                routingStore.pushRoute("/");
            }
        }, []);

        return (
            <div className="host-offers-container">
                <div className="host-offers-content">
                    <div className="left-panel">
                        <div className="container saved-offers">
                            <h2>Saved Offers</h2>
                            <div className="offer-template-list">
                                {offerTemplateStore.offers.map(offer => (
                                    <div
                                        key={offer.id}
                                        className={classNames("offer-template", {
                                            selected: offer.id === focused?.id
                                        })}
                                        onClick={() => setFocused(offer)}
                                    >
                                        <img src="assets/Icons/Icon_Host_Offer.svg" />
                                        <h1>{offer.packageName}</h1>
                                        <h3>{offer.activityDto?.name}</h3>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="middle-panel">
                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                            <li className="nav-item">
                                <a
                                    className={classNames("nav-link", { active: tab === "new-edit" })}
                                    id="new-edit-tab"
                                    data-toggle="tab"
                                    style={{ cursor: "pointer" }}
                                    aria-selected={tab === "new-edit"}
                                    onClick={() => changeTab("new-edit")}
                                >
                                    {focused?.id ? "Edit" : "New"}
                                </a>
                            </li>
                            {focused?.id && (
                                <div className="empty-nav-space">
                                    <button className="btn btn-orange-rounded" onClick={() => setFocused(undefined)}>
                                        <img src="assets/Icons/Icon-plus.svg" className="icon_style" />
                                        CREATE NEW OFFER
                                    </button>
                                </div>
                            )}
                        </ul>
                        <div className="tab-content">
                            <div
                                className={classNames("tab-pane fade", {
                                    show: tab === "new-edit",
                                    active: tab === "new-edit"
                                })}
                                id="new-edit"
                                role="tabpanel"
                                aria-labelledby="new-edit-tab"
                            >
                                <OfferTemplate
                                    offerTemplate={focused}
                                    onSave={offerTemplate => setFocused(offerTemplate)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    })
);
