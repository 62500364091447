import { del, get, patch, post } from "./base.service";
import { config } from "../utils/constants";
export function getOfferTemplates(hostId) {
    return get(`${config.url}/api/offer-template/host/${hostId}`);
}
export function getOfferTemplateById(id) {
    return get(`${config.url}/api/offer-template/${id}`);
}
export function saveOfferTemplate(offer) {
    return post(`${config.url}/api/offer-template/`, offer);
}
export function updateOfferTemplate(offerTemplate, offerTemplateId) {
    return patch(`${config.url}/api/offer-template/${offerTemplateId}`, offerTemplate);
}
export function deleteOfferTemplate(offerTemplateId) {
    return del(`${config.url}/api/offer-template/${offerTemplateId}`);
}
export function addOfferToRequest(offer) {
    return post(`${config.url}/api/offer`, offer);
}
export function acceptOffer(offer) {
    return patch(`${config.url}/api/offer/${offer.id}/update-status`, { newStatus: "ACCEPTED" });
}
export function rejectOffer(offer) {
    return patch(`${config.url}/api/offer/${offer.id}/update-status`, { newStatus: "REJECTED" });
}
export function withdrawOffer(offer) {
    return patch(`${config.url}/api/offer/${offer.id}/update-status`, { newStatus: "WITHDRAW" });
}
