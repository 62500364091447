import Keycloak from "keycloak-js";
import { keycloakConfig, config } from "@hoverflo/shared/api/utils/constants";

const keycloak = new Keycloak({
    realm: keycloakConfig.realm,
    clientId: keycloakConfig.clientId,
    url: config.urlKeyCloak
});

export default keycloak;
