import { action, makeAutoObservable, observable } from "mobx";
import { RootStore } from "./root.store";

export class RoutingStore {
    @observable
    route: string;

    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
    }

    /**
     * Push routes to the router and store it in the mobx store
     * @param route
     * @param state
     */
    @action pushRoute(route: string, state?: any) {
        this.route = route;
        this.rootStore.routerStore.push(route, state);
    }
}
