import { action, makeAutoObservable, observable } from "mobx";
import {
    Host,
    HostActivity,
    HostActivityLocation,
    HostImageDto,
    HostInfo,
    HostTermsAndConditionsDto,
    PaymentInfoDto
} from "@hoverflo/shared/api/models";
import { nanoid } from "nanoid/non-secure";

export class HostStore {
    @observable.deep host: HostInfo = {} as HostInfo;
    @observable.deep hosts: Host[] = [];

    constructor() {
        makeAutoObservable(this);
    }

    @action setHost(host: HostInfo) {
        this.host = host;
    }

    @action setHosts(hosts: Host[]) {
        this.hosts = hosts;
    }

    @action addTermsAndConditions(tc: HostTermsAndConditionsDto) {
        const tcs = [...(this.host.termsAndConditions ?? [])];
        tcs.push({ ...tc, id: nanoid(), isTemporary: true } as any);
        this.host.termsAndConditions = tcs;
    }

    @action updateTermsAndConditions(updatedTc: HostTermsAndConditionsDto) {
        const tcs = [...(this.host.termsAndConditions ?? [])];
        const index = tcs.findIndex(tc => tc.id === updatedTc.id) ?? -1;
        if (index > -1) {
            tcs[index] = updatedTc;
            this.host.termsAndConditions = tcs;
        }
    }

    @action removeTermsAndConditions(tc: HostTermsAndConditionsDto) {
        const tcs = [...(this.host.termsAndConditions ?? [])];
        const index = tcs?.findIndex(t => t.id === tc.id) ?? -1;
        if (index > -1) {
            tcs.splice(index, 1);
            this.host.termsAndConditions = tcs;
        }
    }

    @action addActivityLocation(location: HostActivityLocation) {
        const activityIndex = this.host.activities?.findIndex(ac => ac.activity?.id === location.activityId) ?? -1;
        if (activityIndex > -1) {
            this.host.activities?.[activityIndex].locations.push(location);
        }
    }

    @action addActivity(activity: HostActivity) {
        this.host.activities = [
            ...this.host.activities,
            { id: nanoid(), ...activity, isTemporary: true } as HostActivity
        ];
    }

    @action addLocation(activity: HostActivity) {
        const activities = [...this.host.activities];
        const targetActivity = activities.find(ac => ac.id === activity.id);
        if (targetActivity) {
            const homeSpotExists = targetActivity.locations.some(l => !!l.mainSpot);
            targetActivity.locations.push({
                id: nanoid(),
                location: undefined,
                mainSpot: !homeSpotExists,
                isTemporary: true
            } as HostActivityLocation);
            this.host.activities = activities;
        }
    }

    @action updateActivityLocation(location: HostActivityLocation) {
        const activityIndex = this.host.activities?.findIndex(ac => ac.activity?.id === location.activityId) ?? -1;
        if (this.host.activities && activityIndex > -1) {
            const locationIndex =
                this.host.activities[activityIndex].locations.findIndex(loc => loc.id === location.id) ?? -1;
            if (locationIndex > -1) {
                this.host.activities[activityIndex].locations[locationIndex] = location;
            }
        }
    }

    @action updateLocation(activity: HostActivity, location: HostActivityLocation) {
        const activities = [...this.host.activities];
        const index = activities.findIndex(ac => ac.id === activity.id);
        const indexLocation = activity.locations.findIndex(loc => loc.id === location.id);
        if (indexLocation > -1) {
            activity.locations[indexLocation] = { ...location };
        }
        if (index > -1) {
            activities[index] = { ...activity };
            this.host.activities = activities;
        }
    }

    @action removeActivityLocation(location: HostActivityLocation) {
        const activityIndex = this.host.activities?.findIndex(ac => ac.activity?.id === location.activityId) ?? -1;
        if (this.host.activities && activityIndex > -1) {
            const locationIndex =
                this.host.activities[activityIndex].locations.findIndex(loc => loc.id === location.id) ?? -1;
            if (locationIndex > -1) {
                this.host.activities[activityIndex].locations.splice(locationIndex, 1);
            }
        }
    }

    @action removeLocation(activity: HostActivity, location: HostActivityLocation) {
        const activities = this.host.activities;
        const index = activities.findIndex(ac => ac.id === activity.id);
        const indexLocation = activity.locations.findIndex(loc => loc.id === location.id);
        if (indexLocation > -1) {
            activity.locations.splice(indexLocation, 1);
        }
        if (index > -1) {
            activities[index] = { ...activity };
            this.host.activities = [...activities];
        }
    }

    @action addImage(image: HostImageDto) {
        const images = [...this.host.images];
        images?.push({ ...image, id: nanoid(), isTemporary: true } as any);
        this.host.images = images;
    }

    @action updateImage(image: HostImageDto) {
        const images = [...this.host.images];
        const index = images?.findIndex(i => i.id === image.id) ?? -1;
        if (images && index > -1) {
            images[index] = image;
            this.host.images = images;
        }
    }

    @action removeImage(image: HostImageDto) {
        const images = [...this.host.images];
        const index = images?.findIndex(i => i.id === image.id) ?? -1;
        if (images && index > -1) {
            images.splice(index, 1);
            this.host.images = images;
        }
    }

    @action updatePaymentInfo(paymentInfo?: PaymentInfoDto) {
        this.host.paymentInfo = paymentInfo;
    }

    @action updateActivity(activity: HostActivity) {
        const activities = [...this.host.activities];
        const index = activities.findIndex(ac => ac.id === activity.id);
        if (index > -1) {
            activities[index] = { ...activity };
        }
        this.host.activities = activities;
    }

    @action removeActivity(activity: HostActivity) {
        const activities = this.host.activities;
        const index = activities.findIndex(ac => ac.id === activity.id);
        if (index > -1) {
            activities.splice(index, 1);

            this.host.activities = [...activities];
        }
    }

    @action clearHost() {
        this.host = {} as HostInfo;
    }

    @action addHost(host: Host) {
        this.hosts.push(host);
    }

    @action removeHost(id: string) {
        const index = this.hosts.findIndex(host => host.id === id);
        if (index > -1) {
            this.hosts.splice(index, 1);
        }
    }

    @action updateHost(host: HostInfo) {
        const index = this.hosts.findIndex(h => h.id === host.generalInfo.id);
        if (index > -1) {
            this.hosts.splice(index, 1, host.generalInfo);
        }
    }
}
