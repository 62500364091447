import { action, makeAutoObservable, observable } from "mobx";

export enum Route {
    ACCOUNT_OVERVIEW = "account-overview",
    LOGIN = "login",
    REGISTER = "register",
    REGISTER_WITH = "register-with",
    FORGOT_PASSWORD = "forgot-password"
}

export class SidebarStore {
    @observable route: Route = Route.ACCOUNT_OVERVIEW;
    @observable open: boolean = false;

    constructor() {
        makeAutoObservable(this);
    }

    @action changeRoute(route: Route) {
        this.route = route;
    }

    @action toggleSidebar(open?: boolean) {
        this.open = open ?? !this.open;
    }
}
