import React, { CSSProperties, forwardRef, Fragment, MutableRefObject, ReactElement, useEffect, useState } from "react";
import TimeAgo from "javascript-time-ago";
import { IconButton } from "@mui/material";
import FeatherIcon from "feather-icons-react";
import { ChatMessage, Session, ThemeType } from "@hoverflo/shared/api/models";
import { getCurrentSession } from "@hoverflo/shared/api/utils/login.utils";
import { themeColors } from "@hoverflo/shared/api/utils/constants";
import { MessageType, Offer, Request } from "@hoverflo/shared";
import i18n from "../../../i18n";
import { inject, observer } from "mobx-react";
import { Stores } from "../../../models";

interface ChatProps extends Stores {
    header?: ReactElement;
    messages: ChatMessage[];
    theme: ThemeType | "default" | "host";
    onSend: (message: string) => void;
    scrollRef?: MutableRefObject<HTMLDivElement> | null;
    onCreateOffer?: () => void;
    onClickViewOffer?: (offer: Offer) => void;
}

// TODO: This is a workaround to fix not timezoned dates coming from the backend
const fixTimezone = (date: any) => {
    if (typeof date === "string") {
        // Dates with no timezone
        if (date.includes("T")) {
            const [notZonedDate] = date.split(".");
            // Set to UTC
            return new Date(`${notZonedDate}.000Z`);
        } else if (date.includes("-")) {
            // Date without time
            return new Date(date);
        }
    }
    return date;
};

export const Chat = inject("rootStore")(
    observer(
        forwardRef((props: ChatProps, ref: MutableRefObject<HTMLDivElement> | null) => {
            const { sessionStore } = props.rootStore;
            const [text, setText] = useState("");
            const [session, setSession] = useState<Session | null>(null);
            const timeAgo = new TimeAgo(i18n.language ?? "en");

            useEffect(() => {
                getCurrentSession().then(session => {
                    setSession(session);
                });
            }, []);

            const getTemplate = (template: MessageType, object: any): ReactElement => {
                const textStyle = {
                    ...styles.text,
                    color: themeColors[props.theme].text
                };
                switch (template) {
                    case MessageType.OFFER:
                        const offer = object.offer as Offer;
                        return (
                            <div
                                style={{
                                    ...styles.chatBubble,
                                    ...(sessionStore?.isHost ? styles.chatBubbleSender : styles.chatBubbleReceiver),
                                    ...{
                                        backgroundColor: themeColors[props.theme].background,
                                        display: "flex",
                                        flexDirection: "column"
                                    }
                                }}
                            >
                                <span style={textStyle}>
                                    Hi {object.request.user?.displayName}. Thanks for the request. Hereby I am sending
                                    you our offer. By confirming the offer the reservation will be completed.
                                </span>
                                <div style={styles.button} onClick={() => props.onClickViewOffer?.(offer)}>
                                    <span style={{ ...textStyle, ...{ textTransform: "uppercase", fontSize: 16 } }}>
                                        Offer
                                    </span>
                                </div>
                            </div>
                        );
                    case MessageType.REQUEST:
                        const request = object.request as Request;
                        return (
                            <>
                                {request.requestDate && (
                                    <span
                                        style={{
                                            ...(sessionStore.isHost ? styles.chatTimeReceiver : styles.chatTimeSender),
                                            ...{ color: themeColors[props.theme].text, marginLeft: 10, fontSize: 10 }
                                        }}
                                    >
                                        {timeAgo.format(fixTimezone(request.requestDate as any))}
                                    </span>
                                )}
                                <div
                                    style={{
                                        ...styles.chatBubble,
                                        ...(sessionStore.isHost ? styles.chatBubbleReceiver : styles.chatBubbleSender),
                                        ...{ backgroundColor: themeColors[props.theme].backgroundTertiary },
                                        ...{ display: "flex", flexDirection: "column" }
                                    }}
                                >
                                    <span style={textStyle}>
                                        Hi {request.hostActivity?.host?.name},<br /> I would like to request the
                                        following:
                                    </span>
                                    <div style={styles.separator} />
                                    <div>
                                        <div style={styles.horizontalText}>
                                            <span style={{ ...textStyle, ...styles.textBold }}>ACTIVITY:</span>
                                            <span
                                                style={{
                                                    ...textStyle,
                                                    ...styles.textCapitalized,
                                                    ...{ marginLeft: 5, flex: 1 }
                                                }}
                                            >
                                                {request.hostActivity?.activity?.name}
                                            </span>
                                        </div>
                                        <div style={styles.horizontalText}>
                                            <span style={{ ...textStyle, ...styles.textBold }}>PRODUCT:</span>
                                            <span
                                                style={{
                                                    ...textStyle,
                                                    ...styles.textCapitalized,
                                                    ...{ marginLeft: 5, flex: 1 }
                                                }}
                                            >
                                                {request.product}
                                            </span>
                                        </div>
                                        <div style={styles.horizontalText}>
                                            <span style={{ ...textStyle, ...styles.textBold }}>PERSON:</span>
                                            <span
                                                style={{
                                                    ...textStyle,
                                                    ...styles.textCapitalized,
                                                    ...{ marginLeft: 5, flex: 1 }
                                                }}
                                            >
                                                {request.numberOfPeople}
                                            </span>
                                        </div>
                                        <div style={styles.horizontalText}>
                                            <span style={{ ...textStyle, ...styles.textBold }}>DATE:</span>
                                            <span
                                                style={{
                                                    ...textStyle,
                                                    ...styles.textCapitalized,
                                                    ...{ marginLeft: 5, flex: 1 }
                                                }}
                                            >
                                                {request.requestDate &&
                                                    new Date(request.requestDate as any).toLocaleDateString()}
                                            </span>
                                        </div>
                                        <div style={styles.horizontalText}>
                                            <span style={{ ...textStyle, ...styles.textBold }}>LOCATION:</span>
                                            <span
                                                style={{
                                                    ...textStyle,
                                                    ...styles.textCapitalized,
                                                    ...{ marginLeft: 5, flex: 1 }
                                                }}
                                            >
                                                {request.hostActivity?.locations?.[0]?.location?.address?.fullAddress}
                                            </span>
                                        </div>
                                    </div>
                                    <div style={styles.separator} />
                                    {sessionStore.isHost && (
                                        <div
                                            onClick={props.onCreateOffer}
                                            style={{ ...styles.button, ...{ width: 170 } }}
                                        >
                                            <span
                                                style={{
                                                    ...textStyle,
                                                    ...{ textTransform: "uppercase", fontSize: 16 }
                                                }}
                                            >
                                                Create Offer
                                            </span>
                                        </div>
                                    )}
                                </div>
                            </>
                        );
                }
                return <div />;
            };

            return (
                <div style={{ position: "relative", width: "100%", height: "100%" }}>
                    {props.header}
                    <div style={styles.container} ref={ref}>
                        {props.messages.map((message, index) => {
                            const isReceiver = message.sender !== String(session?.user.id ?? "1");
                            if (message.type !== MessageType.TEXT) {
                                return getTemplate(message.type, message.metaData);
                            }
                            return (
                                <Fragment key={`message_${index}`}>
                                    <span
                                        style={{
                                            ...(isReceiver ? styles.chatTimeReceiver : styles.chatTimeSender),
                                            ...{ color: themeColors[props.theme].text }
                                        }}
                                    >
                                        {timeAgo.format(fixTimezone(message.created as any))}
                                    </span>
                                    <div
                                        style={{
                                            ...styles.chatBubble,
                                            ...(isReceiver ? styles.chatBubbleReceiver : styles.chatBubbleSender),
                                            ...{
                                                backgroundColor: isReceiver
                                                    ? themeColors[props.theme].backgroundTertiary
                                                    : themeColors[props.theme].background
                                            }
                                        }}
                                    >
                                        <span style={{ color: themeColors[props.theme].text }}>{message.body}</span>
                                        {!isReceiver && (
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    alignItems: "flex-end",
                                                    marginLeft: 2
                                                }}
                                            >
                                                {message.sent && (
                                                    <FeatherIcon
                                                        icon="check"
                                                        color={themeColors[props.theme].text}
                                                        size={12}
                                                    />
                                                )}
                                                {message.read && (
                                                    <FeatherIcon
                                                        icon="check"
                                                        color={themeColors[props.theme].text}
                                                        size={12}
                                                        style={{ marginLeft: -7 }}
                                                    />
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </Fragment>
                            );
                        })}
                    </div>
                    <div style={styles.chatBar}>
                        {/*<IconButton component="span" style={{ color: themeColors.default.backgroundTertiary }}>*/}
                        {/*    <FeatherIcon icon="camera" />*/}
                        {/*</IconButton>*/}
                        <input
                            type="text"
                            style={styles.chatBarTextInput}
                            placeholder="Type your message here"
                            onKeyUp={({ key }) => {
                                if (key === "Enter") {
                                    props.onSend(text);
                                    setText("");
                                }
                            }}
                            onChange={({ target: { value } }) => setText(value)}
                            value={text}
                        />
                        <IconButton
                            style={{ color: themeColors.default.primary }}
                            onClick={() => {
                                props.onSend(text);
                                setText("");
                            }}
                            component="span"
                        >
                            <FeatherIcon icon="send" />
                        </IconButton>
                    </div>
                </div>
            );
        })
    )
);

const styles: { [key: string]: CSSProperties } = {
    button: {
        backgroundColor: themeColors.default.primary,
        borderRadius: 30,
        padding: 10,
        marginTop: 10,
        width: 100,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer"
    },
    container: {
        display: "flex",
        flex: 1,
        flexDirection: "column",
        height: "calc(100% - 52px)",
        overflow: "scroll",
        paddingBottom: "52px"
    },
    chatBar: {
        display: "flex",
        flexDirection: "row",
        backgroundColor: "#ededed",
        position: "absolute",
        height: "52px",
        bottom: 0,
        width: "100%"
    },
    chatBubble: {
        display: "flex",
        flex: 0,
        padding: 10,
        borderRadius: 20,
        maxWidth: "70%",
        marginRight: 10,
        marginLeft: 10,
        marginBottom: 10
    },
    chatBubbleReceiver: {
        alignSelf: "flex-start"
    },
    chatBubbleSender: {
        alignSelf: "flex-end"
    },
    chatTimeReceiver: {
        fontSize: 10,
        marginLeft: 15,
        marginRight: 15,
        marginTop: 10,
        marginBottom: 3,
        alignSelf: "flex-start"
    },
    chatTimeSender: {
        fontSize: 10,
        marginLeft: 15,
        marginRight: 15,
        marginTop: 10,
        marginBottom: 3,
        alignSelf: "flex-end"
    },
    chatBarTextInput: {
        display: "flex",
        flex: 1,
        backgroundColor: "#fff",
        paddingLeft: 10,
        paddingTop: 10,
        paddingBottom: 10,
        color: "#000",
        marginTop: 5,
        marginBottom: 5,
        borderRadius: 20,
        border: "1px solid #EAEAEA"
    },
    separator: {
        borderBottom: "1px solid #FFF",
        margin: "10px 0"
    }
};
