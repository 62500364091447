import { inject, observer } from "mobx-react";
import { Stores } from "../../models";
import { useSnackbar } from "notistack";

export const MessagesCenter = inject("rootStore")(
    observer((props: Stores) => {
        const { enqueueSnackbar } = useSnackbar();
        props.rootStore?.messagesStore?.setMessageHandler(enqueueSnackbar);
        return null;
    })
);
