import { inject, observer } from "mobx-react";
import React, { useContext, useEffect } from "react";
import { StepperContext } from "../../layout/stepper/Stepper";
import { Stores } from "../../../models";
import { Checkbox, IconButton } from "@mui/material";
import { getActivities } from "@hoverflo/shared/api/services/activity.service";
import { themeColors } from "@hoverflo/shared/api/utils/constants";
import { AddCircle, RemoveCircle } from "@mui/icons-material";
import { errorHandler } from "../../../utils/errorHandler";

export const Step2 = inject("rootStore")(
    observer((props: Stores) => {
        const { onboardingStore, messagesStore, activityStore, loadingStore } = props.rootStore;
        const { onChange } = useContext(StepperContext);

        useEffect(() => {
            if (activityStore.activities.length === 0) {
                loadingStore.triggerLoading();
                getActivities()
                    .then(activities => {
                        activities.sort((a, b) => a.name.localeCompare(b.name));
                        activityStore.setActivities(activities);
                        loadingStore.stopLoading();
                    })
                    .catch(async e => {
                        await errorHandler(e, props.rootStore);
                        loadingStore.stopLoading();
                        messagesStore.error(e.message);
                    });
            }
        }, [activityStore, loadingStore, messagesStore]);

        useEffect(() => {
            if (onboardingStore.activities.length === 0) {
                onboardingStore.addActivity({ lesson: false, material: false, locations: [] });
            }
        }, []);

        const onSubmit = () => {
            onboardingStore.setVisibleSteps(2);
            onChange(3);
        };

        return (
            <>
                <h3>
                    <strong>STEP 2:</strong>Add your activities and services below
                </h3>
                {onboardingStore.activities.map((activity, index) => (
                    <div key={activity.id} className="flex-column">
                        <div className="flex-row align-items-center">
                            <select
                                className="form-control"
                                value={activity.activity?.id}
                                onChange={event => {
                                    onboardingStore.updateActivity({
                                        ...activity,
                                        activity: { ...activityStore.activities.find(a => a.id === event.target.value) }
                                    });
                                }}
                                placeholder={`Please select activity ${index + 1}`}
                            >
                                <option>Activity {index + 1}</option>
                                {activityStore.activities
                                    .filter(
                                        ac =>
                                            !onboardingStore.activities
                                                .filter(act => act.id !== activity.id)
                                                .map(act => act.activity?.id)
                                                .filter(Boolean)
                                                .includes(ac.id)
                                    )
                                    .map(activity => (
                                        <option key={activity.id} value={activity.id}>
                                            {activity.name.toUpperCase()}
                                        </option>
                                    ))}
                            </select>
                            {index !== 0 && (
                                <div>
                                    <IconButton
                                        title="Remove activity"
                                        onClick={() => {
                                            if (window.confirm("Are sure you want to delete this activity?")) {
                                                onboardingStore.removeActivity(activity);
                                            }
                                        }}
                                    >
                                        <RemoveCircle sx={{ color: themeColors.EARTH.background }} />
                                    </IconButton>
                                </div>
                            )}
                        </div>
                        <div className="flex-row">
                            <div className="flex-row flex-grow-1 align-items-center">
                                <Checkbox
                                    checked={activity.lesson}
                                    name={`lesson_${activity.id}`}
                                    onChange={event => {
                                        onboardingStore.updateActivity({
                                            ...activity,
                                            lesson: event.target.checked
                                        });
                                    }}
                                    sx={{
                                        color: "#FFF",
                                        "&.Mui-checked": {
                                            color: themeColors.EARTH.background
                                        }
                                    }}
                                />
                                <label htmlFor={`lesson_${activity.id}`}>Lessons/Guides</label>
                            </div>
                            <div className="flex-row flex-grow-1 align-items-center">
                                <Checkbox
                                    checked={activity.material}
                                    name={`material_${activity.id}`}
                                    onChange={event => {
                                        onboardingStore.updateActivity({
                                            ...activity,
                                            material: event.target.checked
                                        });
                                    }}
                                    sx={{
                                        color: "#FFF",
                                        "&.Mui-checked": {
                                            color: themeColors.EARTH.background
                                        }
                                    }}
                                />
                                <label htmlFor={`material_${activity.id}`}>Material</label>
                            </div>
                        </div>
                    </div>
                ))}
                {onboardingStore.activities.length < 4 && (
                    <div className="buttons align-items-end">
                        <IconButton
                            title="Add activity"
                            onClick={() => {
                                onboardingStore.addActivity({ lesson: false, material: false, locations: [] });
                            }}
                        >
                            <AddCircle sx={{ color: themeColors.EARTH.background }} />
                        </IconButton>
                    </div>
                )}
                <div className="buttons">
                    <button
                        type="button"
                        className="btn btn-next"
                        disabled={onboardingStore.activities.filter(a => !a.activity).length > 0}
                        onClick={onSubmit}
                    >
                        NEXT STEP
                    </button>
                </div>
            </>
        );
    })
);
