import { inject, observer } from "mobx-react";
import { Stores } from "../../../models";
import { TextBox } from "../../common/TextBox";
import { Activity, HostActivityLocation, OfferTemplate as OfferTemplateType } from "@hoverflo/shared";
import { Formik, FormikHelpers } from "formik";
import { Dropdown } from "../../common/dropdown/Dropdown";
import React, { useEffect, useState } from "react";
import { formatCurrency } from "@hoverflo/shared/api/utils/formats";
import { saveOfferTemplate, updateOfferTemplate } from "@hoverflo/shared/api/services/offer.service";

const personValues = [
    { value: "1", label: "1 PERSON" },
    { value: "2", label: "2 PERSONS" },
    { value: "3", label: "3 PERSONS" },
    { value: "4", label: "4 PERSONS" },
    { value: "5", label: "5 PERSONS" }
];

const noticePeriodValues = [
    { value: "0", label: "CANCELLATION NOT POSSIBLE" },
    { value: "1", label: "1 DAY" },
    { value: "3", label: "3 DAYS" },
    { value: "7", label: "1 WEEK" },
    { value: "30", label: "1 MONTH" }
];

const expiringDaysValues = [
    { value: "0", label: "NO EXPIRATION" },
    { value: "1", label: "1 DAY" },
    { value: "3", label: "3 DAYS" },
    { value: "7", label: "1 WEEK" },
    { value: "30", label: "1 MONTH" }
];

const packageValues = [
    { value: "1", label: "LESSON" },
    { value: "2", label: "EQUIPMENT" },
    { value: "3", label: "LESSON & EQUIPMENT" }
];

// TODO: Fixed values should be changed to API
const downPaymentValues = [
    { value: "1", label: "1%" },
    { value: "5", label: "5%" },
    { value: "10", label: "10%" },
    { value: "15", label: "15%" },
    { value: "25", label: "25%" },
    { value: "50", label: "50%" }
];

const checkNumber = (value: string) => {
    const number = Number(value);
    if (isNaN(number)) {
        return 0;
    }
    return number;
};

interface OfferTemplateProps extends Stores {
    offerTemplate?: OfferTemplateType;
    onSave: (offerTemplate: OfferTemplateType) => void;
}

interface OfferTemplateForm {
    packageName: string;
    activity: string;
    persons: string;
    noticePeriod: string;
    package: string;
    location: string;
    instructor: string;
    expiringDays: string;
    downPayment: string;
    lessonPrice: string;
    materialPrice: string;
    othersPrice: string;
    termsAndConditions: string;
}

export const OfferTemplate = inject("rootStore")(
    observer((props: OfferTemplateProps) => {
        const { hostStore, loadingStore, messagesStore, offerTemplateStore } = props.rootStore;
        const [activity, setActivity] = useState<string>(props.offerTemplate?.activityDto?.id);
        const [locations, setLocations] = useState<HostActivityLocation[]>([]);
        const [activities, setActivities] = useState<Array<Activity | undefined>>([]);

        useEffect(() => {
            setLocations(
                hostStore.host.activities?.filter(ac => ac.activity?.id === activity).flatMap(ac => ac.locations) ?? []
            );
            setActivities(hostStore.host.activities?.map(ac => ac.activity).filter(Boolean) ?? []);
        }, [hostStore.host, activity]);

        useEffect(() => {
            setActivity(props.offerTemplate?.activityDto?.id);
        }, [props.offerTemplate]);

        const onSave = async (values: OfferTemplateForm, { resetForm }: FormikHelpers<OfferTemplateForm>) => {
            loadingStore.triggerLoading();
            try {
                const offerTemplate = {
                    packageName: values.packageName,
                    packageDescription: packageValues.find(pkg => pkg.value === values.package)!.label,
                    persons: Number(values.persons),
                    noticePeriodDays: Number(values.noticePeriod),
                    expirationDateDays: Number(values.expiringDays),
                    instructor: values.instructor ?? "",
                    downPaymentPercentage: Number(values.downPayment),
                    lessonPrice: Number(values.lessonPrice),
                    materialPrice: Number(values.materialPrice),
                    otherPrice: Number(values.othersPrice),
                    hostActivityLocationId: values.location,
                    termsAndConditionsId: values.termsAndConditions
                } as OfferTemplateType;
                if (props.offerTemplate?.id) {
                    const updatedOfferTemplate = await updateOfferTemplate(offerTemplate, props.offerTemplate.id);
                    offerTemplateStore.updateOffer(updatedOfferTemplate);
                    props.onSave(updatedOfferTemplate);
                } else {
                    const savedOfferTemplate = await saveOfferTemplate(offerTemplate);
                    offerTemplateStore.addOffer(savedOfferTemplate);
                    props.onSave(savedOfferTemplate);
                }
                loadingStore.stopLoading();
                resetForm();
                messagesStore.success("Offer template successfully saved");
            } catch (e) {
                loadingStore.stopLoading();
                messagesStore.error("Error occurred while saving offer template.");
            }
        };

        return (
            <div className="new-edit">
                <Formik
                    initialValues={{
                        packageName: props.offerTemplate?.packageName ?? "",
                        activity: props.offerTemplate?.activityDto?.id ?? "",
                        persons: props.offerTemplate?.persons.toString() ?? "",
                        noticePeriod: props.offerTemplate?.noticePeriodDays.toString() ?? "",
                        package:
                            packageValues.find(v => v.label === props.offerTemplate?.packageDescription)?.value ?? "",
                        location: props.offerTemplate?.hostActivityLocationId ?? "",
                        instructor: props.offerTemplate?.instructor ?? "",
                        expiringDays: props.offerTemplate?.expirationDateDays?.toString() ?? "",
                        downPayment: props.offerTemplate?.downPaymentPercentage?.toString() ?? "",
                        lessonPrice: props.offerTemplate?.lessonPrice?.toString() ?? "",
                        materialPrice: props.offerTemplate?.materialPrice?.toString() ?? "",
                        othersPrice: props.offerTemplate?.otherPrice?.toString() ?? "",
                        termsAndConditions: props.offerTemplate?.termsAndConditionsId ?? ""
                    }}
                    validate={values => {
                        let errors = {} as any;
                        if (!values.packageName) {
                            errors.packageName = "Required";
                        }
                        if (!values.activity) {
                            errors.activity = "Required";
                        }
                        if (!values.persons) {
                            errors.persons = "Required";
                        }
                        if (values.noticePeriod === undefined) {
                            errors.noticePeriod = "Required";
                        }
                        if (!values.package) {
                            errors.package = "Required";
                        }
                        if (!values.location) {
                            errors.location = "Required";
                        }
                        if (!values.instructor) {
                            errors.instructor = "Required";
                        }
                        if (values.expiringDays === undefined) {
                            errors.expiringDays = "Required";
                        }
                        if (!values.downPayment) {
                            errors.downPayment = "Required";
                        }
                        if (!values.termsAndConditions) {
                            errors.termsAndConditions = "Required";
                        }
                        if (values.lessonPrice && isNaN(Number(values.lessonPrice))) {
                            errors.lessonPrice = "Invalid";
                        }
                        if (values.materialPrice && isNaN(Number(values.materialPrice))) {
                            errors.materialPrice = "Invalid";
                        }
                        if (values.othersPrice && isNaN(Number(values.othersPrice))) {
                            errors.othersPrice = "Invalid";
                        }
                        return errors;
                    }}
                    enableReinitialize={true}
                    onSubmit={onSave}
                >
                    {({ values, errors, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
                        <form onSubmit={handleSubmit}>
                            <h1>Offer template:</h1>
                            <hr />
                            <div className="flex-row">
                                <label className="middle-align">Package Name:</label>
                                <TextBox
                                    rounded={false}
                                    invalid={errors && !!errors.packageName}
                                    name="packageName"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.packageName}
                                    placeholder="Package name"
                                />
                            </div>
                            <hr />
                            <h2>General information:</h2>
                            <div className="flex-row">
                                <label className="middle-align">Activity:</label>
                                <Dropdown
                                    name="activity"
                                    value={values.activity}
                                    options={activities.map(ac => ({ label: ac.name, value: ac.id }))}
                                    onChange={value => {
                                        setFieldValue("activity", value ? value : undefined);
                                        setActivity(value);
                                    }}
                                    invalid={errors && !!errors.activity}
                                    placeholder="Select an activity"
                                />
                            </div>
                            <div className="flex-row">
                                <label className="middle-align">Persons:</label>
                                <Dropdown
                                    name="persons"
                                    value={String(values.persons)}
                                    options={personValues}
                                    onChange={value => {
                                        setFieldValue("persons", value ? Number(value) : undefined);
                                    }}
                                    invalid={errors && !!errors.persons}
                                    placeholder="Select the quantity of persons"
                                />
                            </div>
                            <div className="flex-row">
                                <label className="middle-align">Notice period:</label>
                                <Dropdown
                                    name="noticePeriod"
                                    value={String(values.noticePeriod)}
                                    options={noticePeriodValues}
                                    onChange={value => {
                                        setFieldValue("noticePeriod", value ? Number(value) : undefined);
                                    }}
                                    invalid={errors && !!errors.noticePeriod}
                                    placeholder="Select the notice period"
                                />
                            </div>
                            <div className="flex-row">
                                <label className="middle-align">Package:</label>
                                <Dropdown
                                    name="package"
                                    value={String(values.package)}
                                    options={packageValues}
                                    onChange={value => {
                                        setFieldValue("package", value ? value : undefined);
                                    }}
                                    invalid={errors && !!errors.package}
                                    placeholder="Select a package"
                                />
                            </div>
                            <div className="flex-row">
                                <label className="middle-align">Location:</label>
                                <Dropdown
                                    name="location"
                                    value={values.location}
                                    options={locations.map(l => ({
                                        label: l.location.address.fullAddress,
                                        value: l.id
                                    }))}
                                    onChange={value => {
                                        setFieldValue("location", value ? value : undefined);
                                    }}
                                    invalid={errors && !!errors.location}
                                    placeholder="Select a location"
                                />
                            </div>
                            <div className="flex-row">
                                <label className="middle-align">Instructor:</label>
                                <TextBox
                                    rounded={false}
                                    invalid={errors && !!errors.instructor}
                                    name="instructor"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.instructor}
                                    placeholder="Instructor name"
                                />
                            </div>
                            <div className="flex-row">
                                <label className="middle-align">Expiring date:</label>
                                <Dropdown
                                    name="expiringDays"
                                    value={values.expiringDays}
                                    options={expiringDaysValues}
                                    onChange={value => {
                                        setFieldValue("expiringDays", value ? value : undefined);
                                    }}
                                    invalid={errors && !!errors.expiringDays}
                                    placeholder="Select the number of expiring days"
                                />
                            </div>
                            <hr />
                            <h2>Price:</h2>
                            <div className="flex-row">
                                <label className="middle-align">Lesson:</label>
                                <TextBox
                                    rounded={false}
                                    invalid={errors && !!errors.lessonPrice}
                                    name="lessonPrice"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.lessonPrice}
                                    placeholder="Lesson price"
                                />
                            </div>
                            <div className="flex-row">
                                <label className="middle-align">Material:</label>
                                <TextBox
                                    rounded={false}
                                    invalid={errors && !!errors.materialPrice}
                                    name="materialPrice"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.materialPrice}
                                    placeholder="Material price"
                                />
                            </div>
                            <div className="flex-row">
                                <label className="middle-align">Others:</label>
                                <TextBox
                                    rounded={false}
                                    invalid={errors && !!errors.othersPrice}
                                    name="othersPrice"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.othersPrice}
                                    placeholder="Other prices"
                                />
                            </div>
                            <h3>
                                Total price:{" "}
                                {formatCurrency(
                                    checkNumber(values.lessonPrice) +
                                        checkNumber(values.materialPrice) +
                                        checkNumber(values.othersPrice)
                                )}{" "}
                                INCL. TAX
                            </h3>
                            <div className="flex-row">
                                <label className="middle-align">Down payment:</label>
                                <Dropdown
                                    name="downPayment"
                                    value={values.downPayment}
                                    options={downPaymentValues}
                                    onChange={value => {
                                        setFieldValue("downPayment", value ? value : undefined);
                                    }}
                                    invalid={errors && !!errors.downPayment}
                                    placeholder="Select a down payment"
                                />
                            </div>
                            <h3>
                                Down payment:{" "}
                                {formatCurrency(
                                    (checkNumber(values.lessonPrice) +
                                        checkNumber(values.materialPrice) +
                                        checkNumber(values.othersPrice)) *
                                        (checkNumber(values.downPayment) ? Number(values.downPayment) / 100 : 1)
                                )}{" "}
                                INCL. TAX
                            </h3>
                            <hr />
                            <h2>Terms & conditions:</h2>
                            <div className="flex-row">
                                <label className="middle-align">Terms & conditions:</label>
                                <Dropdown
                                    name="termsAndConditions"
                                    value={values.termsAndConditions}
                                    options={
                                        hostStore.host.termsAndConditions?.map(tc => ({
                                            label: tc.name,
                                            value: tc.id
                                        })) ?? []
                                    }
                                    onChange={value => {
                                        setFieldValue("termsAndConditions", value ? value : undefined);
                                    }}
                                    invalid={errors && !!errors.termsAndConditions}
                                    placeholder="Select a terms & conditions"
                                />
                            </div>
                            <hr />
                            <div className="save">
                                <button
                                    disabled={Object.keys(errors).length > 0}
                                    type="submit"
                                    className="btn btn-orange-rounded"
                                >
                                    Save
                                </button>
                            </div>
                        </form>
                    )}
                </Formik>
            </div>
        );
    })
);
