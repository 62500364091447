import { inject, observer } from "mobx-react";
import React, { useContext } from "react";
import { StepperContext } from "../../layout/stepper/Stepper";
import { Formik, FormikHelpers } from "formik";
import { Stores } from "../../../models";
import { validateEmail } from "../../../utils/validations";
import Checkbox from "@mui/material/Checkbox";
import { themeColors } from "@hoverflo/shared/api/utils/constants";
import { findUserByEmail } from "@hoverflo/shared/api/services/user.service";
import { TextBox } from "../../common/TextBox";

export interface CompanyData {
    companyName: string;
    email: string;
    contactPerson: string;
    telephoneNumber: string;
    password: string;
    matchingPassword: string;
    agree: boolean;
}

export const Step1 = inject("rootStore")(
    observer((props: Stores) => {
        const { onboardingStore, messagesStore } = props.rootStore;
        const { onChange } = useContext(StepperContext);

        const onSubmitSignup = async (values: CompanyData, { setErrors }: FormikHelpers<CompanyData>) => {
            const duplicated = await findUserByEmail(values.email);

            if (duplicated) {
                setErrors({ email: `An user with email ${values.email} already exists.` });
                messagesStore.error(`An user with email ${values.email} already exists.`);
            } else {
                onboardingStore.setCompanyData(values);
                onboardingStore.setVisibleSteps(1);
                onChange(2);
            }
        };

        return (
            <Formik
                initialValues={{
                    companyName: onboardingStore.companyData.companyName ?? "",
                    email: onboardingStore.companyData.email ?? "",
                    contactPerson: onboardingStore.companyData.contactPerson ?? "",
                    telephoneNumber: onboardingStore.companyData.telephoneNumber ?? "",
                    password: onboardingStore.companyData.password ?? "",
                    matchingPassword: onboardingStore.companyData.matchingPassword ?? "",
                    agree: onboardingStore.companyData.agree ?? false
                }}
                validate={async values => {
                    // same as above, but feel free to move this into a class method now.
                    let errors: Partial<any> = {};
                    if (!values.companyName) {
                        errors.companyName = "Required";
                    }
                    if (!values.email) {
                        errors.email = "Required";
                    } else if (!validateEmail(values.email)) {
                        errors.email = "Invalid";
                    }
                    if (!values.contactPerson) {
                        errors.contactPerson = "Required";
                    }
                    if (!values.telephoneNumber) {
                        errors.telephoneNumber = "Required";
                    }
                    if (!values.password) {
                        // errors.password = t("form.errors.required");
                        errors.password = "Required";
                    } else {
                        const hasUpperCase = /[A-Z]+/.test(values.password);
                        const hasLowerCase = /[a-z]+/.test(values.password);
                        const hasNumeric = /\d+/.test(values.password);
                        const hasMinimum8 = values.password.length >= 8;

                        const passwordValid = hasUpperCase && hasLowerCase && hasNumeric && hasMinimum8;

                        if (!passwordValid) {
                            errors.password =
                                "Your password must have at least 8 characters containing one upper case (A) and a numeric characters.";
                        }
                    }
                    if (!values.matchingPassword) {
                        // errors.confirmPassword = t("form.errors.required");
                        errors.matchingPassword = "Required";
                    } else {
                        if (values.matchingPassword !== values.password) {
                            errors.matchingPassword = "Passwords do not match";
                        }
                    }
                    if (!values.agree) {
                        errors.agree = "Required";
                    }
                    return errors;
                }}
                onSubmit={onSubmitSignup}
                enableReinitialize={true}
            >
                {({ handleSubmit, values, errors, setFieldValue, handleChange, handleBlur, setErrors }) => (
                    <form onSubmit={handleSubmit}>
                        <h3>
                            <strong>STEP 1:</strong>Creating company content
                        </h3>
                        <TextBox
                            invalid={!!errors.companyName}
                            type="text"
                            name="companyName"
                            placeholder="Company name"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values && values.companyName}
                        />
                        <TextBox
                            invalid={!!errors.email}
                            errorMessage={errors.email as string}
                            type="email"
                            name="email"
                            placeholder="Email"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values && values.email}
                        />
                        <TextBox
                            invalid={!!errors.contactPerson}
                            type="text"
                            name="contactPerson"
                            placeholder="Contact person"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values && values.contactPerson}
                        />
                        <TextBox
                            invalid={!!errors.telephoneNumber}
                            type="text"
                            name="telephoneNumber"
                            placeholder="Telephone number"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values && values.telephoneNumber}
                        />
                        <TextBox
                            invalid={!!errors.password}
                            errorMessage={errors.password as string}
                            type="password"
                            name="password"
                            minLength={6}
                            placeholder="Password"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values && values.password}
                        />
                        <TextBox
                            invalid={!!errors.matchingPassword}
                            errorMessage={errors.matchingPassword}
                            type="password"
                            name="matchingPassword"
                            minLength={6}
                            placeholder="Confirm password"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values && values.matchingPassword}
                        />
                        <div className="container flex-row agree">
                            <Checkbox
                                name="agree"
                                onChange={event => setFieldValue("agree", event.target.checked)}
                                checked={values.agree}
                                sx={{
                                    color: errors.agree ? "#dc3545" : undefined,
                                    "&.Mui-checked": {
                                        color: themeColors.EARTH.background
                                    }
                                }}
                            />
                            <p className="col-11 label">
                                By creating a Hoverflo account,
                                <br />
                                I understand and agree to Hoverflo's Privacy Notice and Terms of Use (Required)
                                <br />
                            </p>
                        </div>
                        <div className="buttons">
                            <button type="submit" className="btn btn-next" disabled={Object.keys(errors).length > 0}>
                                NEXT STEP
                            </button>
                        </div>
                    </form>
                )}
            </Formik>
        );
    })
);
