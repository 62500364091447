import { Checkbox } from "@mui/material";
import { inject, observer } from "mobx-react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Stores } from "../../../models";

import "./SearchResultsList.scss";
import { themeColors } from "@hoverflo/shared/api/utils/constants";
import { TextBox } from "../../common/TextBox";
import { Host, Location } from "@hoverflo/shared/api/models";
import { SearchResponse } from "@hoverflo/shared";

interface SearchResultsListProps extends Stores {
    resultToFocus: SearchResponse;
}

export const SearchResultsList = inject("rootStore")(
    observer((props: SearchResultsListProps) => {
        const { searchStore, sessionStore, messagesStore, routingStore } = props.rootStore;
        const [filter, setFilter] = useState("");
        const lastFocusedResult = useRef<SearchResponse | undefined>(undefined);
        const listRef = useRef<HTMLUListElement>();
        const contactSelectedHosts = () => {
            if (sessionStore.logged) {
                routingStore.pushRoute("/bookings");
            } else {
                messagesStore.warning("Please login to continue");
            }
        };
        const getHostLabel = useCallback(
            (host: Host, location: Location) => {
                const hostResults = searchStore.searchResults?.filter(f => f.host?.id === host.id)?.length ?? 0;
                if (hostResults > 1) {
                    return `${host.name.toUpperCase()} (${location.address.city})`;
                }
                return host.name.toUpperCase();
            },
            [searchStore.searchResults]
        );

        const scrollToItem = (result: SearchResponse) => {
            listRef.current.querySelector(`#host-${result.idHostActivityLocation}`).scrollIntoView();
        };

        useEffect(() => {
            if (lastFocusedResult.current !== props.resultToFocus) {
                lastFocusedResult.current = props.resultToFocus;
                scrollToItem(props.resultToFocus);
            }
        }, [props.resultToFocus]);
        return (
            <div className="search-list-container flex-column">
                <div className="container flex-row">
                    <h1>HOSTS</h1>
                    <h2 style={{ marginLeft: 8 }}>
                        - {searchStore.searchResults.length}{" "}
                        {searchStore.searchResults.length === 1 ? "RESULT" : "RESULTS"}
                    </h2>
                    <img src="assets/Icons/Icon_settings.svg" className="icon_filter" />
                </div>
                <div>
                    <hr />
                </div>
                <TextBox
                    placeholder="Type host name..."
                    value={filter}
                    onChange={event => setFilter(event.target.value)}
                />
                <div>
                    <ul ref={listRef}>
                        {searchStore.searchResults
                            .filter(result =>
                                filter ? result.host.name.toLowerCase().includes(filter.toLowerCase()) : true
                            )
                            .map(result => (
                                <li
                                    onClick={e => {
                                        e.stopPropagation();
                                        const selected =
                                            searchStore.selectedResults.findIndex(
                                                r => r.idHostActivityLocation === result.idHostActivityLocation
                                            ) > -1;
                                        if (selected) {
                                            searchStore.deselectResult(result);
                                        } else {
                                            searchStore.selectResult(result);
                                        }
                                    }}
                                    key={result.idHostActivityLocation}
                                    id={`host-${result.idHostActivityLocation}`}
                                >
                                    <Checkbox
                                        sx={{
                                            color: "#FFF",
                                            "&.Mui-checked": {
                                                color: themeColors.default.primary
                                            }
                                        }}
                                        checked={
                                            searchStore.selectedResults.findIndex(
                                                r => r.idHostActivityLocation === result.idHostActivityLocation
                                            ) > -1
                                        }
                                    />{" "}
                                    {getHostLabel(result.host, result.location)}
                                </li>
                            ))}
                    </ul>
                </div>
                <div>
                    <hr />
                </div>
                <div>
                    <h3>MULTIPLE SELECTION POSSIBLE</h3>
                </div>
                <div>
                    <button
                        className="btn btn-orange-rounded"
                        onClick={contactSelectedHosts}
                        disabled={searchStore.selectedResults.length === 0}
                    >
                        CONTACT HOSTS
                    </button>
                </div>
            </div>
        );
    })
);
