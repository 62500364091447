import { inject, observer } from "mobx-react";
import { Stores } from "../../../models";
import { setFocus } from "../../../utils";
import { Location } from "@hoverflo/shared";
import { TextBox } from "../../common/TextBox";
import React, { useCallback, useState } from "react";
import { debounce } from "../../../utils/debounce";
import { getAutocompleteAddresses } from "@hoverflo/shared/api/services/address.service";
import { errorHandler } from "../../../utils/errorHandler";
import { CancelRounded } from "@mui/icons-material";
import CircularProgress from "@mui/material/CircularProgress";

export const popularLocations: Location[] = [
    {
        name: "",
        address: { city: "Scheveningen", country: "Netherlands" },
        coordination: { latitude: 52.11872566492131, longitude: 4.288167129119113 }
    },
    {
        name: "",
        address: { city: "Tenerife", country: "Spain" },
        coordination: { latitude: 28.469801, longitude: -16.254841 }
    },
    {
        name: "",
        address: { city: "Finistere", country: "France" },
        coordination: { latitude: 48.353968769359184, longitude: -4.085538317147771 }
    }
];

export const SearchLocations = inject("rootStore")(
    observer((props: Stores) => {
        const { searchStore, loadingStore, messagesStore } = props.rootStore;
        const [isSearching, setIsSearching] = useState(false);
        const [locations, setLocations] = useState<Location[]>([]);
        const [filter, setFilter] = useState("");

        const getCurrentLocation = async () => {
            if ("geolocation" in navigator) {
                return new Promise((resolve, reject) => {
                    loadingStore.triggerLoading();
                    return navigator.geolocation.getCurrentPosition(
                        position => {
                            searchStore.setLocation({
                                name: "",
                                address: { city: "Current Location", country: "" },
                                coordination: {
                                    longitude: position.coords.longitude,
                                    latitude: position.coords.latitude
                                }
                            });
                            loadingStore.stopLoading();
                            resolve(true);
                        },
                        error => {
                            loadingStore.stopLoading();
                            messagesStore.error("Unable to get current location");
                            reject(new Error("Unable to get current location"));
                        }
                    );
                });
            }
            return Promise.resolve(false);
        };

        const onSearch = useCallback(
            debounce((value: string) => onFilterLocation(value), 500),
            []
        );

        const onFilterLocation = (filter: string) => {
            if (!!filter.trim()) {
                setIsSearching(true);
                getAutocompleteAddresses(filter.trim().toLowerCase(), "nl")
                    .then(addresses => {
                        setIsSearching(false);
                        setLocations(
                            addresses.map((address, id) => ({
                                id,
                                name: address.name,
                                coordination: {
                                    latitude: address.point.latitude,
                                    longitude: address.point.longitude
                                },
                                address: {
                                    city: address.name?.split(",")?.[0] ?? address.city,
                                    country: address.country ?? "",
                                    postalCode: address.zipCode ?? "",
                                    province: address.region ?? "",
                                    fullAddress: address.name ?? ""
                                }
                            }))
                        );
                    })
                    .catch(async e => {
                        await errorHandler(e, props.rootStore);
                        setIsSearching(false);
                        messagesStore.error("Error while searching locations.");
                    });
            }
        };

        return (
            <div className="location-field" onTouchStart={() => setFocus("location-field")} tabIndex={0} role="button">
                {!searchStore.location && (
                    <div className="field-title">
                        <div className="left-align medium-text">LOCATIONS</div>
                        <TextBox
                            id="txt-box-search-activity"
                            rounded={false}
                            placeholder="Where do you want to go?"
                            value={filter}
                            onChange={event => {
                                setFilter(event.target.value);
                                onSearch(event.target.value);
                            }}
                        />
                    </div>
                )}
                {searchStore.location && (
                    <div className="middle-align medium-text selected-item">
                        <span>
                            {searchStore.location.address.city}
                            {searchStore.location.address.country ? "," : ""} {searchStore.location.address.country}
                        </span>
                        <CancelRounded
                            fontSize="small"
                            onClick={() => searchStore.setLocation(undefined)}
                            titleAccess="Clear"
                        />
                    </div>
                )}
                <div className="dropdown-style">
                    <hr />
                    <ul>
                        <li
                            onClick={async () => await getCurrentLocation()}
                            role="button"
                            style={{ cursor: "pointer" }}
                            title="Use current location"
                        >
                            <img src="assets/Icons/Icon_current_location.svg" />
                            USE CURRENT LOCATION
                        </li>
                    </ul>
                    <hr />
                    {filter ? (
                        <>
                            <h2>Locations</h2>
                            {isSearching ? (
                                <CircularProgress color="inherit" size={24} style={{ marginBottom: "8px" }} />
                            ) : (
                                <ul>
                                    {locations.map(location => (
                                        <li
                                            key={`${location.coordination.longitude}_${location.coordination.latitude}`}
                                            onClick={() => {
                                                searchStore.setLocation(location);
                                            }}
                                            role="button"
                                            style={{ cursor: "pointer" }}
                                            title={`Select ${location.address.city} as location`}
                                        >
                                            <img src="assets/Icons/Icon_navigation.svg" />
                                            {location.address.city}, {location.address.country}
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </>
                    ) : (
                        <>
                            <h2>Popular Destination</h2>
                            <ul>
                                {popularLocations.map(location => (
                                    <li
                                        key={`${location.coordination.longitude}_${location.coordination.latitude}`}
                                        onClick={() => {
                                            searchStore.setLocation(location);
                                        }}
                                        role="button"
                                        style={{ cursor: "pointer" }}
                                        title="Select location"
                                    >
                                        <img src="assets/Icons/Icon_navigation.svg" />
                                        {location.address.city}, {location.address.country}
                                    </li>
                                ))}
                            </ul>
                        </>
                    )}
                </div>
            </div>
        );
    })
);
