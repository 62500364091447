import { inject, observer } from "mobx-react";
import { Stores } from "../../models";
import React, { useEffect } from "react";
import { getCurrentSession } from "@hoverflo/shared/api/utils/login.utils";
import { getCurrentUser } from "@hoverflo/shared/api/services/user.service";
import { errorHandler } from "../../utils/errorHandler";
import { useInterval, usePollingEffect } from "../layout/chat/hooks";
import differenceInSeconds from "date-fns/differenceInSeconds";

export const SessionChecker = inject("rootStore")(
    observer((props: Stores) => {
        const { sessionStore } = props.rootStore;

        // Checks when the component mounts if there is no session but there is something
        // in the local storage and then sets the session based on the local storage
        useEffect(() => {
            async function checkSession() {
                const session = await getCurrentSession();
                if (!sessionStore.logged && session) {
                    try {
                        await getCurrentUser();
                        sessionStore.setCurrentSession(session);
                    } catch (e) {
                        await errorHandler(e, props.rootStore);
                    }
                }
            }
            checkSession();
        }, []);

        // Executes a session check every 10 seconds
        usePollingEffect(
            async () => {
                // Checks if the user is logged and the session contains an expiration (When logging with social networks it doesn't)
                if (sessionStore.logged && sessionStore.currentSession?.expiresAt) {
                    const diff = differenceInSeconds(new Date(sessionStore.currentSession.expiresAt), new Date());
                    // If the difference between expiration and now is < 30 seconds we are going to try renewing the session
                    if (diff < 30) {
                        await errorHandler({ message: "401" } as any, props.rootStore);
                        console.debug("Session renewed automatically until ", sessionStore.currentSession.expiresAt);
                    }
                }
            },
            [],
            { interval: 10000 }
        );
        return null;
    })
);
