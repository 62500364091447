import { Stores } from "../../../models";
import { inject, observer } from "mobx-react";
import { InputSearchLocation } from "../../common/input-search-location/InputSearchLocation";
import { HostActivity, HostActivityLocation } from "@hoverflo/shared";
import { InputSeasonSelector } from "../become-host/SeasonSelector";
import { IconButton } from "@mui/material";
import { AddCircle, RemoveCircle } from "@mui/icons-material";
import { themeColors } from "@hoverflo/shared/api/utils/constants";
import React, { useState } from "react";
import i18n from "../../../i18n";
import { SeasonMap } from "../become-host/SeasonMap";
import classNames from "classnames";
import {
    addActivityLocation,
    deleteActivityLocation,
    editActivityLocation,
    getHost
} from "@hoverflo/shared/api/services/host.service";
import { errorHandler } from "../../../utils/errorHandler";

interface HostLocationsProps extends Stores {
    disabled: boolean;
    onSuccess: () => void;
}

interface EditableHostActivityLocation extends HostActivityLocation {
    isTemporary?: boolean;
}

export const HostLocations = inject("rootStore")(
    observer((props: HostLocationsProps) => {
        const { hostStore, loadingStore, messagesStore } = props.rootStore;
        const [focusedActivity, setFocusedActivity] = useState<HostActivity>();
        const [locationsToBeDeleted, setLocationsToBeDeleted] = useState<HostActivityLocation[]>([]);

        const onSave = async () => {
            try {
                loadingStore.triggerLoading();
                const newLocations = hostStore.host.activities
                    .flatMap(ac => ac.locations.map(loc => ({ ...loc, activityId: ac.activity?.id })))
                    .filter((loc: EditableHostActivityLocation) => loc.isTemporary)
                    .map((loc: EditableHostActivityLocation) => addActivityLocation({ ...loc, id: undefined }));

                const editedLocations = hostStore.host.activities
                    .flatMap(ac => ac.locations.map(loc => ({ ...loc, activityId: ac.activity?.id })))
                    .filter((loc: EditableHostActivityLocation) => !loc.isTemporary && loc.id)
                    .map((loc: EditableHostActivityLocation) => editActivityLocation(loc));

                if (newLocations.length > 0) {
                    try {
                        const newLocs = await Promise.all(newLocations);
                        newLocs.forEach(loc => hostStore.addActivityLocation(loc));
                    } catch (e) {
                        await errorHandler(e, props.rootStore);
                    }
                }

                if (editedLocations.length > 0) {
                    const updatedLocs = await Promise.all(editedLocations);
                    updatedLocs.forEach(loc => hostStore.updateActivityLocation(loc));
                }

                if (locationsToBeDeleted.length > 0) {
                    await Promise.all(locationsToBeDeleted.map(l => deleteActivityLocation(l)));
                    locationsToBeDeleted.forEach(loc => hostStore.removeActivityLocation(loc));
                    setLocationsToBeDeleted([]);
                }

                const id = focusedActivity.id;
                const updatedHost = await getHost(hostStore.host.generalInfo.id);
                // Clean the screen data because key is currently an id and id changes in the backend
                setFocusedActivity(undefined);
                hostStore.setHost(updatedHost);
                // Define the new screen data
                const activity = updatedHost.activities?.find(ac => ac.id === id);
                activity.locations.sort((a, b) => (a.mainSpot ? -1 : b.mainSpot ? 1 : 0));
                setFocusedActivity(activity);

                loadingStore.stopLoading();
                messagesStore.success("Locations successfully updated");
                props.onSuccess();
            } catch (e) {
                loadingStore.stopLoading();
                messagesStore.error(e.message);
            }
        };

        return (
            <div className="locations-list">
                <div className={`col-md-${focusedActivity ? 6 : 12}`}>
                    <h1>Edit or add locations &amp; seasons below</h1>
                    <hr />
                    <select
                        className="form-control"
                        value={focusedActivity?.id}
                        onChange={event => {
                            const activity = hostStore.host?.activities?.find(ac => ac.id === event.target.value);
                            activity.locations.sort((a, b) => (a.mainSpot ? -1 : b.mainSpot ? 1 : 0));
                            setFocusedActivity(activity);
                        }}
                        placeholder="For which activity do you want to select a location?"
                    >
                        <option selected={!focusedActivity?.id} disabled>
                            For which activity do you want to select a location?
                        </option>
                        {hostStore.host?.activities
                            ?.slice()
                            .sort((a, b) => a.activity?.name?.localeCompare(b.activity?.name))
                            .map(ac => (
                                <option key={ac.id} value={ac.id}>
                                    {ac.activity?.name.toUpperCase()}
                                </option>
                            ))}
                    </select>
                    <hr />
                    {focusedActivity && <SeasonMap hostActivity={focusedActivity} disabled={props.disabled} />}
                    <div className="save">
                        <button
                            disabled={
                                props.disabled ||
                                hostStore.host?.activities
                                    ?.flatMap(a => a.locations)
                                    .some(
                                        l =>
                                            l.location?.address === undefined ||
                                            !l.location?.fromDate ||
                                            !l.location?.tillDate
                                    )
                            }
                            onClick={onSave}
                            type="button"
                            className="btn btn-orange-rounded"
                        >
                            SAVE
                        </button>
                    </div>
                </div>
                {focusedActivity && (
                    <div className="col-md-6">
                        <h1>
                            <strong>Location List:</strong>
                            <span>{focusedActivity.activity?.name}</span>
                        </h1>
                        <div className="location-header__content">
                            <div>Spots:</div>
                            <div>Seasons:</div>
                        </div>
                        <hr />
                        {focusedActivity.locations.map((location, index) => (
                            <div
                                className={classNames("flex-row align-items-center", {
                                    "main-spot": location.mainSpot
                                })}
                                key={location.id}
                            >
                                <div
                                    className="combined-form-picker"
                                    id={`combined-picker-${
                                        location.mainSpot ? "main" : "additional"
                                    }-location-${index}`}
                                >
                                    <InputSearchLocation
                                        closeOnSelect
                                        disabled={!focusedActivity || props.disabled}
                                        onSelectLocation={newLocation => {
                                            hostStore.updateLocation(focusedActivity, {
                                                ...location,
                                                location: {
                                                    ...location.location,
                                                    ...newLocation
                                                }
                                            } as HostActivityLocation);

                                            setFocusedActivity(
                                                hostStore.host?.activities?.find(ac => ac.id === focusedActivity.id)
                                            );
                                        }}
                                        country="nl"
                                        placeholder={location.mainSpot ? "Main spot" : `Additional spot ${index}`}
                                        value={location.location?.name ?? ""}
                                        portalId={`combined-picker-${
                                            location.mainSpot ? "main" : "additional"
                                        }-location-${index}`}
                                    />
                                    <InputSeasonSelector
                                        disabled={props.disabled}
                                        onChange={(startDate, endDate) => {
                                            hostStore.updateLocation(focusedActivity, {
                                                ...location,
                                                location: {
                                                    ...location.location,
                                                    fromDate: startDate,
                                                    tillDate: endDate
                                                }
                                            } as HostActivityLocation);

                                            setFocusedActivity(
                                                hostStore.host?.activities?.find(ac => ac.id === focusedActivity.id)
                                            );
                                        }}
                                        locale={i18n.language as any}
                                        startDate={
                                            location.location?.fromDate
                                                ? new Date(location.location?.fromDate)
                                                : undefined
                                        }
                                        endDate={
                                            location.location?.tillDate
                                                ? new Date(location.location?.tillDate)
                                                : undefined
                                        }
                                    />
                                </div>
                                {location.mainSpot && focusedActivity.locations.filter(l => !l.mainSpot).length < 4 && (
                                    <IconButton
                                        disabled={props.disabled}
                                        title="Add location"
                                        onClick={() => {
                                            hostStore.addLocation(focusedActivity);
                                            setFocusedActivity(
                                                hostStore.host.activities.find(ac => ac.id === focusedActivity.id)
                                            );
                                        }}
                                    >
                                        <AddCircle
                                            sx={{ color: !props.disabled ? themeColors.EARTH.background : undefined }}
                                        />
                                    </IconButton>
                                )}
                                {!location.mainSpot && (
                                    <div>
                                        <IconButton
                                            disabled={props.disabled}
                                            title="Remove location"
                                            onClick={() => {
                                                if (window.confirm("Are sure you want to delete this location?")) {
                                                    if (!(location as any).isTemporary) {
                                                        setLocationsToBeDeleted(prev => [...prev, location]);
                                                    }
                                                    hostStore.removeLocation(focusedActivity, location);
                                                    setFocusedActivity(
                                                        hostStore.host?.activities?.find(
                                                            ac => ac.id === focusedActivity.id
                                                        )
                                                    );
                                                }
                                            }}
                                        >
                                            <RemoveCircle
                                                sx={{
                                                    color: !props.disabled ? themeColors.EARTH.background : undefined
                                                }}
                                            />
                                        </IconButton>
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                )}
            </div>
        );
    })
);
