import { inject, observer } from "mobx-react";
import { Stores } from "../../../models";
import { setFocus } from "../../../utils";
import { ThemeType } from "@hoverflo/shared";
import { TextBox } from "../../common/TextBox";
import React, { useCallback, useState } from "react";
import { CancelRounded } from "@mui/icons-material";

const categories = [
    { value: ThemeType.WATER, label: "Water" },
    { value: ThemeType.EARTH, label: "Earth" },
    { value: ThemeType.ALPINE, label: "Alpine" },
    { value: ThemeType.AIR, label: "Air" }
];

export const SearchActivities = inject("rootStore")(
    observer((props: Stores) => {
        const { activityStore, searchStore } = props.rootStore;
        const [filter, setFilter] = useState("");

        const existsActivity = useCallback(
            (category: ThemeType) => {
                return (
                    (searchStore.showMoreActivities || !!filter.length
                        ? activityStore.activities
                        : activityStore.popularActivities
                    )
                        .filter(activity => activity.type === category)
                        .filter(activity =>
                            filter ? activity.name.toLowerCase().includes(filter.toLowerCase()) : true
                        ).length > 0
                );
            },
            [searchStore.showMoreActivities, filter, activityStore.activities, activityStore.popularActivities]
        );

        return (
            <div className="activity-field" onTouchStart={() => setFocus("activity-field")} tabIndex={0} role="button">
                {!searchStore.activity && (
                    <div className="field-title">
                        <div className="left-align medium-text">ACTIVITY</div>
                        <TextBox
                            id="txt-box-search-activity"
                            rounded={false}
                            placeholder="What do you like to do?"
                            value={filter}
                            onChange={event => setFilter(event.target.value)}
                        />
                    </div>
                )}
                {searchStore.activity && (
                    <div className="middle-align medium-text selected-item">
                        <span>{searchStore.activity.name}</span>
                        <CancelRounded
                            fontSize="small"
                            onClick={() => searchStore.setActivity(undefined)}
                            titleAccess="Clear"
                        />
                    </div>
                )}
                <div className="dropdown-style">
                    <hr />
                    <h2>{searchStore.showMoreActivities ? "All Activities" : "Popular Activities"}</h2>
                    <hr />
                    <div className="list-max-length">
                        {categories.map(category => (
                            <div key={category.value}>
                                {existsActivity(category.value) && <h2>{category.label}</h2>}
                                <ul>
                                    {(searchStore.showMoreActivities || !!filter.length
                                        ? activityStore.activities
                                        : activityStore.popularActivities
                                    )
                                        .filter(activity => activity.type === category.value)
                                        .filter(activity =>
                                            filter ? activity.name.toLowerCase().includes(filter.toLowerCase()) : true
                                        )
                                        .map(activity => {
                                            return (
                                                <li
                                                    key={activity.id}
                                                    onClick={() => {
                                                        searchStore.setActivity(activity);
                                                        setFilter("");
                                                    }}
                                                    role="button"
                                                    style={{ cursor: "pointer" }}
                                                    title="Select activity"
                                                >
                                                    <img src="assets/Icons/Icon_hoverflo.svg" />
                                                    {activity.name}
                                                </li>
                                            );
                                        })}
                                </ul>
                            </div>
                        ))}
                    </div>
                    <hr />
                    <div onClick={() => searchStore.toggleShowMoreActivities()} style={{ width: "100%" }}>
                        {searchStore.showMoreActivities && <h3>Show popular activities</h3>}
                        {!searchStore.showMoreActivities && <h3>Show all activities</h3>}
                    </div>
                </div>
            </div>
        );
    })
);
