import { Stores } from "../../../models";
import { inject, observer } from "mobx-react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { deleteHost, getHost, getHosts, updateGeneralInfo } from "@hoverflo/shared/api/services/host.service";
import "./HostManagement.scss";
import { TextBox } from "../../common/TextBox";
import { Button, DialogContent, DialogTitle, IconButton, Switch } from "@mui/material";
import { FirstPage, LastPage, NavigateBefore, NavigateNext } from "@mui/icons-material";
import Dialog from "@mui/material/Dialog";
import { Host, HostInfo } from "@hoverflo/shared/api/models/host";
import { validateEmail } from "../../../utils/validations";
import { InputSearchLocation } from "../../common/input-search-location/InputSearchLocation";
import { Formik } from "formik";
import { errorHandler } from "../../../utils/errorHandler";
import { Location } from "@hoverflo/shared";
import { themeColors } from "@hoverflo/shared/api/utils/constants";

const LIMIT = 20;
interface HostProfileInfo {
    name: string;
    subtitle: string;
    biography: string;
    address: string;
    email: string;
    phoneNumber: string;
    website: string;
    activeProfile: boolean;
}

export const HostManagement = inject("rootStore")(
    observer((props: Stores) => {
        const { hostStore, loadingStore, messagesStore } = props.rootStore!;
        const [filter, setFilter] = useState("");
        const [page, setPage] = useState(1);
        const [pageLimit, setPageLimit] = useState(0);
        const [total, setTotal] = useState(0);
        const [showDetails, setShowDetails] = useState(false);
        const [focusedHost, setFocusedHost] = useState<HostInfo | undefined>();
        const [location, setLocation] = useState<Location>(undefined);

        useEffect(() => {
            if (hostStore.hosts.length === 0) {
                loadingStore.triggerLoading();
                getHosts()
                    .then(hosts => {
                        hostStore.setHosts(hosts);
                        loadingStore.stopLoading();
                    })
                    .catch(() => loadingStore.stopLoading());
            }
        }, []);

        useEffect(() => {
            const filteredHosts = hostStore.hosts
                .slice()
                .filter(h => (filter ? h.name.toLowerCase().includes(filter.toLowerCase()) : true));
            setTotal(filteredHosts.length);
            setPageLimit(Math.round(filteredHosts.length / LIMIT));
        }, [filter, hostStore.hosts]);

        const loadHost = useCallback(async (id: string) => {
            try {
                loadingStore.triggerLoading();
                const hostDetails = await getHost(id);
                setFocusedHost(hostDetails);
                setShowDetails(true);
                loadingStore.stopLoading();
            } catch (e) {
                loadingStore.stopLoading();
            }
        }, []);

        const onSave = async ({
            name,
            subtitle,
            biography,
            email,
            phoneNumber,
            website,
            activeProfile
        }: HostProfileInfo) => {
            loadingStore.triggerLoading();
            try {
                const newGeneralInfo = {
                    ...focusedHost?.generalInfo,
                    name,
                    subtitle,
                    biography,
                    contact: {
                        ...focusedHost?.generalInfo?.contact,
                        email,
                        phoneNumber,
                        website,
                        ...(location ? { contactAddress: location?.address } : {})
                    },
                    activeProfile
                } as Host;
                const generalInfo = await updateGeneralInfo(newGeneralInfo);
                const newHost = await getHost(generalInfo.id);
                hostStore.updateHost(newHost);
                loadingStore.stopLoading();
                messagesStore.success("Host successfully saved");
            } catch (e) {
                await errorHandler(e, props.rootStore!);
                loadingStore.stopLoading();
                messagesStore.error("Error occurred while saving host.");
                console.error(e);
            }
        };

        const deleteHostHandler = useCallback(async (id: string) => {
            setShowDetails(false);
            if (window.confirm("Are sure you want to delete this host?")) {
                try {
                    await deleteHost(id);
                    hostStore.removeHost(id);
                    setFocusedHost(undefined);
                    messagesStore.success("Host successfully deleted");
                } catch (e) {
                    messagesStore.error("Error occurred while deleting host");
                    console.error(e);
                }
            } else {
                setShowDetails(true);
            }
        }, []);

        return (
            <>
                <Dialog open={showDetails} onClose={() => setShowDetails(false)} maxWidth="md" fullWidth={true}>
                    <DialogTitle sx={{ backgroundColor: "#717274", color: "#fff" }}>
                        Host Details - {focusedHost?.generalInfo?.name}
                    </DialogTitle>
                    <DialogContent sx={{ backgroundColor: "#717274" }} className="host-management-details">
                        <Formik
                            initialValues={{
                                name: focusedHost?.generalInfo?.name ?? "",
                                subtitle: focusedHost?.generalInfo?.subtitle ?? "",
                                biography: focusedHost?.generalInfo?.biography ?? "",
                                address: focusedHost?.generalInfo?.contact?.contactAddress?.city ?? "",
                                email: focusedHost?.generalInfo?.contact?.email ?? "",
                                phoneNumber: focusedHost?.generalInfo?.contact.phoneNumber ?? "",
                                website: focusedHost?.generalInfo?.contact.website ?? "",
                                activeProfile: focusedHost?.generalInfo?.activeProfile ?? false
                            }}
                            validate={values => {
                                let errors = {} as any;
                                if (!values.name) {
                                    errors.name = "Required";
                                }
                                if (!values.address) {
                                    errors.address = "Required";
                                }
                                if (!values.email) {
                                    errors.email = "Required";
                                } else if (!validateEmail(values.email)) {
                                    errors.email = "Invalid";
                                }

                                return errors;
                            }}
                            enableReinitialize={true}
                            onSubmit={onSave}
                        >
                            {({ values, errors, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
                                <form className="general-info-form" onSubmit={handleSubmit}>
                                    <h1>General Information:</h1>
                                    <hr />
                                    <h2>Company data</h2>
                                    <div className="flex-row">
                                        <label className="middle-align">Company Name</label>
                                        <TextBox
                                            rounded={false}
                                            invalid={errors && !!errors.name}
                                            name="name"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.name}
                                            placeholder="Company name"
                                        />
                                    </div>
                                    <div className="flex-row">
                                        <label className="middle-align">Subtitle</label>
                                        <TextBox
                                            rounded={false}
                                            invalid={errors && !!errors.subtitle}
                                            name="subtitle"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.subtitle}
                                            placeholder="Subtitle"
                                        />
                                    </div>
                                    <hr />
                                    <h2>Biography</h2>
                                    <div className="flex-row">
                                        <textarea
                                            value={values.biography}
                                            name="biography"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            placeholder="Type a short description"
                                            rows={4}
                                        ></textarea>
                                    </div>
                                    <hr />
                                    <h2>Contact information</h2>
                                    <div className="flex-row">
                                        {/* Fix for width difference between div and input */}
                                        <label className="middle-align" style={{ width: "25%" }}>
                                            Address
                                        </label>
                                        <InputSearchLocation
                                            country="nl"
                                            onSelectLocation={location => {
                                                setLocation(location);
                                                setFieldValue("address", location.name);
                                            }}
                                            value={values.address}
                                            placeholder="Contact address"
                                            rounded={false}
                                        />
                                    </div>
                                    <div className="flex-row">
                                        <label className="middle-align">Email</label>
                                        <TextBox
                                            type="email"
                                            rounded={false}
                                            invalid={errors && !!errors.email}
                                            name="email"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.email}
                                            placeholder="Email"
                                        />
                                    </div>
                                    <div className="flex-row">
                                        <label className="middle-align">Phone number</label>
                                        <TextBox
                                            rounded={false}
                                            invalid={errors && !!errors.phoneNumber}
                                            name="phoneNumber"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.phoneNumber}
                                            placeholder="Phone number"
                                        />
                                    </div>
                                    <div className="flex-row">
                                        <label className="middle-align">Website URL</label>
                                        <TextBox
                                            rounded={false}
                                            invalid={errors && !!errors.website}
                                            name="website"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.website}
                                            placeholder="Website URL"
                                        />
                                    </div>
                                    <hr className="margin-top" />
                                    <h2>Activate profile</h2>
                                    <div className="flex-row align-items-center">
                                        <Switch
                                            color="primary"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            name="activeProfile"
                                            checked={!!values.activeProfile}
                                            sx={{
                                                // color: "#FFF",
                                                "&.MuiSwitch-switchBase": {
                                                    "&.Mui-checked": {
                                                        color: themeColors.default.primary,
                                                        "& + .MuiSwitch-track": {
                                                            backgroundColor: themeColors.default.primary
                                                        }
                                                    }
                                                }
                                            }}
                                        />
                                        <span className="switch-label">
                                            Host profile is currently{" "}
                                            <strong
                                                style={{
                                                    color: values.activeProfile ? themeColors.default.text : undefined
                                                }}
                                            >
                                                {values.activeProfile ? "ACTIVE" : "INACTIVE"}
                                            </strong>{" "}
                                            for customers
                                        </span>
                                    </div>
                                    <hr />
                                    <div className="save">
                                        <button
                                            disabled={!location || Object.keys(errors).length > 0}
                                            type="submit"
                                            className="btn btn-orange-rounded"
                                        >
                                            Save
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-cancel-rounded"
                                            onClick={() => {
                                                setShowDetails(false);
                                                setFocusedHost(undefined);
                                            }}
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-delete-rounded"
                                            onClick={() => deleteHostHandler(focusedHost.generalInfo.id)}
                                        >
                                            Delete host
                                        </button>
                                    </div>
                                </form>
                            )}
                        </Formik>
                    </DialogContent>
                </Dialog>
                <div className="host-management-container">
                    <div className="host-management-content">
                        <div className="host-management-header">
                            <h2>HOSTS MANAGEMENT</h2>
                            <h3>
                                {hostStore.hosts.length} HOST,{" "}
                                {hostStore.hosts.slice().filter(h => h.contact?.contactAddress!!).length} HOSTS
                                ONBOARDED
                            </h3>
                        </div>
                        <div className="host-management-info">
                            <TextBox
                                placeholder="Type the host name"
                                value={filter}
                                onChange={e => {
                                    setFilter(e.target.value);
                                    setPage(1);
                                }}
                            />
                            <table>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>City</th>
                                        <th>Phone number</th>
                                        <th>Website</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {hostStore.hosts
                                        .filter(h =>
                                            filter ? h.name.toLowerCase().includes(filter.toLowerCase()) : true
                                        )
                                        .slice(page === 1 ? 0 : (page - 1) * LIMIT, page * LIMIT)
                                        .map(host => (
                                            <tr key={host.id} onClick={() => loadHost(host.id)}>
                                                <td>{host.name}</td>
                                                <td>{host.contact?.contactAddress?.city ?? "-"}</td>
                                                <td>{host.contact?.phoneNumber ?? "-"}</td>
                                                <td>{host.contact?.website ?? "-"}</td>
                                            </tr>
                                        ))}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td colSpan={4} align="center">
                                            <IconButton
                                                title="First"
                                                disabled={page === 1}
                                                onClick={() => {
                                                    setPage(1);
                                                }}
                                            >
                                                <FirstPage />
                                            </IconButton>
                                            <IconButton
                                                title="Previous"
                                                disabled={page - 1 < 1}
                                                onClick={() => {
                                                    setPage(prev => (prev - 1 > 0 ? prev - 1 : prev));
                                                }}
                                            >
                                                <NavigateBefore />
                                            </IconButton>
                                            <span>
                                                {page - 1 === 0 ? 1 : (page - 1) * LIMIT + 1} to{" "}
                                                {page * LIMIT > total ? total : page * LIMIT} of {total}
                                            </span>
                                            <IconButton
                                                title="Next"
                                                disabled={page + 1 > pageLimit}
                                                onClick={() => {
                                                    setPage(prev => (prev + 1 <= pageLimit ? prev + 1 : prev));
                                                }}
                                            >
                                                <NavigateNext />
                                            </IconButton>
                                            <IconButton
                                                title="Last"
                                                disabled={page === pageLimit}
                                                onClick={() => setPage(pageLimit)}
                                            >
                                                <LastPage />
                                            </IconButton>
                                        </td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                </div>
            </>
        );
    })
);
