export var RequestStatus;
(function (RequestStatus) {
    RequestStatus["UNSENT"] = "UNSENT";
    RequestStatus["REQUESTED"] = "REQUESTED";
    RequestStatus["OFFER"] = "OFFERED";
    RequestStatus["REJECTED"] = "REJECTED";
    RequestStatus["CONFIRMED"] = "CONFIRMED";
    RequestStatus["COMPLETED"] = "COMPLETED";
    RequestStatus["CANCELLED"] = "CANCELLED";
})(RequestStatus || (RequestStatus = {}));
