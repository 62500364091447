import { DateRangePicker } from "react-date-range";
import { themeColors } from "@hoverflo/shared/api/utils/constants";
import { Dialog, IconButton } from "@mui/material";
import { DateRange, CancelRounded, CheckCircle } from "@mui/icons-material";
import React, { useState } from "react";
import { addDays } from "date-fns";
import { enUS, fr, nl, it, de } from "date-fns/locale";

import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { formatLocalizedDate } from "../../../utils";

interface SeasonSelectorProps {
    startDate?: Date;
    endDate?: Date;
    onChange: (startDate: Date, endDate: Date) => void;
    open: boolean;
    onClose: () => void;
    locale?: "en" | "fr" | "nl" | "it" | "de";
}

export function SeasonSelector(props: SeasonSelectorProps) {
    const [range, setRange] = useState({
        startDate: props.startDate ?? new Date(),
        endDate: props.endDate ?? addDays(new Date(), 10)
    });
    let locale;
    switch (props.locale) {
        case "de":
            locale = de;
            break;
        case "fr":
            locale = fr;
            break;
        case "it":
            locale = it;
            break;
        case "nl":
            locale = nl;
            break;
        default:
            locale = enUS;
            break;
    }
    return (
        <Dialog open={props.open} onClose={props.onClose}>
            <DateRangePicker
                locale={locale}
                ranges={[
                    {
                        ...range,
                        key: "range"
                    }
                ]}
                editableDateInputs={false}
                onChange={rangesByKey => {
                    const { startDate, endDate } = rangesByKey["range"] as { startDate: Date; endDate: Date };
                    setRange({ startDate, endDate });
                }}
                // showPreview={false}
                showDateDisplay={false}
                // fixedHeight={false}
                // staticRanges={[]}
                // inputRanges={[]}
                className="calendar-date-range"
                rangeColors={[themeColors.default.primary]}
            />
            <div className="dialog-buttons">
                <IconButton onClick={props.onClose}>
                    <CancelRounded />
                </IconButton>
                <IconButton
                    onClick={() => {
                        props.onChange(range.startDate, range.endDate);
                        props.onClose();
                    }}
                >
                    <CheckCircle sx={{ color: themeColors.default.primary }} />
                </IconButton>
            </div>
        </Dialog>
    );
}

interface InputSeasonSelectorProps {
    locale?: "en" | "fr" | "nl" | "it" | "de";
    onChange: (startDate: Date, endDate: Date) => void;
    startDate?: Date;
    endDate?: Date;
    disabled?: boolean;
}

export function InputSeasonSelector(props: InputSeasonSelectorProps) {
    const [open, setOpen] = useState(false);
    return (
        <div className="date-picker">
            <SeasonSelector
                locale={props.locale}
                open={open}
                onClose={() => setOpen(false)}
                startDate={props?.startDate}
                endDate={props?.endDate}
                onChange={(startDate, endDate) => props.onChange(startDate, endDate)}
            />
            {!props?.startDate && !props?.endDate && <span>FROM - TILL</span>}
            {props?.startDate && props?.endDate && (
                <>
                    {formatLocalizedDate(props.startDate, "MMM dd", props.locale).toLocaleUpperCase()} -{" "}
                    {formatLocalizedDate(props.endDate, "MMM dd", props.locale).toLocaleUpperCase()}
                </>
            )}
            <IconButton onClick={() => setOpen(true)} disabled={props.disabled}>
                <DateRange />
            </IconButton>
        </div>
    );
}
