import { action, computed, makeAutoObservable, observable } from "mobx";
import { Activity, Location, SearchResponse, ThemeType } from "@hoverflo/shared/api/models";

export class SearchStore {
    @observable activity: Activity | undefined = undefined;
    @observable location: Location | undefined = undefined;
    @observable distance: number = 10;

    @observable.deep searchResults: SearchResponse[] = [];
    @observable.deep selectedResults: SearchResponse[] = [];

    @observable showMoreActivities = false;

    constructor() {
        makeAutoObservable(this);
    }

    @action setActivity(activity: Activity) {
        this.activity = activity;
    }

    @action setLocation(location: Location) {
        this.location = location;
    }

    @action setDistance(distance: number) {
        this.distance = distance;
    }

    @action setSearchResults(searchResults: SearchResponse[]) {
        this.searchResults = searchResults;
    }

    @action toggleShowMoreActivities() {
        this.showMoreActivities = !this.showMoreActivities;
    }

    @computed getTheme(): ThemeType | "default" {
        if (!this.activity || !this.activity.type) {
            return "default";
        }
        return this.activity.type;
    }

    @action clearSelectedResults() {
        this.selectedResults = [];
    }

    @action selectResult(result: SearchResponse) {
        if (this.selectedResults.findIndex(r => r.idHostActivityLocation === result.idHostActivityLocation) === -1) {
            this.selectedResults.push(result);
        }
    }

    @action deselectResult(result: SearchResponse) {
        if (this.selectedResults.includes(result)) {
            const index = this.selectedResults.findIndex(
                r => r.idHostActivityLocation === result.idHostActivityLocation
            );
            const selectedResults = [...this.selectedResults];
            selectedResults.splice(index, 1);
            this.selectedResults = selectedResults;
        }
    }

    @action isSelected(result: SearchResponse) {
        return this.selectedResults.findIndex(r => r.idHostActivityLocation === result.idHostActivityLocation) > -1;
    }
}
