import { LoadingStore } from "@hoverflo/shared/api/stores/loading.store";
import { SessionStore } from "@hoverflo/shared/api/stores/session.store";
import { makeAutoObservable } from "mobx";
import { ActivityStore } from "@hoverflo/shared/api/stores/activity.store";
import { ChatStore } from "./chat.store";
import { MessagesStore } from "./messages.store";
import { SidebarStore } from "./sidebar.store";
import { UserStore } from "./user.store";
import { SearchStore } from "./search.store";
import { RoutingStore } from "./routing.store";
import { RouterStore } from "mobx-react-router";
import { RequestStore } from "@hoverflo/shared/api/stores/request.store";
import { OnboardingStore } from "./onboarding.store";
import { HostStore } from "./host.store";
import { OfferTemplateStore } from "./offer-template.store";

export class RootStore {
    loadingStore: LoadingStore;
    routingStore: RoutingStore;
    routerStore: RouterStore;
    sessionStore: SessionStore;
    activityStore: ActivityStore;
    chatStore: ChatStore;
    messagesStore: MessagesStore;
    sidebarStore: SidebarStore;
    userStore: UserStore;
    searchStore: SearchStore;
    requestStore: RequestStore;
    onboardingStore: OnboardingStore;
    hostStore: HostStore;
    offerTemplateStore: OfferTemplateStore;

    constructor() {
        makeAutoObservable(this);

        this.loadingStore = new LoadingStore();
        this.routingStore = new RoutingStore(this);
        this.routerStore = new RouterStore();
        this.sessionStore = new SessionStore();
        this.activityStore = new ActivityStore();
        this.chatStore = new ChatStore();
        this.messagesStore = new MessagesStore();
        this.sidebarStore = new SidebarStore();
        this.userStore = new UserStore();
        this.searchStore = new SearchStore();
        this.requestStore = new RequestStore();
        this.onboardingStore = new OnboardingStore();
        this.hostStore = new HostStore();
        this.offerTemplateStore = new OfferTemplateStore();
    }
}
