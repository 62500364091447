import Map, { MapRef, Marker, NavigationControl } from "react-map-gl";
import { mapStylesUrl } from "../../../utils/constants";
import { config } from "@hoverflo/shared/api/utils/constants";
import React, { useEffect, useState } from "react";
import { HostActivity } from "@hoverflo/shared";

interface SeasonMapProps {
    hostActivity: HostActivity;
    disabled?: boolean;
}

export function SeasonMap(props: SeasonMapProps) {
    const [mapRef, setMapRef] = useState<MapRef>();
    const [mapLoaded, setMapLoaded] = useState(false);
    const [centerLatitude, setCenterLatitude] = useState(52.527203013218184);
    const [centerLongitude, setCenterLongitude] = useState(5.695452185041854);
    const [bounds, setBounds] = useState({ s: 0, w: 0, n: 0, e: 0 });

    useEffect(() => {
        if (props.hostActivity?.locations?.length > 0) {
            const locations = props.hostActivity.locations.filter(f => f?.location);
            const n = Math.max(...locations.map(h => h.location.coordination?.longitude ?? 0));
            const e = Math.max(...locations.map(h => h.location.coordination?.latitude ?? 0));
            const s = Math.min(...locations.map(h => h.location.coordination?.longitude ?? 0));
            const w = Math.min(...locations.map(h => h.location.coordination?.latitude ?? 0));
            const latCenter =
                locations.map(h => h.location.coordination?.latitude ?? 0).reduce((prev, curr) => prev + curr, 0) /
                locations.length;
            const lngCenter =
                locations.map(h => h.location.coordination?.longitude ?? 0).reduce((prev, curr) => prev + curr, 0) /
                locations.length;
            setCenterLatitude(latCenter);
            setCenterLongitude(lngCenter);
            setBounds({ n, e, s, w });
            if (mapRef) {
                mapRef.setCenter([lngCenter, latCenter]);
                mapRef.fitBounds(
                    [
                        [s, w],
                        [n, e]
                    ],
                    { padding: 25 }
                );
            }
        }
    }, [props.hostActivity, mapRef]);

    useEffect(() => {
        if (mapRef && !mapLoaded) {
            setTimeout(() => {
                // Force the map to repaint after load for the first time
                window.dispatchEvent(new Event("resize"));
                setMapLoaded(true);
                if (centerLatitude > 0 && centerLongitude > 0) {
                    mapRef.setCenter([centerLongitude, centerLatitude]);
                }
                if (bounds.s > 0 && bounds.w > 0 && bounds.n > 0 && bounds.e > 0) {
                    mapRef.fitBounds(
                        [
                            [bounds.s, bounds.w],
                            [bounds.n, bounds.e]
                        ],
                        { padding: 25 }
                    );
                }
            }, 1000);
        }
    }, [mapRef, centerLatitude, centerLongitude, bounds]);

    useEffect(() => {
        setTimeout(() => window.dispatchEvent(new Event("resize")), 1000);
    }, []);

    return (
        <Map
            ref={r => {
                if (r && !mapRef) {
                    setMapRef(r);
                }
            }}
            mapStyle={mapStylesUrl.AIR}
            mapboxAccessToken={config.mapboxKey}
            initialViewState={{ latitude: centerLatitude, longitude: centerLongitude, zoom: 5 }}
            scrollZoom={false}
            maxZoom={15}
            padding={{ bottom: 20, top: 20, left: 20, right: 20 }}
            dragPan={!props.disabled}
            touchPitch={!props.disabled}
            doubleClickZoom={!props.disabled}
        >
            {props.hostActivity?.locations
                ?.filter(f => f?.location)
                .map((result, index) => (
                    <Marker
                        key={result.id ?? `location_${index}`}
                        longitude={result.location.coordination.longitude}
                        latitude={result.location.coordination.latitude}
                        anchor="bottom"
                        offset={result.mainSpot ? [0, -4] : [0, -2]}
                        style={{ cursor: "pointer" }}
                    >
                        {result.mainSpot && <img src="/assets/Icons/marker.svg" width={32} />}
                        {!result.mainSpot && <img src="/assets/Icons/marker-black.svg" width={24} />}
                    </Marker>
                ))}
            {!props.disabled && <NavigationControl />}
        </Map>
    );
}
