import { inject, observer } from "mobx-react";
import { Stores } from "../../../../models";
import { Formik, FormikHelpers } from "formik";
import { Route } from "../../../../stores/sidebar.store";
import classNames from "classnames";

import { forgotEmail } from "@hoverflo/shared/api/services/login.service";

import "./ForgotPassword.scss";
import { TextBox } from "../../../common/TextBox";

export const ForgotPassword = inject("rootStore")(
    observer((props: Stores) => {
        const { sidebarStore, loadingStore, messagesStore, routingStore } = props.rootStore;
        const routeToLogin = () => sidebarStore.changeRoute(Route.LOGIN);
        const onSubmit = async (values: { email: string }, { setErrors }: FormikHelpers<any>) => {
            loadingStore.triggerLoading();
            try {
                await forgotEmail(values.email);
                messagesStore.success("An email was sent with details on how to change your password.");
            } catch (e: any) {
                messagesStore.error(e.error_description ?? e.message);
                setErrors({
                    // email: t("login.errors.invalid-user")
                    email: "Invalid email"
                });
            } finally {
                loadingStore.stopLoading();
            }
        };

        // const { t } = useTranslation();

        return (
            <Formik
                initialValues={{
                    email: "",
                    password: ""
                }}
                validate={values => {
                    let errors: any = {};
                    if (!values.email) {
                        // errors.email = t("form.errors.required");
                        errors.email = "Required";
                    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
                        // errors.email = t("form.errors.invalid");
                        errors.email = "Invalid";
                    }
                    return errors;
                }}
                onSubmit={onSubmit}
            >
                {({ values, errors, handleChange, handleBlur, handleSubmit, resetForm }) => (
                    <div className="login-container text-white flex-column">
                        <form onSubmit={handleSubmit}>
                            <div>
                                <h1>Forgot password</h1>
                            </div>

                            <div>
                                <hr />
                            </div>

                            <div>
                                <TextBox
                                    invalid={errors && !!errors.email}
                                    type="email"
                                    name="email"
                                    placeholder="email"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values && values.email}
                                />
                            </div>

                            <div>
                                <button
                                    className={classNames("btn btn-rounded", {
                                        disabled: Object.keys(errors).length > 0
                                    })}
                                >
                                    continue
                                </button>
                            </div>

                            <div>
                                <hr className="hr2" />
                            </div>

                            <div>
                                <button
                                    type="button"
                                    className="btn btn-host"
                                    onClick={() => routingStore.pushRoute("/become-host")}
                                >
                                    become a host
                                </button>
                            </div>

                            <div>
                                <button onClick={routeToLogin} className="btn btn-rounded">
                                    cancel
                                </button>
                            </div>

                            <div>
                                <p>
                                    * professional use / respond to bookers
                                    <br />
                                    offer your services
                                </p>
                            </div>
                        </form>
                    </div>
                )}
            </Formik>
        );
    })
);
