import { inject, observer } from "mobx-react";
import React, { useState } from "react";

import { SearchBar } from "../../layout/search-bar/SearchBar";
import { Stores } from "../../../models";
import { loremIpsum } from "../../../utils/constants";
import { Explore as ExploreType, ThemeType } from "@hoverflo/shared/api/models";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import "swiper/css/effect-coverflow";
import "swiper/css/mousewheel";
import "swiper/css/keyboard";

import "./Explore.scss";
import { A11y, Navigation, Pagination, Mousewheel, Keyboard, Autoplay, EffectCoverflow } from "swiper";

const exploreAll: Array<ExploreType> = [
    {
        id: "1",
        title: "Go Kitesurfing at moana kitesurf in zandvoort (NL)",
        note: "with 10% dicount on your first lesson",
        description: loremIpsum,
        image: "https://i.ibb.co/p3bkyRR/Abseiling.jpg",
        activity: {
            id: "38f192fb-0ce6-432e-8dfa-5b0b97e69a79",
            name: "Abseiling",
            image: "https://i.ibb.co/p3bkyRR/Abseiling.jpg",
            type: ThemeType.ALPINE,
            noOfAvailableHosts: 6
        },
        discount: 10
    },
    {
        id: "2",
        title: "Mountainbiking in the zeisterbossen with dutch outdoors",
        note: "girls only",
        description: loremIpsum,
        image: "https://i.ibb.co/J5yxYSt/Mountain-Biking.jpg",
        activity: {
            id: "962b2159-7620-4858-bf7c-7226c4274452",
            name: "Mountain Biking",
            image: "https://i.ibb.co/J5yxYSt/Mountain-Biking.jpg",
            type: ThemeType.ALPINE,
            noOfAvailableHosts: 5
        }
    },
    {
        id: "3",
        title: "Learn wave surfing",
        note: "on a dutch island",
        description: loremIpsum,
        image: "https://i.ibb.co/jfjJc6f/Wavesurf.jpg",
        activity: {
            id: "7125cbc4-6b5d-4b41-a71d-005f3d740cfe",
            name: "Wavesurf",
            image: "https://i.ibb.co/jfjJc6f/Wavesurf.jpg",
            type: ThemeType.ALPINE,
            noOfAvailableHosts: 2
        }
    },
    {
        id: "4",
        title: "Beach Cleanup @the shore / Scheveningen",
        note: "",
        description: loremIpsum,
        image: "https://i.ibb.co/Z6rxSQM/Beach-cleanup.png",
        activity: {
            id: "26891218-8b28-47ea-86b9-1332dd0dee26",
            name: "hiking",
            description: "Hiking",
            type: ThemeType.ALPINE,
            image: "https://i.ibb.co/tLQC8Yq/Hiking.jpg",
            noOfAvailableHosts: 0
        }
    },
    {
        id: "5",
        title: "Go Kitesurfing at moana kitesurf in zandvoort (NL)",
        note: "with 10% dicount on your first lesson",
        description: loremIpsum,
        image: "https://i.ibb.co/p3bkyRR/Abseiling.jpg",
        activity: {
            id: "38f192fb-0ce6-432e-8dfa-5b0b97e69a79",
            name: "Abseiling",
            image: "https://i.ibb.co/p3bkyRR/Abseiling.jpg",
            type: ThemeType.ALPINE,
            noOfAvailableHosts: 6
        },
        discount: 10
    },
    {
        id: "6",
        title: "Mountainbiking in the zeisterbossen with dutch outdoors",
        note: "girls only",
        description: loremIpsum,
        image: "https://i.ibb.co/J5yxYSt/Mountain-Biking.jpg",
        activity: {
            id: "962b2159-7620-4858-bf7c-7226c4274452",
            name: "Mountain Biking",
            image: "https://i.ibb.co/J5yxYSt/Mountain-Biking.jpg",
            type: ThemeType.ALPINE,
            noOfAvailableHosts: 5
        }
    },
    {
        id: "7",
        title: "Learn wave surfing",
        note: "on a dutch island",
        description: loremIpsum,
        image: "https://i.ibb.co/jfjJc6f/Wavesurf.jpg",
        activity: {
            id: "7125cbc4-6b5d-4b41-a71d-005f3d740cfe",
            name: "Wavesurf",
            image: "https://i.ibb.co/jfjJc6f/Wavesurf.jpg",
            type: ThemeType.ALPINE,
            noOfAvailableHosts: 2
        }
    },
    {
        id: "8",
        title: "Beach Cleanup @the shore / Scheveningen",
        note: "",
        description: loremIpsum,
        image: "https://i.ibb.co/Z6rxSQM/Beach-cleanup.png",
        activity: {
            id: "26891218-8b28-47ea-86b9-1332dd0dee26",
            name: "hiking",
            description: "Hiking",
            type: ThemeType.ALPINE,
            image: "https://i.ibb.co/tLQC8Yq/Hiking.jpg",
            noOfAvailableHosts: 0
        }
    }
];

export const Explore = inject("rootStore")(
    observer((props: Stores) => {
        const { activityStore, searchStore } = props.rootStore;
        const [showDetails, setShowDetails] = useState(false);
        const [selectedExplore, setSelectedExplore] = useState<ExploreType>();

        return (
            <div className="explore-container container-fluid">
                <div>
                    <SearchBar hideTitle />
                </div>

                <div>
                    {showDetails && (
                        <div className="flex-row image-detail">
                            <div className="col-6">
                                <Swiper
                                    modules={[
                                        Navigation,
                                        Pagination,
                                        A11y,
                                        Autoplay,
                                        Mousewheel,
                                        Keyboard,
                                        EffectCoverflow
                                    ]}
                                    className="swiper-container"
                                    direction="horizontal"
                                    effect="coverflow"
                                    loop
                                    speed={1000}
                                    autoplay={{ delay: 2000, disableOnInteraction: false }}
                                    slidesPerView={2}
                                    keyboard
                                    mousewheel
                                    navigation
                                    pagination={false}
                                    centeredSlides
                                >
                                    {/* TODO: Change this to support multiple images coming from the APIs */}
                                    <SwiperSlide className="swiper-slide">
                                        <img src={selectedExplore.activity.image} />
                                    </SwiperSlide>
                                    <SwiperSlide className="swiper-slide">
                                        <img src="https://i.ibb.co/47L2RS6/Bungee-Jump.jpg" />
                                    </SwiperSlide>
                                    <SwiperSlide className="swiper-slide">
                                        <img src="https://i.ibb.co/x8PtfMF/Hoverboarding.jpg" />
                                    </SwiperSlide>
                                </Swiper>
                            </div>
                            <div className="col-4">
                                <div className="icons-bar col-11">
                                    <div className="desc-icons flex-row justify-content-end">
                                        {/*TODO: Add action to buttons */}
                                        {/*<img src="assets/Icons/Icon_social_share.svg" />*/}
                                        {/*<img src="assets/Icons/Icon_social_save.svg" />*/}
                                        {/*<img src="assets/Icons/Icon_social_like.svg" />*/}
                                        <img
                                            src="assets/Icons/Icon_close.svg"
                                            style={{ cursor: "pointer" }}
                                            title="Close details"
                                            onClick={() => {
                                                setShowDetails(false);
                                            }}
                                        />
                                    </div>
                                    <div>
                                        <h1>{selectedExplore.title}</h1>
                                    </div>
                                    <hr />
                                    <div className="desc">{selectedExplore.description}</div>
                                    <hr />
                                    <div className="flex-row justify-content-sm-around button-container">
                                        <button
                                            className="btn btn-rounded"
                                            onClick={() => {
                                                setShowDetails(false);
                                            }}
                                        >
                                            BACK TO OVERVIEW
                                        </button>
                                        <button
                                            className="btn btn-orange-rounded"
                                            onClick={() => {
                                                const activity = activityStore.activities.find(
                                                    a => a.id === selectedExplore.activity.id
                                                );
                                                if (activity) {
                                                    searchStore.setActivity(activity);
                                                }
                                            }}
                                        >
                                            SEARCH HOSTS
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    {!showDetails && (
                        <div className="grid grid-lg-3 grid-md-2 grid-sm-1 image-tiles">
                            <div className="explore-info">
                                <div className="explore-message">
                                    <div>
                                        <p className="info-head">
                                            Hoverflo selected for you <br />
                                            the coolest activites
                                        </p>
                                        <hr />
                                        <p className="info-desc">
                                            Get Inspired! Together with our partners we made a <br />
                                            selection of Sed blandit ut mi a lobortis. Fusce vulputate <br />
                                            semper eros in ultrices. Donec mollis dui eget ipsum <br />
                                            gravida rutrum.
                                        </p>
                                    </div>
                                </div>
                                <div
                                    className="explore-list-first-element"
                                    style={{ backgroundImage: `url('${exploreAll[0].image}')` }}
                                    onClick={() => {
                                        setSelectedExplore(exploreAll[0]);
                                        setShowDetails(true);
                                    }}
                                >
                                    <div className="explore-title">
                                        <h2>{exploreAll[0].title}</h2>
                                        <h3>{exploreAll[0].note}</h3>
                                    </div>
                                </div>
                            </div>
                            {exploreAll.slice(1).map(explore => (
                                <div
                                    key={explore.id}
                                    className="grid-item explore-list-rest-of-element"
                                    onClick={() => {
                                        setSelectedExplore(explore);
                                        setShowDetails(true);
                                    }}
                                    style={{ backgroundImage: `url('${explore.image}')` }}
                                >
                                    <div className="explore-title">
                                        <h2>{explore.title}</h2>
                                        <h3>{explore.note}</h3>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        );
    })
);
