import { inject, observer } from "mobx-react";
import { Stores } from "../../../../models";
import { Route } from "../../../../stores/sidebar.store";

import { config, keycloakConfig } from "@hoverflo/shared/api/utils/constants";
import { getCurrentUser } from "@hoverflo/shared/api/services/user.service";
import { Session } from "@hoverflo/shared";
import { setCurrentSession } from "@hoverflo/shared/api/utils/login.utils";
import { useKeycloak } from "@react-keycloak/web";

import "./RegisterWith.scss";

export const RegisterWith = inject("rootStore")(
    observer((props: Stores) => {
        const { loadingStore, messagesStore, routingStore, sidebarStore, sessionStore, userStore } = props.rootStore;
        const { keycloak, initialized } = useKeycloak();
        const routeToRegister = () => sidebarStore.changeRoute(Route.REGISTER);
        const routeToAccountOverview = () => sidebarStore.changeRoute(Route.ACCOUNT_OVERVIEW);
        const routeToLogin = () => sidebarStore.changeRoute(Route.LOGIN);
        const socialLogin = async (provider: "google" | "facebook") => {
            if (!initialized) {
                messagesStore.error("Error while performing social login");
                return;
            }
            await keycloak.login({
                redirectUri: config.url + "/",
                idpHint: provider,
                scope: keycloakConfig.scopes[0]
            });
            const { token: accessToken, idToken, refreshToken } = keycloak;
            if (accessToken && idToken && refreshToken) {
                const user = await getCurrentUser({
                    accessToken
                } as Session);
                if (user) {
                    const session: Session = {
                        accessToken,
                        lastLogin: new Date(),
                        idToken,
                        refreshToken,
                        user
                    };
                    sessionStore.setCurrentSession(session);
                    userStore.setUser(user);
                    await setCurrentSession(session);
                    sidebarStore.changeRoute(Route.ACCOUNT_OVERVIEW);
                    loadingStore.stopLoading();
                }
            } else {
                loadingStore.stopLoading();
                messagesStore.error("Error while performing social login");
            }
        };
        return (
            <div className="register-with-container flex-column text-white">
                <div>
                    <h1>create account</h1>
                </div>

                <div>
                    <hr />
                </div>

                <div>
                    <button className="btn btn-white-rounded btn-13" onClick={routeToRegister}>
                        <img src="assets/Icons/Icon_email.svg" className="icon_style_email" />
                        proceed with email
                    </button>
                </div>
                <div>
                    <button className="btn btn-white-rounded btn-13" onClick={() => socialLogin("google")}>
                        <img src="assets/Icons/Icon_google.png" className="icon_style" />
                        proceed with google
                    </button>
                </div>
                <div>
                    <button className="btn btn-white-rounded btn-13" onClick={() => socialLogin("facebook")}>
                        <img src="assets/Icons/Icon_facebook.png" className="icon_style" />
                        proceed with facebook
                    </button>
                </div>

                <div>
                    <hr className="hr2" />
                </div>

                <div>
                    <p>for professional use:</p>
                </div>

                <div>
                    <button
                        type="button"
                        className="btn btn-host"
                        onClick={() => routingStore.pushRoute("/become-host")}
                    >
                        become a host
                    </button>
                </div>

                <div>
                    <button onClick={routeToAccountOverview} className="btn btn-rounded">
                        cancel
                    </button>
                </div>
                <div>
                    <hr className="hr2" />
                </div>
                <div>
                    <p>
                        already have account?{" "}
                        <a className="link_style" onClick={routeToLogin}>
                            login
                        </a>
                    </p>
                </div>
            </div>
        );
    })
);
