import { inject, observer } from "mobx-react";
import { Stores } from "../../../models";
import Map, { NavigationControl } from "react-map-gl";
import { InputSearchLocation } from "../../common/input-search-location/InputSearchLocation";
import React, { useState } from "react";
import { Location } from "@hoverflo/shared/api/models";
import { mapStylesUrl } from "../../../utils/constants";
import { config } from "@hoverflo/shared/api/utils/constants";

import "./Locations.scss";

const locationList = [
    { value: "nl", label: "The Netherlands", disabled: false },
    { value: "be", label: "Belgium - COMING SOON", disabled: true },
    { value: "de", label: "Germany - COMING SOON", disabled: true },
    { value: "fr", label: "France - COMING SOON", disabled: true },
    { value: "es", label: "Spain - COMING SOON", disabled: true },
    { value: "pt", label: "Portugal - COMING SOON", disabled: true }
];

const rangeList = [1, 2, 5, 10, 15, 30, 50, 100];

const defaultLatitude = 52.17826407598622;
const defaultLongitude = 5.497907265611973;

export const Locations = inject("rootStore")(
    observer((props: Stores) => {
        const { searchStore, routingStore } = props.rootStore;
        const [country, setCountry] = useState("");
        const [range, setRange] = useState("");
        const [location, setLocation] = useState<Location>();

        const onSubmit = () => {
            searchStore.setLocation(location);
            searchStore.setDistance(Number(range));
            routingStore.pushRoute("/activities");
        };
        return (
            <div className="locations-container row">
                <div className="col-6">
                    <div className="coming-soon">
                        <h3>
                            coming
                            <br />
                            soon
                        </h3>
                        <hr />
                        <h4>
                            Germany,
                            <br />
                            France, Spain &amp; <br />
                            Portugal
                        </h4>
                    </div>
                    <Map
                        mapStyle={mapStylesUrl.WATER}
                        mapboxAccessToken={config.mapboxKey}
                        initialViewState={{ latitude: defaultLatitude, longitude: defaultLongitude, zoom: 4 }}
                        scrollZoom={false}
                        maxZoom={15}
                    >
                        <NavigationControl showCompass={false} showZoom visualizePitch />
                    </Map>
                </div>
                <div className="col-4 flex-column">
                    <h1>
                        Location
                        <br />
                        Where
                        <br />
                        Hoverflo
                        <br />
                        represented
                    </h1>
                    <div>
                        <hr />
                    </div>
                    <div>
                        <select
                            className="form-control input-rounded"
                            onChange={event => setCountry(event.target.value)}
                            value={country}
                        >
                            <option>COUNTRY</option>
                            {locationList.map(country => (
                                <option key={country.value} value={country.value} disabled={country.disabled}>
                                    {country.label}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="location-wrapper">
                        <InputSearchLocation onSelectLocation={setLocation} country={country} />
                    </div>
                    <div>
                        <select
                            className="form-control input-rounded"
                            onChange={event => setRange(event.target.value)}
                            value={range}
                        >
                            <option>RANGE</option>
                            {rangeList.map(range => (
                                <option key={`range_${range}`} value={range}>
                                    + {range} KM
                                </option>
                            ))}
                        </select>
                    </div>
                    <div>
                        <hr className="hr2" />
                    </div>
                    <div>
                        <button
                            className="btn btn-blue-rounded"
                            disabled={!country || !range || !location}
                            onClick={onSubmit}
                        >
                            Search Activity
                        </button>
                    </div>
                </div>
            </div>
        );
    })
);
