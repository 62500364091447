export const mapStylesUrl = {
    default: "mapbox://styles/vadivel654/cksu4pu6y00c117phr8wk6cj4",
    WATER: "mapbox://styles/vadivel654/cksu3n8th4si517qivuu4z2lk",
    EARTH: "mapbox://styles/vadivel654/cksu3odim2j6118o2hhtczeri",
    ALPINE: "mapbox://styles/vadivel654/cksu3ovlm0vmf17o4yf8360k2",
    AIR: "mapbox://styles/vadivel654/cksu4sorw3ctt18pflgzwg0qw"
};

export const loremIpsum = `
Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec lobortis vestibulum molestie. Integer dignissim tincidunt arcu, non consequat dolor laoreet sit amet. Cras ultricies sit amet velit a vehicula. Aenean lacus massa, placerat consectetur porta non, porttitor nec nibh. Quisque bibendum sem in suscipit pulvinar. Proin quis magna dictum, fermentum tortor eget, tempus est. Interdum et malesuada fames ac ante ipsum primis in faucibus. Nam augue urna, feugiat nec massa nec, faucibus ultrices leo. Praesent fermentum lorem ut est mattis efficitur. Sed semper dapibus tellus, sed volutpat lorem consequat non.

Pellentesque diam quam, vestibulum non dapibus vel, rhoncus nec ex. Phasellus porttitor mi sed quam sagittis, ac malesuada tortor luctus. Mauris eleifend eget quam sit amet porta. Praesent convallis sodales odio nec ullamcorper. Nullam id orci nec dui posuere consequat non ut turpis. Praesent vitae mattis ante. Fusce eu gravida augue, eget feugiat libero. Cras nibh turpis, imperdiet at dolor a, luctus rhoncus tellus. Curabitur scelerisque, felis a iaculis ultrices, nibh purus dapibus orci, eget sodales lorem nisl et sapien. Pellentesque et porta mi. Maecenas nec risus eu nisi porttitor pellentesque in rhoncus augue. Vestibulum ultrices dolor mi, sit amet viverra ligula varius ut. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Nulla malesuada suscipit eros, at lobortis nulla molestie in. Nam fermentum velit et tristique consequat.

Aenean ac tortor non lorem dapibus congue. Mauris eget ex pulvinar lacus efficitur tristique. Nulla facilisi. Donec faucibus, nunc ac bibendum eleifend, odio diam vulputate mauris, in dictum quam libero eu est. Vestibulum porta purus vel lorem maximus dictum. Mauris elementum a sem id sodales. Nunc finibus egestas dui ac ultrices. `;
