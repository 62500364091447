import { useEffect, useRef } from "react";
export function setTime(date, hours, minutes, seconds) {
    date.setHours(hours);
    date.setMinutes(minutes);
    date.setSeconds(seconds);
    return date;
}
export function setDate(day, month, year) {
    const date = new Date();
    date.setFullYear(year, month, day);
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    return date;
}
export function setDateTime(day, month, year, hours, minutes, seconds) {
    const date = new Date();
    date.setFullYear(year, month, day);
    date.setHours(hours);
    date.setMinutes(minutes);
    date.setSeconds(seconds);
    return date;
}
export function daysBetweenDates(startDate, endDate) {
    const oneDay = 1000 * 60 * 60 * 24;
    const start = Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());
    const end = Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
    return (start - end) / oneDay;
}
export function useInterval(callback, delay) {
    const savedCallback = useRef();
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);
    useEffect(() => {
        function tick() {
            var _a;
            (_a = savedCallback.current) === null || _a === void 0 ? void 0 : _a.call(savedCallback);
        }
        const id = setInterval(tick, delay);
        return () => clearInterval(id);
    }, [callback, delay]);
}
export function handlePaging(paging) {
    return `${(paging === null || paging === void 0 ? void 0 : paging.page) !== undefined ? `?page=${paging.page}` : ""}${(paging === null || paging === void 0 ? void 0 : paging.size) !== undefined ? `${paging.page !== undefined ? "&" : "?"}size=${paging.size}` : ""}${(paging === null || paging === void 0 ? void 0 : paging.sort)
        ? `${paging.page !== undefined || paging.size !== undefined ? "&" : "?"}sort=${paging.sort.join(",")}`
        : ""}`;
}
