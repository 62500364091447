import classNames from "classnames";
import { inject, observer } from "mobx-react";
import { Stores } from "../../../models";
import { SearchBar } from "../../layout/search-bar/SearchBar";

import "./LandingPage.scss";
import React, { useEffect, useState } from "react";
import { verifyEmail } from "@hoverflo/shared/api/services/login.service";
import { SearchResultsList } from "../../layout/search-results-list/SearchResultsList";
import { SearchResultsMap } from "../../layout/search-results-map/SearchResultsMap";
import { SearchResponse } from "@hoverflo/shared";

export const LandingPage = inject("rootStore")(
    observer((props: Stores) => {
        const {
            searchStore,
            routerStore: { location },
            messagesStore,
            routingStore
        } = props.rootStore;

        const [selectedResult, setSelectedResult] = useState<SearchResponse | undefined>(undefined);

        useEffect(() => {
            if (location.search) {
                const id = new URLSearchParams(location.search).get("id");
                if (id) {
                    verifyEmail(id)
                        .then(() => {
                            messagesStore.success("Email Verified. Please proceed with login");
                            routingStore.pushRoute("/");
                        })
                        .catch(e => messagesStore.error(e.message));
                }
            }
        }, []);

        return (
            <div className={classNames("landing-page", { "show-results": searchStore.searchResults.length > 0 })}>
                <div className="box-column">
                    <SearchBar />
                    {searchStore.searchResults.length > 0 && (
                        <div className="box-row map-container">
                            <div className="w-42">
                                <SearchResultsList resultToFocus={selectedResult} />
                            </div>
                            <div className="w-52">
                                <SearchResultsMap onSelectResult={result => setSelectedResult(result)} />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    })
);
