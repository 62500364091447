import { HostInfo } from "@hoverflo/shared";
import React, { useMemo } from "react";
import { checkEmpty, formatLocalizedDate } from "../../../utils";

export function HostProfileInfo({ host }: { host: HostInfo }) {
    const activityLocations = useMemo(
        () => host?.activities?.flatMap(ac => ac.locations).sort((a, b) => (a === b ? 0 : b ? -1 : 1)),
        [host]
    );
    const profileImage = host.images?.find(i => !!i.profileImage);
    return (
        <>
            <div>
                <h2>PREVIEW</h2>
            </div>
            <hr />
            <div className="row">
                <div
                    className="host-logo"
                    style={{
                        backgroundImage: `url("${
                            profileImage?.imageUri ?? "/assets/design_files/logo/Hoverflo_LOGO_final_FEB21-black.jpg"
                        }")`
                    }}
                />
                <div className="container col host-name">
                    <h3>{host?.generalInfo?.name}</h3>
                    <h4>{checkEmpty(host?.generalInfo?.subtitle, "(No subtitle)")}</h4>
                </div>
            </div>
            <div className="full-detail">
                <hr />
                <div>
                    <h6>{checkEmpty(host?.generalInfo?.biography, "(No biography added)")}</h6>
                </div>
                <hr />
                <div className="row">
                    <h5 className="text">Home:</h5>
                    <div className="content">
                        {checkEmpty(
                            host?.locations?.find(l => l.headquarter)?.location.address.city,
                            "(No headquarter added)"
                        )}
                    </div>
                </div>
                <div className="row">
                    <h5 className="text">Activities:</h5>
                    <div className="content">
                        {host?.activities?.map(hostActivity => (
                            <div key={hostActivity?.activity?.id}>{hostActivity?.activity?.name}</div>
                        ))}
                    </div>
                </div>
                <div className="row">
                    <h5 className="text">Spots:</h5>
                    <div className="content">
                        {activityLocations
                            ?.map(loc => `${loc.location?.address?.city} ${loc.mainSpot ? " (HQ)" : ""}`)
                            .map((location, index) => (
                                <div key={`location_${index}`}>{location}</div>
                            ))}
                    </div>
                </div>
                <div className="row">
                    <h5 className="text">Seasons:</h5>
                    <div className="content">
                        {Array.from(
                            new Set(
                                activityLocations
                                    ?.filter(loc => loc.location?.fromDate && loc.location.tillDate)
                                    .map(
                                        loc =>
                                            `${formatLocalizedDate(
                                                new Date(loc.location?.fromDate!),
                                                "MMM"
                                            )}  - ${formatLocalizedDate(new Date(loc.location?.tillDate!), "MMM")}`
                                    )
                            )
                        ).map((season, index) => (
                            <div key={`season_${index}`}>{season}</div>
                        ))}
                    </div>
                </div>
                <div className="row">
                    <h5 className="text">Bookings:</h5>
                    <div className="content">coming soon!</div>
                </div>
                <div className="row">
                    <h5 className="text">Reviews:</h5>
                    <div className="content">
                        <div>
                            {Array.from({ length: host?.generalInfo?.googleRating ?? 0 }).map((_, index) => (
                                <span key={`checked_${index}`} className="fa fa-star checked" />
                            ))}
                            {Array.from({ length: 5 - (host?.generalInfo?.googleRating ?? 0) }).map((_, index) => (
                                <span key={`unchecked_${index}`} className="fa fa-star unchecked" />
                            ))}
                            <span>
                                ({checkEmpty(host?.generalInfo?.googleUserRatingsTotal?.toString(), "0")} Reviews)
                            </span>
                        </div>
                    </div>
                </div>
                <div className="row host-image-list">
                    {host?.images
                        ?.filter(i => !i.profileImage)
                        .map(image => (
                            <div
                                key={image.id}
                                className="host-image"
                                style={{ backgroundImage: `url("${image.imageUri}")` }}
                            />
                        ))}
                </div>
            </div>
        </>
    );
}
