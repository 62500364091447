import { observable, action, makeAutoObservable } from "mobx";
import { OfferTemplate } from "@hoverflo/shared/api/models";

export class OfferTemplateStore {
    @observable.deep offers: OfferTemplate[] = [];

    constructor() {
        makeAutoObservable(this);
    }

    @action setOffers(activities: OfferTemplate[]) {
        this.offers = activities;
    }

    @action updateOffer(offer: OfferTemplate) {
        const offers = [...this.offers];
        const index = offers.findIndex(c => c.id === offer.id);
        if (index > -1) {
            offers[index] = offer;
            this.offers = offers;
        }
    }

    @action getOfferById(id: string): OfferTemplate | undefined {
        return this.offers.find(c => c.id === id);
    }

    @action addOffer(offer: OfferTemplate) {
        this.offers = [...this.offers, offer];
    }

    @action removeOffer(offer: OfferTemplate) {
        const offers = [...this.offers];
        const index = offers.findIndex(c => c.id === offer.id);
        if (index > -1) {
            offers.splice(index, 1);
            this.offers = offers;
        }
    }
}
