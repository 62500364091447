import { inject, observer } from "mobx-react";
import { Stores } from "../../../../models";
import { Formik, FormikHelpers } from "formik";
import { Route } from "../../../../stores/sidebar.store";
import classNames from "classnames";
import addSeconds from "date-fns/addSeconds";
// import { useTranslation } from "react-i18next";

import { performLogin } from "@hoverflo/shared/api/services/login.service";
import { getCurrentUser } from "@hoverflo/shared/api/services/user.service";
import { LoginRequest, Session } from "@hoverflo/shared";
import { setCurrentSession } from "@hoverflo/shared/api/utils/login.utils";

import "./Login.scss";
import { useKeycloak } from "@react-keycloak/web";
import { config, keycloakConfig } from "@hoverflo/shared/api/utils/constants";
import { TextBox } from "../../../common/TextBox";

export const Login = inject("rootStore")(
    observer((props: Stores) => {
        const { sidebarStore, loadingStore, messagesStore, sessionStore, userStore, routingStore } = props.rootStore;
        const { keycloak, initialized } = useKeycloak();
        const socialLogin = async (provider: "google" | "facebook") => {
            if (!initialized) {
                messagesStore.error("Error while performing social login");
                return;
            }
            await keycloak.login({
                redirectUri: config.url + "/",
                idpHint: provider,
                scope: keycloakConfig.scopes[0]
            });
            const { token: accessToken, idToken, refreshToken } = keycloak;
            if (accessToken && idToken && refreshToken) {
                const user = await getCurrentUser({
                    accessToken
                } as Session);
                if (user) {
                    const session: Session = {
                        accessToken,
                        lastLogin: new Date(),
                        idToken,
                        refreshToken,
                        user
                    };
                    sessionStore.setCurrentSession(session);
                    userStore.setUser(user);
                    await setCurrentSession(session);
                    sidebarStore.changeRoute(Route.ACCOUNT_OVERVIEW);
                    loadingStore.stopLoading();
                }
            } else {
                loadingStore.stopLoading();
                messagesStore.error("Error while performing social login");
            }
        };
        const routeToRegisterWith = () => sidebarStore.changeRoute(Route.REGISTER_WITH);
        const routeToAccountOverview = () => sidebarStore.changeRoute(Route.ACCOUNT_OVERVIEW);
        const routeToForgotPassword = () => sidebarStore.changeRoute(Route.FORGOT_PASSWORD);
        const onSubmit = async (values: LoginRequest, { setErrors }: FormikHelpers<any>) => {
            loadingStore.triggerLoading();
            try {
                const response = await performLogin(values);
                if (response.access_token && response) {
                    const user = await getCurrentUser({ accessToken: response.access_token } as Session);
                    if (user) {
                        const dateTime = new Date();
                        const session: Session = {
                            accessToken: response.access_token,
                            refreshToken: response.refresh_token,
                            user: user,
                            lastLogin: dateTime,
                            expiresAt: response.expires_in ? addSeconds(dateTime, response.expires_in) : undefined,
                            refreshTokenExpiresAt: response.refresh_expires_in
                                ? addSeconds(dateTime, response.refresh_expires_in)
                                : undefined
                        };
                        sessionStore.setCurrentSession(session);
                        userStore.setUser(user);
                        await setCurrentSession(session);
                        sidebarStore.changeRoute(Route.ACCOUNT_OVERVIEW);
                        loadingStore.stopLoading();
                    }
                } else {
                    loadingStore.stopLoading();
                    messagesStore.error("An error occurred while performing login");
                    setErrors({
                        // email: t("login.errors.invalid-user")
                        email: "Invalid email"
                    });
                }
            } catch (e: any) {
                messagesStore.error(e.error_description ?? e.message);
                setErrors({
                    // email: t("login.errors.invalid-user")
                    email: "Invalid email"
                });
                loadingStore.stopLoading();
            }
        };

        // const { t } = useTranslation();

        return (
            <Formik
                initialValues={{
                    email: "",
                    password: ""
                }}
                validate={values => {
                    let errors: any = {};
                    if (!values.email) {
                        // errors.email = t("form.errors.required");
                        errors.email = "Required";
                    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
                        // errors.email = t("form.errors.invalid");
                        errors.email = "Invalid";
                    }
                    if (!values.password) {
                        // errors.password = t("form.errors.required");
                        errors.password = "Required";
                    }
                    return errors;
                }}
                onSubmit={onSubmit}
            >
                {({ values, errors, handleChange, handleBlur, handleSubmit, resetForm }) => (
                    <div className="login-container text-white flex-column">
                        <form onSubmit={handleSubmit}>
                            <div>
                                <h1>Sign in</h1>
                            </div>

                            <div>
                                <hr />
                            </div>

                            <div>
                                <TextBox
                                    invalid={errors && !!errors.email}
                                    type="email"
                                    name="email"
                                    placeholder="email"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values && values.email}
                                />
                            </div>

                            <div>
                                <TextBox
                                    invalid={errors && !!errors.password}
                                    type="password"
                                    name="password"
                                    placeholder="password"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values && values.password}
                                />
                            </div>

                            <div>
                                <a onClick={routeToForgotPassword} href="#">
                                    <p>forgot password?</p>
                                </a>
                            </div>

                            <div>
                                <button
                                    className={classNames("btn btn-rounded", {
                                        disabled: Object.keys(errors).length > 0
                                    })}
                                >
                                    sign in
                                </button>
                            </div>

                            <div>
                                <hr className="hr2" />
                            </div>

                            <div>
                                <a onClick={() => socialLogin("facebook")} className="flex-row social">
                                    <img src="assets/Icons/Icon_facebook.png" className="icon_style" />
                                    <h3>login with facebook</h3>
                                </a>
                            </div>

                            <div>
                                <a onClick={() => socialLogin("google")} className="flex-row social">
                                    <img src="assets/Icons/Icon_google.png" className="icon_style" />
                                    <h3>login with google</h3>
                                </a>
                            </div>

                            <div>
                                <hr />
                            </div>

                            <div>
                                <button onClick={routeToRegisterWith} className="btn btn-rounded">
                                    create account
                                </button>
                            </div>

                            <div>
                                <button
                                    type="button"
                                    className="btn btn-host"
                                    onClick={() => routingStore.pushRoute("/become-host")}
                                >
                                    become a host
                                </button>
                            </div>

                            <div>
                                <button onClick={routeToAccountOverview} className="btn btn-rounded">
                                    cancel
                                </button>
                            </div>

                            <div>
                                <p>
                                    * professional use / respond to bookers
                                    <br />
                                    offer your services
                                </p>
                            </div>
                        </form>
                    </div>
                )}
            </Formik>
        );
    })
);
