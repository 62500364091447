var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { action, makeAutoObservable, observable } from "mobx";
export class RequestStore {
    constructor() {
        this.requests = [];
        this.hosts = [];
        makeAutoObservable(this);
    }
    setRequests(requests) {
        this.requests = requests;
    }
    getRequestById(id) {
        return this.requests.find(c => c.id === id);
    }
    addRequest(request) {
        this.requests.push(request);
    }
    setHosts(hosts) {
        this.hosts = hosts;
    }
}
__decorate([
    observable.deep
], RequestStore.prototype, "requests", void 0);
__decorate([
    observable.deep
], RequestStore.prototype, "hosts", void 0);
__decorate([
    action
], RequestStore.prototype, "setRequests", null);
__decorate([
    action
], RequestStore.prototype, "getRequestById", null);
__decorate([
    action
], RequestStore.prototype, "addRequest", null);
__decorate([
    action
], RequestStore.prototype, "setHosts", null);
