var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { get, post } from "./base.service";
import { config } from "../utils/constants";
import { useEffect, useRef, useState } from "react";
export function getMessages(contactId, requestId, size, page) {
    return __awaiter(this, void 0, void 0, function* () {
        return get(`${config.chatUrl}/message?contact=${contactId}&requestId=${requestId}${page ? `&page=${page}` : ""}${size ? `&size=${size}` : ""}`);
    });
}
export function postMessage(message) {
    return __awaiter(this, void 0, void 0, function* () {
        return post(`${config.chatUrl}/message`, message);
    });
}
export function usePollingEffect(asyncCallback, dependencies = [], { interval = 10000, onCleanUp = () => { } } = {}) {
    const timeoutIdRef = useRef(null);
    useEffect(() => {
        let _stopped = false;
        // Side note: preceding semicolon needed for IIFEs.
        (function pollingCallback() {
            return __awaiter(this, void 0, void 0, function* () {
                try {
                    yield asyncCallback();
                }
                finally {
                    // Set timeout after it finished, unless stopped
                    timeoutIdRef.current = !_stopped && setTimeout(pollingCallback, interval);
                }
            });
        })();
        // Clean up if dependencies change
        return () => {
            _stopped = true; // prevent racing conditions
            clearTimeout(timeoutIdRef.current);
            onCleanUp();
        };
    }, [...dependencies, interval]);
}
export function useChat(requestId, isHost, onError, size, page, delay = 5000) {
    const [isLoading, setIsLoading] = useState(false);
    const [messages, setMessages] = useState([]);
    const [hostUserId, setHostUserId] = useState("");
    const [customerUserId, setCustomerUserId] = useState("");
    const initialCall = useRef(true);
    useEffect(() => {
        getChatInfoByRequest(requestId).then(({ hostUserId, customerUserId }) => {
            setHostUserId(hostUserId);
            setCustomerUserId(customerUserId);
        });
    }, []);
    usePollingEffect(() => __awaiter(this, void 0, void 0, function* () {
        if (!isLoading) {
            setIsLoading(true);
            if (hostUserId && customerUserId) {
                try {
                    const messages = yield getMessages(isHost ? customerUserId : hostUserId, requestId, size, page);
                    setMessages(messages);
                    setIsLoading(false);
                }
                catch (e) {
                    setIsLoading(false);
                    yield onError(e);
                }
            }
            else {
                setIsLoading(false);
            }
        }
        initialCall.current = false;
    }), 
    //@ts-ignore
    [customerUserId, hostUserId, isHost, requestId, size, page], {
        interval: delay,
        onCleanUp: () => {
            setIsLoading(false);
        }
    });
    return [isLoading, messages, hostUserId, customerUserId];
}
export function getChatInfoByRequest(requestId) {
    return __awaiter(this, void 0, void 0, function* () {
        return get(`${config.url}/api/request/${requestId}/chat-info`);
    });
}
export function getChatInfoByOffer(offerId) {
    return __awaiter(this, void 0, void 0, function* () {
        return get(`${config.url}/api/chat-info/offer/${offerId}`);
    });
}
