import {
    LoggedAdminComponent,
    LoggedComponent,
    LoggedHostComponent,
    LoggedUserComponent
} from "../../utils/LoggedComponent";
import { inject, observer } from "mobx-react";
import { Stores } from "../../../models";
import { Sidebar } from "../sidebar/Sidebar";
import Drawer from "@mui/material/Drawer";
import { useTranslation } from "react-i18next";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import "./Header.scss";
import { ThemeType } from "@hoverflo/shared";

export const Header = inject("rootStore")(
    observer((props: Stores) => {
        const { sidebarStore, sessionStore, searchStore, routingStore, routerStore } = props.rootStore;
        const logoColor =
            // (searchStore.activity?.type === ThemeType.AIR &&
            //     routingStore.route !== "/bookings" &&
            //     routingStore.route !== "/activities" &&
            //     routerStore.location.pathname !== "/bookings" &&
            //     routerStore.location.pathname !== "/activities") ||
            routingStore.route === "/explore" || routerStore.location.pathname === "/explore" ? "black" : "white";
        const becomeHost = routingStore.route === "/become-host" || routerStore.location.pathname === "/become-host";

        const { t } = useTranslation();

        return (
            <>
                <div className="web-view">
                    <nav className="navbar navbar-expand-lg">
                        <div className="container-fluid">
                            <div className="navbar-header">
                                <img
                                    onClick={() => {
                                        routingStore.pushRoute("/");
                                    }}
                                    alt="Hoverflo logo"
                                    title="Go to home page"
                                    width="80"
                                    style={{ cursor: "pointer" }}
                                    src={`assets/design_files/logo/Hoverflo_LOGO_final_28JAN21_${logoColor}.png`}
                                />
                                <a
                                    className="navbar-brand logo-font text-white"
                                    onClick={() => {
                                        routingStore.pushRoute("/");
                                    }}
                                    title="Go to home page"
                                    style={{ cursor: "pointer" }}
                                >
                                    HOVERFLO
                                </a>
                            </div>

                            {!becomeHost && (
                                <>
                                    <div className="collapse navbar-collapse">
                                        <ul className="navbar-nav">
                                            {!sessionStore?.isHost && (
                                                <>
                                                    <li className="nav-item">
                                                        <a
                                                            className="nav-link"
                                                            style={{ cursor: "pointer" }}
                                                            onClick={() => routingStore.pushRoute("/activities")}
                                                        >
                                                            {t("menu.activities")}
                                                        </a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a
                                                            className="nav-link"
                                                            style={{ cursor: "pointer" }}
                                                            onClick={() => routingStore.pushRoute("/locations")}
                                                        >
                                                            {t("menu.locations")}
                                                        </a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a
                                                            className="nav-link"
                                                            style={{ cursor: "pointer" }}
                                                            onClick={() => routingStore.pushRoute("/explore")}
                                                        >
                                                            {t("menu.explore")}
                                                        </a>
                                                    </li>
                                                </>
                                            )}
                                            <LoggedUserComponent>
                                                <li className="nav-item">
                                                    <a
                                                        className="nav-link"
                                                        style={{ cursor: "pointer" }}
                                                        onClick={() => routingStore.pushRoute("/bookings")}
                                                    >
                                                        {t("menu.bookings")}
                                                    </a>
                                                </li>
                                                {/*<li className="nav-item">*/}
                                                {/*    <a*/}
                                                {/*        className="nav-link"*/}
                                                {/*        style={{ cursor: "pointer" }}*/}
                                                {/*        onClick={() => routingStore.pushRoute("/wallet")}*/}
                                                {/*    >*/}
                                                {/*        {t("menu.wallet")}*/}
                                                {/*    </a>*/}
                                                {/*</li>*/}
                                            </LoggedUserComponent>
                                            <LoggedHostComponent>
                                                <li className="nav-item">
                                                    <a
                                                        className="nav-link"
                                                        style={{ cursor: "pointer" }}
                                                        onClick={() => routingStore.pushRoute("/host-profile")}
                                                    >
                                                        profile
                                                    </a>
                                                </li>
                                                <li className="nav-item">
                                                    <a
                                                        className="nav-link"
                                                        style={{ cursor: "pointer" }}
                                                        onClick={() => routingStore.pushRoute("/host-bookings")}
                                                    >
                                                        {t("menu.bookings")}
                                                    </a>
                                                </li>
                                                <li className="nav-item">
                                                    <a
                                                        className="nav-link"
                                                        style={{ cursor: "pointer" }}
                                                        onClick={() => routingStore.pushRoute("/host-offers")}
                                                    >
                                                        offers
                                                    </a>
                                                </li>
                                                {/*<li className="nav-item">*/}
                                                {/*    <a*/}
                                                {/*        className="nav-link"*/}
                                                {/*        style={{ cursor: "pointer" }}*/}
                                                {/*        onClick={() => routingStore.pushRoute("/host-schedule")}*/}
                                                {/*    >*/}
                                                {/*        schedule*/}
                                                {/*    </a>*/}
                                                {/*</li>*/}
                                                {/*<li className="nav-item">*/}
                                                {/*    <a*/}
                                                {/*        className="nav-link"*/}
                                                {/*        style={{ cursor: "pointer" }}*/}
                                                {/*        onClick={() => routingStore.pushRoute("/host-financial")}*/}
                                                {/*    >*/}
                                                {/*        financial*/}
                                                {/*    </a>*/}
                                                {/*</li>*/}
                                            </LoggedHostComponent>
                                            <LoggedAdminComponent>
                                                <li className="nav-item">
                                                    <a
                                                        className="nav-link"
                                                        style={{ cursor: "pointer" }}
                                                        onClick={() => routingStore.pushRoute("/host-management")}
                                                    >
                                                        Manage Hosts
                                                    </a>
                                                </li>
                                            </LoggedAdminComponent>
                                        </ul>
                                    </div>
                                    <LoggedComponent>
                                        <div className="user-name">{sessionStore?.session?.user?.displayName}</div>
                                    </LoggedComponent>
                                </>
                            )}
                            <div className="sidebar-button">
                                <a
                                    style={{ cursor: "pointer" }}
                                    className="hamburger"
                                    onClick={() => {
                                        if (becomeHost) {
                                            sidebarStore.toggleSidebar(false);
                                            routingStore.pushRoute("/");
                                        } else {
                                            sidebarStore.toggleSidebar(true);
                                        }
                                    }}
                                    title={becomeHost ? "Cancel registration" : "Open menu"}
                                >
                                    {!becomeHost && <MenuIcon fontSize="large" />}
                                    {becomeHost && <CloseIcon fontSize="large" />}
                                </a>
                            </div>
                        </div>
                    </nav>
                </div>
                <div className="mobile-view">
                    {!sessionStore?.isHost && (
                        <div>
                            <a style={{ cursor: "pointer" }} onClick={() => routingStore.pushRoute("/activities")}>
                                <img src="assets/Icons/Icon_Mobile_menu_1.svg" className="icon_style_white" />
                            </a>
                            <a style={{ cursor: "pointer" }} onClick={() => routingStore.pushRoute("/locations")}>
                                <img src="assets/Icons/Icon_Mobile_menu_2.svg" className="icon_style_white" />
                            </a>
                            <a style={{ cursor: "pointer" }} onClick={() => routingStore.pushRoute("/explore")}>
                                <img src="assets/Icons/Icon_Mobile_menu_3.svg" className="icon_style_white" />
                            </a>
                        </div>
                    )}
                    <LoggedUserComponent>
                        <div>
                            <a style={{ cursor: "pointer" }} onClick={() => routingStore.pushRoute("/bookings")}>
                                <img src="assets/Icons/Icon_calander.svg" className="icon_style_white" />
                            </a>
                            <a style={{ cursor: "pointer" }} onClick={() => routingStore.pushRoute("/wallet")}>
                                <img src="assets/Icons/Icon_wallet.svg" className="icon_style_white" />
                            </a>
                        </div>
                    </LoggedUserComponent>
                    <LoggedHostComponent>
                        <div>
                            <a style={{ cursor: "pointer" }} onClick={() => routingStore.pushRoute("/host-profile")}>
                                <img src="assets/Icons/Icon_account.svg" className="icon_style_white" />
                            </a>
                            <a style={{ cursor: "pointer" }} onClick={() => routingStore.pushRoute("/host-bookings")}>
                                <img src="assets/Icons/Icon_calander.svg" className="icon_style_white" />
                            </a>
                            <a style={{ cursor: "pointer" }} onClick={() => routingStore.pushRoute("/host-offers")}>
                                <img src="assets/Icons/Icon_Host_Offer.svg" className="icon_style_white" />
                            </a>
                            <a style={{ cursor: "pointer" }} onClick={() => routingStore.pushRoute("/host-schedule")}>
                                <img src="assets/Icons/Icon_calander.svg" className="icon_style_white" />
                            </a>
                            <a style={{ cursor: "pointer" }} onClick={() => routingStore.pushRoute("/host-financial")}>
                                <img src="assets/Icons/Icon_wallet.svg" className="icon_style_white" />
                            </a>
                        </div>
                    </LoggedHostComponent>
                    <LoggedAdminComponent>
                        <div>
                            <a style={{ cursor: "pointer" }} onClick={() => routingStore.pushRoute("/manage-hosts")}>
                                <img src="assets/Icons/Icon_account.svg" className="icon_style_white" />
                            </a>
                        </div>
                    </LoggedAdminComponent>
                </div>
                {!becomeHost && (
                    <Drawer
                        anchor="right"
                        open={sidebarStore.open}
                        onClose={() => sidebarStore.toggleSidebar(false)}
                        classes={{ paper: "sidebar" }}
                    >
                        <Sidebar closeDrawerAction={() => sidebarStore.toggleSidebar(false)} />
                    </Drawer>
                )}
            </>
        );
    })
);
