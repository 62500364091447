import { inject, observer } from "mobx-react";
import { Stores } from "../../../models";
import { Checkbox, IconButton } from "@mui/material";
import { RemoveCircle } from "@mui/icons-material";
import { themeColors } from "@hoverflo/shared/api/utils/constants";
import React, { useEffect, useState } from "react";
import { getActivities } from "@hoverflo/shared/api/services/activity.service";
import { errorHandler } from "../../../utils/errorHandler";
import { deleteActivity, updateActivities } from "@hoverflo/shared/api/services/host.service";
import { HostActivity } from "@hoverflo/shared/api/models/host";

interface HostActivitiesProps extends Stores {
    disabled: boolean;
    onSuccess: () => void;
}

export const HostActivities = inject("rootStore")(
    observer((props: HostActivitiesProps) => {
        const { hostStore, loadingStore, messagesStore, activityStore } = props.rootStore;
        const addNewActivity = () => hostStore.addActivity({ locations: [], lesson: false, material: false });
        const [activitiesToDelete, setActivitiesToDelete] = useState<string[]>([]);

        const saveActivities = async () => {
            loadingStore.triggerLoading();
            try {
                const activities = [
                    ...hostStore.host.activities.map((ac: HostActivity & { isTemporary?: boolean }) => ({
                        ...ac,
                        ...(ac.isTemporary ? { id: undefined, isTemporary: undefined } : {})
                    }))
                ];

                // First delete the necessary ones
                await Promise.all(activitiesToDelete.map(id => deleteActivity({ id } as HostActivity)));
                // Then update the activities
                const updateHost = await updateActivities(hostStore.host.generalInfo, activities);
                hostStore.setHost(updateHost);
                loadingStore.stopLoading();
                messagesStore.success("Activities successfully saved");
                setActivitiesToDelete([]);
                props.onSuccess();
            } catch (e) {
                await errorHandler(e, props.rootStore);
                loadingStore.stopLoading();
                messagesStore.error(e.message);
            }
        };

        const readyToSave = hostStore.host?.activities?.every(ac => !!ac.activity?.id);

        useEffect(() => {
            if (activityStore.activities.length === 0) {
                loadingStore.triggerLoading();
                getActivities()
                    .then(activities => {
                        activities.sort((a, b) => a.name.localeCompare(b.name));
                        activityStore.setActivities(activities);
                        loadingStore.stopLoading();
                    })
                    .catch(async e => {
                        await errorHandler(e, props.rootStore);
                        loadingStore.stopLoading();
                        messagesStore.error(e.message);
                    });
            }
        }, [activityStore, loadingStore, messagesStore]);

        return (
            <div className="activities-list">
                <h1>Edit or Add extra activities and services below</h1>
                <hr />
                <div className="list">
                    {hostStore.host?.activities?.map((activity, index) => (
                        <div key={activity.id} className="flex-column">
                            <div className="flex-row align-items-center">
                                <select
                                    disabled={props.disabled}
                                    className="form-control"
                                    value={activity.activity?.id}
                                    onChange={event => {
                                        hostStore.updateActivity({
                                            ...activity,
                                            activity: {
                                                ...activityStore.activities.find(a => a.id === event.target.value)
                                            }
                                        });
                                    }}
                                    placeholder={`Please select activity ${index + 1}`}
                                >
                                    <option>Activity {index + 1}</option>
                                    {activityStore.activities
                                        .filter(
                                            ac =>
                                                !hostStore.host.activities
                                                    .filter(act => act.id !== activity.id)
                                                    .map(act => act.activity?.id)
                                                    .filter(Boolean)
                                                    .includes(ac.id)
                                        )
                                        .map(activity => (
                                            <option key={activity.id} value={activity.id}>
                                                {activity.name.toUpperCase()}
                                            </option>
                                        ))}
                                </select>
                                {index !== 0 && (
                                    <div>
                                        <IconButton
                                            disabled={props.disabled}
                                            title="Remove activity"
                                            onClick={() => {
                                                if (window.confirm("Are sure you want to delete this activity?")) {
                                                    if ((activity as any).isTemporary === undefined) {
                                                        setActivitiesToDelete(prev => {
                                                            return [...prev, activity.id];
                                                        });
                                                    }
                                                    hostStore.removeActivity(activity);
                                                }
                                            }}
                                        >
                                            <RemoveCircle
                                                sx={{
                                                    color: !props.disabled ? themeColors.EARTH.background : undefined
                                                }}
                                            />
                                        </IconButton>
                                    </div>
                                )}
                            </div>
                            <div className="flex-row">
                                <div className="flex-row flex-grow-1 align-items-center">
                                    <Checkbox
                                        disabled={props.disabled}
                                        checked={activity.lesson}
                                        name={`lesson_${activity.id}`}
                                        onChange={event => {
                                            hostStore.updateActivity({
                                                ...activity,
                                                lesson: event.target.checked
                                            });
                                        }}
                                        sx={{
                                            color: !props.disabled ? "#FFF" : undefined,
                                            "&.Mui-checked": {
                                                color: !props.disabled ? themeColors.EARTH.background : undefined
                                            }
                                        }}
                                    />
                                    <label htmlFor={`lesson_${activity.id}`}>Lessons/Guides</label>
                                </div>
                                <div className="flex-row flex-grow-1 align-items-center">
                                    <Checkbox
                                        disabled={props.disabled}
                                        checked={activity.material}
                                        name={`material_${activity.id}`}
                                        onChange={event => {
                                            hostStore.updateActivity({
                                                ...activity,
                                                material: event.target.checked
                                            });
                                        }}
                                        sx={{
                                            color: !props.disabled ? "#FFF" : undefined,
                                            "&.Mui-checked": {
                                                color: !props.disabled ? themeColors.EARTH.background : undefined
                                            }
                                        }}
                                    />
                                    <label htmlFor={`material_${activity.id}`}>Material</label>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <hr />
                <button
                    disabled={props.disabled}
                    className="btn btn-dark-host-rounded btn-add-activity"
                    onClick={() => addNewActivity()}
                >
                    Add Extra activity
                </button>
                <div>
                    <button
                        disabled={props.disabled || !readyToSave}
                        type="submit"
                        className="btn btn-orange-rounded"
                        onClick={() => saveActivities()}
                    >
                        Save
                    </button>
                </div>
            </div>
        );
    })
);
