import classNames from "classnames";
import React, { createContext } from "react";

import "./Stepper.scss";

interface StepperProps {
    steps: number;
    activeSteps: number;
    currentStep: number;
    onClick: (step: number) => void;
}

export const StepperContext = createContext({ onChange: (step: number) => {} });

export function Stepper(props: StepperProps) {
    const stepsArray = Array.from({ length: props.steps }, (_, index) => index + 1);

    return (
        <div className="stepper">
            {stepsArray.map(step => (
                <div
                    key={`step_${step}`}
                    className={classNames("step--circle", {
                        active: step <= props.currentStep,
                        disabled: step - 1 > props.activeSteps
                    })}
                    title={`Step ${step}`}
                    onClick={() => {
                        step - 1 <= props.activeSteps && props.onClick(step);
                    }}
                />
            ))}
        </div>
    );
}
