import { inject, observer } from "mobx-react";
import { Stores } from "../../../models";

import "./HostProfile.scss";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { HostProfileInfo } from "./HostProfileInfo";
import { getHost } from "@hoverflo/shared/api/services/host.service";
import { errorHandler } from "../../../utils/errorHandler";
import { HostReviews } from "./Reviews";
import { HostPayment } from "./HostPayment";
import { HostActivities } from "./HostActivities";
import { HostLocations } from "./HostLocations";
import { HostGeneralInfo } from "./HostGeneralInfo";

export const HostProfile = inject("rootStore")(
    observer((props: Stores) => {
        const { hostStore, loadingStore, messagesStore, sessionStore, routingStore } = props.rootStore;
        const [tab, setTab] = useState("general");
        const [disabled, setDisabled] = useState(true);
        const changeTab = (nextTab: string) => {
            if (nextTab !== tab && !disabled) {
                messagesStore.warning("You have unsaved changes, please save or cancel before change tab");
                return;
            }
            setTab(nextTab);
        };

        const toggleEditMode = async () => {
            if (!disabled) {
                loadingStore.triggerLoading();
                try {
                    const host = await getHost(sessionStore.session.user.hostDto.id);
                    hostStore.setHost(host);
                    loadingStore.stopLoading();
                    setDisabled(true);
                } catch (e) {
                    await errorHandler(e, props.rootStore);
                    loadingStore.stopLoading();
                    messagesStore.error("Error while retrieving host details.");
                }
            } else {
                setDisabled(false);
            }
        };

        useEffect(() => {
            if (!!sessionStore.session?.user?.hostDto?.id) {
                if (!hostStore.host?.generalInfo?.id) {
                    getHost(sessionStore.session.user.hostDto.id)
                        .then(host => {
                            hostStore.setHost(host);
                            loadingStore.stopLoading();
                        })
                        .catch(async e => {
                            await errorHandler(e, props.rootStore);
                            loadingStore.stopLoading();
                            messagesStore.error("Error while retrieving host details.");
                        });
                }
            } else {
                loadingStore.stopLoading();
                messagesStore.error("User is not a host. Signing off");
                routingStore.pushRoute("/");
            }
        }, []);

        const onSuccess = () => setDisabled(true);

        return (
            <div className="host-profile-container">
                <div className="host-profile-content">
                    <div className="left-panel">
                        <div className="container host-overview">
                            <HostProfileInfo host={hostStore.host} />
                            <div>
                                <button className="btn btn-host-rounded" onClick={toggleEditMode}>
                                    {disabled ? "EDIT PROFILE" : "CANCEL"}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="middle-panel">
                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                            <li className="nav-item">
                                <a
                                    className={classNames("nav-link", { active: tab === "general" })}
                                    id="general-tab"
                                    data-toggle="tab"
                                    style={{ cursor: "pointer" }}
                                    aria-selected={tab === "general"}
                                    onClick={() => changeTab("general")}
                                >
                                    GENERAL
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    className={classNames("nav-link", { active: tab === "activities" })}
                                    id="activities-tab"
                                    data-toggle="tab"
                                    style={{ cursor: "pointer" }}
                                    aria-selected={tab === "activities"}
                                    onClick={() => changeTab("activities")}
                                >
                                    ACTIVITIES
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    className={classNames("nav-link", { active: tab === "locations" })}
                                    id="locations-tab"
                                    data-toggle="tab"
                                    style={{ cursor: "pointer" }}
                                    aria-selected={tab === "locations"}
                                    onClick={() => changeTab("locations")}
                                >
                                    LOCATIONS
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    className={classNames("nav-link", { active: tab === "reviews" })}
                                    id="reviews-tab"
                                    data-toggle="tab"
                                    style={{ cursor: "pointer" }}
                                    aria-selected={tab === "reviews"}
                                    onClick={() => changeTab("reviews")}
                                >
                                    REVIEWS
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    className={classNames("nav-link", { active: tab === "payment-info" })}
                                    id="payment-info-tab"
                                    data-toggle="tab"
                                    style={{ cursor: "pointer" }}
                                    aria-selected={tab === "payment-info"}
                                    onClick={() => changeTab("payment-info")}
                                >
                                    payment info
                                </a>
                            </li>
                        </ul>
                        <div className="tab-content">
                            <div
                                className={classNames("tab-pane fade", {
                                    show: tab === "general",
                                    active: tab === "general"
                                })}
                                id="general"
                                role="tabpanel"
                                aria-labelledby="general-tab"
                            >
                                <div className="general">
                                    <HostGeneralInfo disabled={disabled} onSuccess={onSuccess} />
                                </div>
                            </div>
                            <div
                                className={classNames("tab-pane fade", {
                                    show: tab === "activities",
                                    active: tab === "activities"
                                })}
                                id="activities"
                                role="tabpanel"
                                aria-labelledby="activities-tab"
                            >
                                <div className="activities">
                                    <HostActivities disabled={disabled} onSuccess={onSuccess} />
                                </div>
                            </div>
                            <div
                                className={classNames("tab-pane fade", {
                                    show: tab === "locations",
                                    active: tab === "locations"
                                })}
                                id="locations"
                                role="tabpanel"
                                aria-labelledby="locations-tab"
                            >
                                <div className="locations">
                                    <HostLocations disabled={disabled} onSuccess={onSuccess} />
                                </div>
                            </div>
                            <div
                                className={classNames("tab-pane fade", {
                                    show: tab === "reviews",
                                    active: tab === "reviews"
                                })}
                                id="reviews"
                                role="tabpanel"
                                aria-labelledby="reviews-tab"
                            >
                                <div className="reviews">
                                    <HostReviews />
                                </div>
                            </div>
                            <div
                                className={classNames("tab-pane fade", {
                                    show: tab === "payment-info",
                                    active: tab === "payment-info"
                                })}
                                id="payment-info"
                                role="tabpanel"
                                aria-labelledby="payment-info-tab"
                            >
                                <div className="payment-info">
                                    <HostPayment disabled={disabled} onSuccess={onSuccess} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    })
);
