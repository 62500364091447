import { inject, observer } from "mobx-react";
import { Stores } from "../../../models";
import { SearchBar } from "../../layout/search-bar/SearchBar";
import React, { useEffect, useState } from "react";
import { Activity, ThemeType } from "@hoverflo/shared/api/models";

import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import "swiper/css/effect-coverflow";
import "swiper/css/mousewheel";
import "swiper/css/keyboard";

import { A11y, Navigation, Pagination, Mousewheel, Keyboard, Autoplay, EffectCoverflow } from "swiper";

import "./Activities.scss";
import { getActivities } from "@hoverflo/shared/api/services/activity.service";
import { errorHandler } from "../../../utils/errorHandler";

export const Activities = inject("rootStore")(
    observer((props: Stores) => {
        const { activityStore, loadingStore, messagesStore, searchStore } = props.rootStore;
        const [showDetails, setShowDetails] = useState(false);
        const [selectedActivity, setSelectedActivity] = useState<Activity>();
        const [filter, setFilter] = useState<ThemeType>(undefined);

        useEffect(() => {
            if (activityStore.activities.length === 0) {
                loadingStore.triggerLoading();
                getActivities()
                    .then(activities => {
                        activityStore.setActivities(activities);
                        loadingStore.stopLoading();
                    })
                    .catch(async e => {
                        await errorHandler(e, props.rootStore);
                        loadingStore.stopLoading();
                        messagesStore.error(e.message);
                    });
            }
        }, [activityStore, loadingStore, messagesStore]);

        return (
            <div className="activities-container col">
                <div>
                    <SearchBar hideTitle />
                </div>
                {showDetails && (
                    <div className="flex-row activity-info">
                        <div className="col-6">
                            <Swiper
                                modules={[
                                    Navigation,
                                    Pagination,
                                    A11y,
                                    Autoplay,
                                    Mousewheel,
                                    Keyboard,
                                    EffectCoverflow
                                ]}
                                className="swiper-container"
                                direction="horizontal"
                                effect="coverflow"
                                loop
                                speed={1000}
                                autoplay={{ delay: 2000, disableOnInteraction: false }}
                                slidesPerView={2}
                                keyboard
                                mousewheel
                                navigation
                                pagination={false}
                                centeredSlides
                            >
                                {/* TODO: Change this to support multiple images coming from the APIs */}
                                <SwiperSlide className="swiper-slide">
                                    <img src={selectedActivity.image} />
                                </SwiperSlide>
                                <SwiperSlide className="swiper-slide">
                                    <img src="https://i.ibb.co/J5yxYSt/Mountain-Biking.jpg" />
                                </SwiperSlide>
                                <SwiperSlide className="swiper-slide">
                                    <img src="https://i.ibb.co/Z6rxSQM/Beach-cleanup.png" />
                                </SwiperSlide>
                            </Swiper>
                        </div>
                        <div className="col-4">
                            <div className="icons-bar col-11">
                                <div className="desc-icons flex-row justify-content-end">
                                    {/*TODO: Add action to buttons */}
                                    {/*<img src="assets/Icons/Icon_social_share.svg" />*/}
                                    {/*<img src="assets/Icons/Icon_social_save.svg" />*/}
                                    {/*<img src="assets/Icons/Icon_social_like.svg" />*/}
                                    <img
                                        src="assets/Icons/Icon_close.svg"
                                        style={{ cursor: "pointer" }}
                                        title="Close details"
                                        onClick={() => {
                                            setShowDetails(false);
                                        }}
                                    />
                                </div>
                                <div>
                                    <h1>{selectedActivity.name}</h1>
                                </div>
                                <hr />
                                <div className="desc">{selectedActivity.description}</div>
                                <hr />
                                <div className="flex-row justify-content-sm-around button-container">
                                    <button
                                        className="btn btn-rounded"
                                        onClick={() => {
                                            setShowDetails(false);
                                        }}
                                    >
                                        BACK TO OVERVIEW
                                    </button>
                                    <button
                                        className="btn btn-orange-rounded"
                                        onClick={() => {
                                            searchStore.setActivity(selectedActivity);
                                        }}
                                    >
                                        SEARCH HOSTS
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {!showDetails && (
                    <>
                        <div className="image-list row">
                            {activityStore.activities
                                .filter(ac => (filter ? ac.type === filter : true))
                                .map(activity => (
                                    <div
                                        key={activity.id}
                                        className="act-info"
                                        onClick={() => {
                                            setShowDetails(true);
                                            setSelectedActivity(activity);
                                        }}
                                    >
                                        <div style={{ backgroundImage: `url(${activity.image})` }} className="image" />
                                        <span>
                                            <h2>{activity.name}</h2>
                                            <br />
                                            <h3>{`${activity.noOfAvailableHosts} ${
                                                activity.noOfAvailableHosts === 1 ? "HOST" : "HOSTS"
                                            }`}</h3>
                                        </span>
                                    </div>
                                ))}
                        </div>
                        <div className="buttons flex-row row-cols-5">
                            <div className="black flex-row" onClick={() => setFilter(undefined)}>
                                <p>Sort By:</p>
                                <h1>All</h1>
                            </div>
                            <div className="water" onClick={() => setFilter(ThemeType.WATER)}>
                                <h1>Water</h1>
                            </div>
                            <div className="earth" onClick={() => setFilter(ThemeType.EARTH)}>
                                <h1>Earth</h1>
                            </div>
                            <div className="alpine" onClick={() => setFilter(ThemeType.ALPINE)}>
                                <h1>Alpine</h1>
                            </div>
                            <div className="air" onClick={() => setFilter(ThemeType.AIR)}>
                                <h1>Air</h1>
                            </div>
                        </div>
                    </>
                )}
            </div>
        );
    })
);
