import { action, makeObservable, observable } from "mobx";
import { User } from "@hoverflo/shared/api/models";

export class UserStore {
    @observable user: User = {} as User;

    constructor() {
        makeObservable(this);
    }

    @action setUser(user: User) {
        this.user = user;
    }

    @action clearCurrentSession() {
        this.user = {} as User;
    }
}
