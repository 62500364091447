import { inject, observer } from "mobx-react";
import { Stores } from "../../../models";

import "./Bookings.scss";
import { SearchBar } from "../../layout/search-bar/SearchBar";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Activity, HostActivity, Request, RequestResponse, RequestStatus } from "@hoverflo/shared/api/models";
import { getRequests } from "@hoverflo/shared/api/services/request.service";
import { getHost } from "@hoverflo/shared/api/services/host.service";
import { Inbox } from "./Inbox";
import { HostInfo } from "./HostInfo";
import { RequestDetails } from "./RequestDetails";
import { errorHandler } from "../../../utils/errorHandler";

export const Bookings = inject("rootStore")(
    observer((props: Stores) => {
        const { requestStore, loadingStore, messagesStore, searchStore } = props.rootStore;
        const [filterActivity, setFilterActivity] = useState<Activity>();
        const [focused, setFocused] = useState<Request>();

        useEffect(() => {
            if (requestStore.requests.length === 0) {
                loadingStore.triggerLoading();
                getRequests()
                    .then(requests => {
                        requestStore.setRequests(requests.content);
                        loadingStore.stopLoading();
                    })
                    .catch(async e => {
                        await errorHandler(e, props.rootStore);
                        loadingStore.stopLoading();
                        messagesStore.error(e.message ?? "Error while retrieving booking requests");
                    });
            }
        }, []);

        const requests = useMemo(
            () =>
                [
                    ...(searchStore.selectedResults.map(result => ({
                        tempId: Math.random().toString().slice(2, 12), //temporary id for search history to find and delete
                        requestDate: new Date(),
                        hostGeneralInfoDto: result.host,
                        selectedActivityId: searchStore.activity.id,
                        selectedLocationId: result.idHostActivityLocation,
                        status: RequestStatus.UNSENT
                    })) as Array<RequestResponse & { tempId: string }>),
                    ...requestStore.requests
                ].filter(request =>
                    filterActivity ? request.selectedActivityId === filterActivity.id : true
                ) as Array<RequestResponse & { tempId: string }>,
            [requestStore.requests, filterActivity, searchStore.selectedResults]
        );

        useEffect(() => {
            if (
                requests
                    .map(r => r.hostGeneralInfoDto.id)
                    .some(id => requestStore.hosts.every(host => host.generalInfo?.id !== id))
            ) {
                Promise.all(requests.map(d => getHost(d.hostGeneralInfoDto.id)))
                    .then(hosts => requestStore.setHosts(hosts))
                    .catch(async e => {
                        await errorHandler(e, props.rootStore);
                    });
            }
        }, [requests, requestStore.hosts]);

        const findHostActivity = useCallback(
            (hostId: string, activityId: string, locationId: string): HostActivity | undefined =>
                requestStore?.hosts
                    ?.find(host => host.generalInfo?.id === hostId)
                    ?.activities?.filter(ac => ac.activity?.id === activityId)
                    .map(
                        ac => ({ ...ac, locations: ac.locations.filter(loc => loc.id === locationId) } as HostActivity)
                    )?.[0],
            [requestStore.hosts]
        );

        useEffect(() => {
            if (!focused && requests.length > 0 && requestStore.hosts.length > 0) {
                const request = requests[0];
                setFocused({
                    ...request,
                    user: request.userDto,
                    hostActivity: {
                        ...findHostActivity(
                            request.hostGeneralInfoDto.id,
                            request.selectedActivityId,
                            request.selectedLocationId
                        ),
                        host: requestStore.hosts.find(host => host.generalInfo?.id === request.hostGeneralInfoDto.id)
                            ?.generalInfo
                    } as HostActivity
                } as Request);
            }
        }, [requests, focused, requestStore.hosts]);

        return (
            <div className="bookings-container">
                <div>
                    <SearchBar hideTitle />
                </div>
                <div className="bookings-content">
                    <div className="left-panel">
                        <Inbox
                            requests={requests}
                            filterActivity={filterActivity}
                            setFilterActivity={setFilterActivity}
                            setFocused={setFocused}
                            focused={focused}
                        />
                    </div>
                    <div className="middle-panel">
                        {focused && (
                            <RequestDetails request={focused} onSubmitRequest={request => setFocused(request)} />
                        )}
                    </div>
                    <div className="right-panel flex-column">
                        {focused && <HostInfo host={focused.hostActivity.host} />}
                    </div>
                </div>
            </div>
        );
    })
);
