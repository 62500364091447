import classNames from "classnames";
import React, { DetailedHTMLProps, forwardRef, InputHTMLAttributes, MutableRefObject } from "react";

export interface TextBoxProps extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
    invalid?: boolean;
    errorMessage?: string;
    rounded?: boolean;
}

export const TextBox = forwardRef(
    (
        { className, invalid, errorMessage, rounded = true, ...rest }: TextBoxProps,
        ref: MutableRefObject<HTMLInputElement> | null
    ) => {
        return (
            <>
                <input
                    ref={ref}
                    className={classNames("form-control", className, {
                        "input-rounded": rounded,
                        "is-invalid": invalid
                    })}
                    type="text"
                    {...rest}
                />
                {invalid && Boolean(errorMessage) && <div className="field-message">{errorMessage}</div>}
            </>
        );
    }
);
