import { inject, observer } from "mobx-react";
import { Host } from "@hoverflo/shared";
import { Stores } from "../../../models";
import React, { useMemo } from "react";
import { checkEmpty, formatLocalizedDate } from "../../../utils";

interface HostInfoProps extends Stores {
    host: Host;
}

export const HostInfo = inject("rootStore")(
    observer((props: HostInfoProps) => {
        const { requestStore } = props.rootStore;
        const host = useMemo(
            () => requestStore.hosts.find(h => h.generalInfo.id === props.host?.id),
            [requestStore.hosts, props.host]
        );
        const activityLocations = useMemo(
            () => host?.activities?.flatMap(ac => ac.locations).sort((a, b) => (a === b ? 0 : b ? -1 : 1)),
            [host]
        );
        const profileImage = host?.images?.find(i => !!i.profileImage);

        if (!host) {
            return <></>;
        }
        return (
            <>
                <div>
                    <h2>INFO</h2>
                </div>
                <div className="info-container">
                    <div>
                        <hr />
                    </div>
                    <div className="flex-row">
                        <div
                            className="host-logo"
                            style={{
                                backgroundImage: `url("${
                                    profileImage?.imageUri ??
                                    "/assets/design_files/logo/Hoverflo_LOGO_final_FEB21-black.jpg"
                                }")`
                            }}
                        />
                        <div className="flex-col host-name">
                            <h3>{host?.generalInfo?.name}</h3>
                            <h4>{checkEmpty(host?.generalInfo?.subtitle, "(No  subtitle)")}</h4>
                        </div>
                    </div>
                    <div>
                        <hr />
                    </div>
                    <div className="flex-row align-items-center">
                        <h5 className="text">Home:</h5>
                        <div className="content">
                            {checkEmpty(
                                host?.locations?.find(l => l.headquarter)?.location.address.city,
                                "(No headquarter added)"
                            )}
                        </div>
                    </div>
                    <div className="flex-row">
                        <h5 className="text">Activities:</h5>
                        <div className="content">
                            {host?.activities?.map(hostActivity => (
                                <div key={hostActivity?.activity?.id}>{hostActivity?.activity?.name}</div>
                            ))}
                        </div>
                    </div>
                    <div className="flex-row">
                        <h5 className="text">Spots:</h5>
                        <div className="content">
                            {activityLocations
                                ?.map(loc => `${loc.location?.address?.city} ${loc.mainSpot ? " (HQ)" : ""}`)
                                .map((location, index) => (
                                    <div key={`location_${index}`}>{location}</div>
                                ))}
                        </div>
                    </div>
                    <div className="flex-row">
                        <h5 className="text">Seasons:</h5>
                        <div className="content">
                            {Array.from(
                                new Set(
                                    activityLocations
                                        ?.filter(loc => loc.location?.fromDate && loc.location.tillDate)
                                        .map(
                                            loc =>
                                                `${formatLocalizedDate(
                                                    new Date(loc.location?.fromDate!),
                                                    "MMM"
                                                )}  - ${formatLocalizedDate(new Date(loc.location?.tillDate!), "MMM")}`
                                        )
                                )
                            ).map((season, index) => (
                                <div key={`season_${index}`}>{season}</div>
                            ))}
                        </div>
                    </div>
                    <div className="flex-row">
                        <h5 className="text">Bookings:</h5>
                        <div className="content">coming soon!</div>
                    </div>
                    <div className="flex-row">
                        <h5 className="text">Reviews:</h5>
                        <div className="content reviews-container">
                            <div>
                                {Array.from({ length: host?.generalInfo?.googleRating ?? 0 }).map((_, index) => (
                                    <span key={`checked_${index}`} className="fa fa-star checked" />
                                ))}
                                {Array.from({ length: 5 - (host?.generalInfo?.googleRating ?? 0) }).map((_, index) => (
                                    <span key={`unchecked_${index}`} className="fa fa-star unchecked" />
                                ))}
                            </div>
                            <span>
                                ({checkEmpty(host?.generalInfo?.googleUserRatingsTotal?.toString(), "0")} Reviews)
                            </span>
                        </div>
                    </div>
                    {host.images?.length > 0 && (
                        <div>
                            <hr />
                        </div>
                    )}
                    <div className="row host-image-list">
                        {host?.images
                            ?.filter(i => !i.profileImage)
                            .map(image => (
                                <div
                                    key={image.id}
                                    className="host-image"
                                    style={{ backgroundImage: `url("${image.imageUri}")` }}
                                />
                            ))}
                    </div>
                    <div>
                        <hr />
                    </div>
                    <div>
                        <button
                            className="btn btn-rounded"
                            disabled={!host?.generalInfo?.contact?.website}
                            onClick={() => {
                                window.open(host?.generalInfo?.contact?.website, "_blank");
                            }}
                        >
                            VIEW WEBSITE
                        </button>
                    </div>
                </div>
            </>
        );
    })
);
