import { useCallback, useEffect, useRef, useState } from "react";
import { ChatMessage } from "@hoverflo/shared";
import { getChatInfoByRequest, getMessages } from "@hoverflo/shared/api/services/chat.service";
import { RootStore } from "../../../stores/root.store";
import { errorHandler } from "../../../utils/errorHandler";

export function useInterval(callback: () => void, delay: number) {
    const savedCallback = useRef<() => void | undefined>();

    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
        function tick() {
            savedCallback.current?.();
        }
        const id = setInterval(tick, delay);
        return () => clearInterval(id);
    }, [callback, delay]);
}

export function usePollingEffect(asyncCallback, dependencies = [], { interval = 10000, onCleanUp = () => {} } = {}) {
    const timeoutIdRef = useRef(null);
    useEffect(() => {
        let _stopped = false;
        // Side note: preceding semicolon needed for IIFEs.
        (async function pollingCallback() {
            try {
                await asyncCallback();
            } finally {
                // Set timeout after it finished, unless stopped
                timeoutIdRef.current = !_stopped && setTimeout(pollingCallback, interval);
            }
        })();
        // Clean up if dependencies change
        return () => {
            _stopped = true; // prevent racing conditions
            clearTimeout(timeoutIdRef.current);
            onCleanUp();
        };
    }, [...dependencies, interval]);
}

export function useChat(
    requestId: string,
    rootStore: RootStore,
    isHost: boolean,
    size?: number,
    page?: number,
    delay = 5000
): [boolean, ChatMessage[], string, string] {
    const [isLoading, setIsLoading] = useState(false);
    const [messages, setMessages] = useState<ChatMessage[]>([]);
    const [hostUserId, setHostUserId] = useState("");
    const [customerUserId, setCustomerUserId] = useState("");
    const initialCall = useRef(true);

    useEffect(() => {
        getChatInfoByRequest(requestId).then(({ hostUserId, customerUserId }) => {
            setHostUserId(hostUserId);
            setCustomerUserId(customerUserId);
        });
    }, []);

    usePollingEffect(
        async () => {
            if (!isLoading) {
                setIsLoading(true);
                if (hostUserId && customerUserId) {
                    try {
                        const messages = await getMessages(isHost ? customerUserId : hostUserId, requestId, size, page);
                        setMessages(messages);
                        setIsLoading(false);
                    } catch (e) {
                        setIsLoading(false);
                        await errorHandler(e, rootStore);
                    }
                } else {
                    setIsLoading(false);
                }
            }
            initialCall.current = false;
        },
        [customerUserId, hostUserId, isHost, requestId, size, page],
        {
            interval: delay,
            onCleanUp: () => {
                setIsLoading(false);
            }
        }
    );

    // useInterval(
    //     useCallback(async () => {
    //         if (!isLoading) {
    //             setIsLoading(true);
    //             if (contactId) {
    //                 try {
    //                     const messages = await getMessages(contactId, requestId, size, page);
    //                     setMessages(messages);
    //                     setIsLoading(false);
    //                 } catch (e) {
    //                     setIsLoading(false);
    //                     await errorHandler(e, rootStore);
    //                 }
    //             } else {
    //                 setIsLoading(false);
    //             }
    //         }
    //         initialCall.current = false;
    //     }, [isLoading, contactId, requestId, size, page, rootStore]),
    //     initialCall.current ? 0 : delay
    // );

    return [isLoading, messages, hostUserId, customerUserId];
}
