var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { action, makeAutoObservable, observable } from "mobx";
export class ActivityStore {
    constructor() {
        this.activities = [];
        this.popularActivities = [];
        this.listOfPopularActivities = [
            "5e10190a-63a4-49cb-b7ac-92c108a51333",
            "1a175497-7a59-4079-a9b7-1a69aa36dec8",
            "0ed2eac0-0c00-4fa5-adfc-77494eae477c",
            "26891218-8b28-47ea-86b9-1332dd0dee26" // Hiking
        ];
        makeAutoObservable(this);
    }
    setActivities(activities) {
        this.activities = activities;
        this.popularActivities = activities.filter(ac => this.listOfPopularActivities.includes(ac.id));
    }
    getActivityById(id) {
        return this.activities.find(c => c.id === id);
    }
    addActivity(activity) {
        this.activities.push(activity);
    }
}
__decorate([
    observable.deep
], ActivityStore.prototype, "activities", void 0);
__decorate([
    observable.deep
], ActivityStore.prototype, "popularActivities", void 0);
__decorate([
    action
], ActivityStore.prototype, "setActivities", null);
__decorate([
    action
], ActivityStore.prototype, "getActivityById", null);
__decorate([
    action
], ActivityStore.prototype, "addActivity", null);
