import { inject, observer } from "mobx-react";
import { Activity, HostActivity, Request, RequestResponse, RequestStatus, ThemeType } from "@hoverflo/shared";
import classNames from "classnames";
import { Stores } from "../../../models";
import React, { useCallback, useMemo } from "react";
import { nanoid } from "nanoid/non-secure";
import { CancelRounded } from "@mui/icons-material";
import { findLocations } from "@hoverflo/shared/api/services/search.service";
import { errorHandler } from "../../../utils/errorHandler";

interface InboxProps extends Stores {
    requests: Array<RequestResponse & { tempId: string }>;
    filterActivity?: Activity;
    setFilterActivity: (activity: Activity) => void;
    setFocused: (request: Request) => void;
    focused?: Request;
}

export const Inbox = inject("rootStore")(
    observer((props: InboxProps) => {
        const { activityStore, requestStore, searchStore, loadingStore, messagesStore, routingStore } = props.rootStore;

        const uniqueActivities = useMemo(() => {
            const ids = Array.from(new Set(props.requests.map(r => r.selectedActivityId)));
            return activityStore.activities.filter(ac => ids.includes(ac.id));
        }, [props.requests, activityStore.activities]);

        const findHostActivity = useCallback(
            (hostId: string, activityId: string, locationId: string): HostActivity | undefined =>
                requestStore?.hosts
                    ?.find(host => host.generalInfo?.id === hostId)
                    ?.activities?.filter(ac => ac.activity?.id === activityId)
                    .map(
                        ac => ({ ...ac, locations: ac.locations.filter(loc => loc.id === locationId) } as HostActivity)
                    )?.[0],
            [requestStore.hosts]
        );

        const requests = useMemo(() => {
            return [
                ...props.requests.map(
                    item =>
                        ({
                            ...item,
                            user: item.userDto,
                            hostActivity: {
                                ...findHostActivity(
                                    item.hostGeneralInfoDto.id,
                                    item.selectedActivityId,
                                    item.selectedLocationId
                                ),
                                host: requestStore.hosts.find(
                                    host => host.generalInfo?.id === item.hostGeneralInfoDto.id
                                )?.generalInfo
                            } as HostActivity
                        } as Request)
                )
            ];
        }, [props.requests, requestStore.hosts]);

        // Uses to reopen the search
        const onSearch = () => {
            if (searchStore.activity && searchStore.location?.coordination && searchStore.distance) {
                loadingStore.triggerLoading();
                findLocations(searchStore.activity, searchStore.location?.coordination, searchStore.distance)
                    .then(results => {
                        loadingStore.stopLoading();
                        searchStore.clearSelectedResults();
                        const resultsFiltered = results.filter(r => !!r.location?.coordination?.latitude);
                        searchStore.setSearchResults(resultsFiltered);
                        if (resultsFiltered.length === 0) {
                            messagesStore.warning("No hosts found");
                        } else {
                            routingStore.pushRoute("/");
                        }
                    })
                    .catch(async e => {
                        loadingStore.stopLoading();
                        await errorHandler(e, props.rootStore);
                        messagesStore.error(e.message ?? "Error while retrieving results from search");
                    });
            }
        };

        return (
            <>
                <div className="request-overview-header">
                    <h2>REQUEST</h2>
                </div>
                <div className="request-overview-info">
                    <div className="activity-type-col">
                        {uniqueActivities.map(activity => (
                            <div
                                key={activity.id}
                                className={`${getThemeColor(activity.type)} activity-name`}
                                onClick={() => {
                                    if (props.filterActivity?.id === activity.id) {
                                        props.setFilterActivity(undefined);
                                    } else {
                                        props.setFilterActivity(activity);
                                    }
                                }}
                            >
                                {activity.name}
                                {props.filterActivity?.id === activity.id && (
                                    <CancelRounded fontSize="small" titleAccess="Clear" style={{ marginTop: "8px" }} />
                                )}
                            </div>
                        ))}
                    </div>
                    <div className="activity-info-col">
                        {requests.map(request => {
                            const selectedLocation = request.hostActivity?.locations?.[0];
                            return (
                                <div
                                    className={classNames("container flex-row", {
                                        selected: request.id === props.focused?.id
                                    })}
                                    key={request.id ?? nanoid()}
                                    onClick={() => {
                                        if (props.focused?.id !== request.id) {
                                            loadingStore.triggerLoading();
                                            props.setFocused(request);
                                        }
                                    }}
                                    style={{ cursor: props.focused?.id !== request.id ? "pointer" : "auto" }}
                                    title="Select this booking request"
                                >
                                    {(request.status === RequestStatus.OFFER ||
                                        request.status === RequestStatus.COMPLETED) && (
                                        <div className="request-status">
                                            {request.status === RequestStatus.OFFER && <div className="badge">1</div>}
                                            {request.status === RequestStatus.COMPLETED && (
                                                <div>
                                                    <img
                                                        src="assets/Icons/Icon_lock.svg"
                                                        className="icon_style_lock"
                                                        alt="Lock"
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    )}
                                    <div className="request-details flex-row">
                                        <div className="detail-section">
                                            <div className="h6">{request.hostActivity?.host?.name}</div>
                                            <div className="h7">
                                                {selectedLocation?.location?.address?.city},{" "}
                                                {selectedLocation?.location?.address?.country}
                                            </div>
                                            <div className="flex-row">
                                                <div className="h8">DATE: </div>
                                                {request.requestDate && (
                                                    <div className="h9">
                                                        {new Date(request.requestDate).toLocaleDateString()}
                                                    </div>
                                                )}
                                            </div>
                                            <div className="flex-row">
                                                <div className="h8">PRODUCT: </div>
                                                <div className="h9">{request.product}</div>
                                            </div>
                                            <div className="flex-row">
                                                <div className="h8">PERSON: </div>
                                                <div className="h9">{request.numberOfPeople}</div>
                                            </div>
                                            <div className="flex-row">
                                                <div className="h8">MATERIAL: </div>
                                                <div className="h9">{request.material ? "Yes incl." : "No"}</div>
                                            </div>
                                        </div>
                                        <div
                                            className={classNames({
                                                "button-section disable_all_content":
                                                    request.status === RequestStatus.COMPLETED,
                                                "button-section": request.status !== RequestStatus.COMPLETED
                                            })}
                                        >
                                            <div
                                                className={
                                                    request.status === RequestStatus.COMPLETED
                                                        ? "btn btn-status-gray-rounded"
                                                        : "btn btn-status-white-rounded"
                                                }
                                            >
                                                {request.status}
                                            </div>
                                            {request.price !== undefined && request.price > 0 && (
                                                <div>
                                                    <button
                                                        className={
                                                            request.status === RequestStatus.COMPLETED
                                                                ? "btn btn-status-gray-rounded"
                                                                : "btn btn-status-white-rounded"
                                                        }
                                                    >
                                                        € {request.price}
                                                    </button>
                                                </div>
                                            )}
                                            {request.status === RequestStatus.OFFER && (
                                                <div className="expire-text">Expires in 24 hours</div>
                                            )}
                                            <div>
                                                <button
                                                    className="btn btn-share-white-rounded"
                                                    onClick={e => {
                                                        e.stopPropagation();
                                                        const location = selectedLocation?.location;
                                                        if (location) {
                                                            searchStore.setLocation(location);
                                                        }
                                                        searchStore.setActivity(request.hostActivity.activity);
                                                        onSearch();
                                                    }}
                                                    title="Reopen search based on this request"
                                                >
                                                    <img
                                                        src="assets/Icons/Icon_social_share.svg"
                                                        className="icon_style_share"
                                                        alt="Share"
                                                    />
                                                    REOPEN SEARCH
                                                </button>
                                            </div>
                                            {request.status === RequestStatus.UNSENT && (
                                                <div>
                                                    <button
                                                        className="btn btn-remove-white-rounded"
                                                        onClick={() => {
                                                            if (
                                                                window.confirm("Are sure you want to delete this item?")
                                                            ) {
                                                                const result = searchStore.selectedResults.find(
                                                                    r =>
                                                                        r.idHostActivityLocation ===
                                                                            request.hostActivity.locations[0].id &&
                                                                        r.host.id === request.hostActivity.host.id
                                                                );
                                                                if (result) {
                                                                    searchStore.deselectResult(result);
                                                                }
                                                            }
                                                        }}
                                                    >
                                                        REMOVE
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </>
        );
    })
);

function getThemeColor(type: ThemeType): string {
    switch (type) {
        case ThemeType.WATER:
            return "water";
        case ThemeType.EARTH:
            return "earth";
        case ThemeType.ALPINE:
            return "alpine";
        case ThemeType.AIR:
            return "air";
        default:
            return "water";
    }
}
