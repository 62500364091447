import { inject, observer } from "mobx-react";
import { Stores } from "../../../models";
import { TextBox } from "../../common/TextBox";
import { Activity, HostActivityLocation, Offer, OfferTemplate as OfferTemplateType, Request } from "@hoverflo/shared";
import { Formik, FormikHelpers } from "formik";
import { Dropdown } from "../../common/dropdown/Dropdown";
import React, { useEffect, useState } from "react";
import { formatCurrency } from "@hoverflo/shared/api/utils/formats";
import { addOfferToRequest, saveOfferTemplate, updateOfferTemplate } from "@hoverflo/shared/api/services/offer.service";
import { themeColors } from "@hoverflo/shared/api/utils/constants";
import Checkbox from "@mui/material/Checkbox";
import { checkEmpty, formatLocalizedDate } from "../../../utils";
import i18n from "../../../i18n";
import { loremIpsum } from "../../../utils/constants";
import { addDays, subDays } from "date-fns";

const personValues = [
    { value: "1", label: "1 PERSON" },
    { value: "2", label: "2 PERSONS" },
    { value: "3", label: "3 PERSONS" },
    { value: "4", label: "4 PERSONS" },
    { value: "5", label: "5 PERSONS" }
];

const noticePeriodValues = [
    { value: "0", label: "CANCELLATION NOT POSSIBLE" },
    { value: "1", label: "1 DAY" },
    { value: "3", label: "3 DAYS" },
    { value: "7", label: "1 WEEK" },
    { value: "30", label: "1 MONTH" }
];

const expiringDaysValues = [
    { value: "0", label: "NO EXPIRATION" },
    { value: "1", label: "1 DAY" },
    { value: "3", label: "3 DAYS" },
    { value: "7", label: "1 WEEK" },
    { value: "30", label: "1 MONTH" }
];

const packageValues = [
    { value: "1", label: "LESSON" },
    { value: "2", label: "EQUIPMENT" },
    { value: "3", label: "LESSON & EQUIPMENT" }
];

// TODO: Fixed values should be changed to API
const downPaymentValues = [
    { value: "1", label: "1%" },
    { value: "5", label: "5%" },
    { value: "10", label: "10%" },
    { value: "15", label: "15%" },
    { value: "25", label: "25%" },
    { value: "50", label: "50%" }
];

const termHoverflo = loremIpsum;

const checkNumber = (value: string) => {
    const number = Number(value);
    if (isNaN(number)) {
        return 0;
    }
    return number;
};

interface OfferTemplateProps extends Stores {
    offerTemplate?: OfferTemplateType;
    request: Request;
    onSave: (offerTemplate: OfferTemplateType) => void;
    onAddOfferToChat: (offer: Offer) => void;
    onCancel: () => void;
}

interface OfferTemplateForm {
    packageName: string;
    activity: string;
    persons: string;
    noticePeriod: string;
    package: string;
    location: string;
    instructor: string;
    expiringDays: string;
    downPayment: string;
    lessonPrice: string;
    materialPrice: string;
    othersPrice: string;
    termsAndConditions: string;
    termsHoverflo: boolean;
    termsHost: boolean;
    specialMessage: string;
}

export const MakeOffer = inject("rootStore")(
    observer((props: OfferTemplateProps) => {
        const { hostStore, loadingStore, messagesStore, offerTemplateStore } = props.rootStore;
        const [activity, setActivity] = useState<string>(props.offerTemplate?.activityDto?.id);
        const [locations, setLocations] = useState<HostActivityLocation[]>([]);
        const [activities, setActivities] = useState<Array<Activity | undefined>>([]);
        const [step, setStep] = useState(1);

        useEffect(() => {
            setLocations(
                hostStore.host.activities?.filter(ac => ac.activity?.id === activity).flatMap(ac => ac.locations) ?? []
            );
            setActivities(hostStore.host.activities?.map(ac => ac.activity).filter(Boolean) ?? []);
        }, [hostStore.host, activity]);

        useEffect(() => {
            setActivity(props.offerTemplate?.activityDto?.id);
        }, [props.offerTemplate]);

        const onSave = async (values: OfferTemplateForm, { resetForm }: FormikHelpers<OfferTemplateForm>) => {
            loadingStore.triggerLoading();
            try {
                const offerTemplate = {
                    packageName: values.packageName,
                    packageDescription: packageValues.find(pkg => pkg.value === values.package)!.label,
                    persons: Number(values.persons),
                    noticePeriodDays: Number(values.noticePeriod),
                    expirationDateDays: Number(values.expiringDays),
                    instructor: values.instructor ?? "",
                    downPaymentPercentage: Number(values.downPayment),
                    lessonPrice: Number(values.lessonPrice),
                    materialPrice: Number(values.materialPrice),
                    otherPrice: Number(values.othersPrice),
                    hostActivityLocationId: values.location,
                    termsAndConditionsId: values.termsAndConditions
                } as OfferTemplateType;
                if (props.offerTemplate?.id) {
                    const updatedOfferTemplate = await updateOfferTemplate(offerTemplate, props.offerTemplate.id);
                    offerTemplateStore.updateOffer(updatedOfferTemplate);
                    props.onSave(updatedOfferTemplate);
                } else {
                    const savedOfferTemplate = await saveOfferTemplate(offerTemplate);
                    offerTemplateStore.addOffer(savedOfferTemplate);
                    props.onSave(savedOfferTemplate);
                }
                loadingStore.stopLoading();
                resetForm();
                messagesStore.success("Offer template successfully saved");
            } catch (e) {
                loadingStore.stopLoading();
                messagesStore.error("Error occurred while saving offer template.");
            }
        };

        const onAddOfferToChat = async (values: OfferTemplateForm) => {
            if (props.request.requestDate) {
                const offerTemplate = {
                    packageName: values.packageName,
                    packageDescription: packageValues.find(pkg => pkg.value === values.package)!.label,
                    persons: Number(values.persons),
                    noticePeriodDays: Number(values.noticePeriod),
                    expirationDateDays: Number(values.expiringDays),
                    instructor: values.instructor ?? "",
                    downPaymentPercentage: Number(values.downPayment),
                    lessonPrice: Number(values.lessonPrice),
                    materialPrice: Number(values.materialPrice),
                    otherPrice: Number(values.othersPrice),
                    hostActivityLocationId: values.location,
                    termsAndConditionsId: values.termsAndConditions
                } as OfferTemplateType;
                const noticePeriod = subDays(new Date(props.request.requestDate), -Number(values.noticePeriod));
                const expirationDate = addDays(new Date(props.request.requestDate), Number(values.expiringDays));
                const offer: Offer = {
                    ...offerTemplate,
                    noticePeriod,
                    expirationDate,
                    requestBaseDto: props.request,
                    hostActivityLocation: {
                        ...offerTemplate.hostActivityLocation,
                        id: offerTemplate.hostActivityLocationId,
                        activityId: values.activity
                    } as any
                } as any;
                try {
                    loadingStore.triggerLoading();
                    const savedOffer = await addOfferToRequest(offer);
                    props.onAddOfferToChat(savedOffer);
                    loadingStore.stopLoading();
                    messagesStore.success("Offer successfully added to chat");
                } catch (e) {
                    loadingStore.stopLoading();
                    messagesStore.error("An error occurred while adding offer to chat");
                }
            }
        };
        return (
            <div className="make-offer">
                <Formik
                    initialValues={{
                        packageName: props.offerTemplate?.packageName ?? "",
                        activity: props.offerTemplate?.activityDto?.id ?? "",
                        persons: props.offerTemplate?.persons.toString() ?? "",
                        noticePeriod: props.offerTemplate?.noticePeriodDays.toString() ?? "",
                        package:
                            packageValues.find(v => v.label === props.offerTemplate?.packageDescription)?.value ?? "",
                        location: props.offerTemplate?.hostActivityLocationId ?? "",
                        instructor: props.offerTemplate?.instructor ?? "",
                        expiringDays: props.offerTemplate?.expirationDateDays?.toString() ?? "",
                        downPayment: props.offerTemplate?.downPaymentPercentage?.toString() ?? "",
                        lessonPrice: props.offerTemplate?.lessonPrice?.toString() ?? "",
                        materialPrice: props.offerTemplate?.materialPrice?.toString() ?? "",
                        othersPrice: props.offerTemplate?.otherPrice?.toString() ?? "",
                        termsAndConditions: props.offerTemplate?.termsAndConditionsId ?? "",
                        termsHoverflo: false,
                        termsHost: false,
                        specialMessage: ""
                    }}
                    validate={values => {
                        let errors = {} as any;
                        if (!values.packageName) {
                            errors.packageName = "Required";
                        }
                        if (!values.activity) {
                            errors.activity = "Required";
                        }
                        if (!values.persons) {
                            errors.persons = "Required";
                        }
                        if (values.noticePeriod === undefined) {
                            errors.noticePeriod = "Required";
                        }
                        if (!values.package) {
                            errors.package = "Required";
                        }
                        if (!values.location) {
                            errors.location = "Required";
                        }
                        if (!values.instructor) {
                            errors.instructor = "Required";
                        }
                        if (values.expiringDays === undefined) {
                            errors.expiringDays = "Required";
                        }
                        if (!values.downPayment) {
                            errors.downPayment = "Required";
                        }
                        if (!values.termsAndConditions) {
                            errors.termsAndConditions = "Required";
                        }
                        if (values.lessonPrice && isNaN(Number(values.lessonPrice))) {
                            errors.lessonPrice = "Invalid";
                        }
                        if (values.materialPrice && isNaN(Number(values.materialPrice))) {
                            errors.materialPrice = "Invalid";
                        }
                        if (values.othersPrice && isNaN(Number(values.othersPrice))) {
                            errors.othersPrice = "Invalid";
                        }
                        return errors;
                    }}
                    enableReinitialize={true}
                    onSubmit={onAddOfferToChat}
                >
                    {({ values, errors, handleChange, handleBlur, handleSubmit, setFieldValue, resetForm }) => (
                        <form onSubmit={handleSubmit}>
                            {step === 1 && (
                                <>
                                    <div className="flex-row title">
                                        <h1>Step 1/ 2:</h1>
                                        <h2>Creating offer based on saved template:</h2>
                                        <h3>fill in missing information</h3>
                                    </div>
                                    <hr />
                                    <div className="flex-row">
                                        <label className="middle-align">Package Name:</label>
                                        <TextBox
                                            rounded={false}
                                            invalid={errors && !!errors.packageName}
                                            name="packageName"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.packageName}
                                            placeholder="Package name"
                                        />
                                    </div>
                                    <hr />
                                    <h2>General information:</h2>
                                    <div className="flex-row">
                                        <label className="middle-align">Activity:</label>
                                        <Dropdown
                                            name="activity"
                                            value={values.activity}
                                            options={activities.map(ac => ({ label: ac.name, value: ac.id }))}
                                            onChange={value => {
                                                setFieldValue("activity", value ? value : undefined);
                                                setActivity(value);
                                            }}
                                            invalid={errors && !!errors.activity}
                                            placeholder="Select an activity"
                                        />
                                    </div>
                                    <div className="flex-row">
                                        <label className="middle-align">Persons:</label>
                                        <Dropdown
                                            name="persons"
                                            value={String(values.persons)}
                                            options={personValues}
                                            onChange={value => {
                                                setFieldValue("persons", value ? Number(value) : undefined);
                                            }}
                                            invalid={errors && !!errors.persons}
                                            placeholder="Select the quantity of persons"
                                        />
                                    </div>
                                    <div className="flex-row">
                                        <label className="middle-align">Notice period:</label>
                                        <Dropdown
                                            name="noticePeriod"
                                            value={String(values.noticePeriod)}
                                            options={noticePeriodValues}
                                            onChange={value => {
                                                setFieldValue("noticePeriod", value ? Number(value) : undefined);
                                            }}
                                            invalid={errors && !!errors.noticePeriod}
                                            placeholder="Select the notice period"
                                        />
                                    </div>
                                    <div className="flex-row">
                                        <label className="middle-align">Package:</label>
                                        <Dropdown
                                            name="package"
                                            value={String(values.package)}
                                            options={packageValues}
                                            onChange={value => {
                                                setFieldValue("package", value ? value : undefined);
                                            }}
                                            invalid={errors && !!errors.package}
                                            placeholder="Select a package"
                                        />
                                    </div>
                                    <div className="flex-row">
                                        <label className="middle-align">Location:</label>
                                        <Dropdown
                                            name="location"
                                            value={values.location}
                                            options={locations.map(l => ({
                                                label: l.location.address.fullAddress,
                                                value: l.id
                                            }))}
                                            onChange={value => {
                                                setFieldValue("location", value ? value : undefined);
                                            }}
                                            invalid={errors && !!errors.location}
                                            placeholder="Select a location"
                                        />
                                    </div>
                                    <div className="flex-row">
                                        <label className="middle-align">Instructor:</label>
                                        <TextBox
                                            rounded={false}
                                            invalid={errors && !!errors.instructor}
                                            name="instructor"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.instructor}
                                            placeholder="Instructor name"
                                        />
                                    </div>
                                    <div className="flex-row">
                                        <label className="middle-align">Expiring date:</label>
                                        <Dropdown
                                            name="expiringDays"
                                            value={values.expiringDays}
                                            options={expiringDaysValues}
                                            onChange={value => {
                                                setFieldValue("expiringDays", value ? value : undefined);
                                            }}
                                            invalid={errors && !!errors.expiringDays}
                                            placeholder="Select the number of expiring days"
                                        />
                                    </div>
                                    <hr />
                                    <h2>Price:</h2>
                                    <div className="flex-row">
                                        <label className="middle-align">Lesson:</label>
                                        <TextBox
                                            rounded={false}
                                            invalid={errors && !!errors.lessonPrice}
                                            name="lessonPrice"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.lessonPrice}
                                            placeholder="Lesson price"
                                        />
                                    </div>
                                    <div className="flex-row">
                                        <label className="middle-align">Material:</label>
                                        <TextBox
                                            rounded={false}
                                            invalid={errors && !!errors.materialPrice}
                                            name="materialPrice"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.materialPrice}
                                            placeholder="Material price"
                                        />
                                    </div>
                                    <div className="flex-row">
                                        <label className="middle-align">Others:</label>
                                        <TextBox
                                            rounded={false}
                                            invalid={errors && !!errors.othersPrice}
                                            name="othersPrice"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.othersPrice}
                                            placeholder="Other prices"
                                        />
                                    </div>
                                    <h3>
                                        Total price:{" "}
                                        {formatCurrency(
                                            checkNumber(values.lessonPrice) +
                                                checkNumber(values.materialPrice) +
                                                checkNumber(values.othersPrice)
                                        )}{" "}
                                        INCL. TAX
                                    </h3>
                                    <div className="flex-row">
                                        <label className="middle-align">Down payment:</label>
                                        <Dropdown
                                            name="downPayment"
                                            value={values.downPayment}
                                            options={downPaymentValues}
                                            onChange={value => {
                                                setFieldValue("downPayment", value ? value : undefined);
                                            }}
                                            invalid={errors && !!errors.downPayment}
                                            placeholder="Select a down payment"
                                        />
                                    </div>
                                    <h3>
                                        Down payment:{" "}
                                        {formatCurrency(
                                            (checkNumber(values.lessonPrice) +
                                                checkNumber(values.materialPrice) +
                                                checkNumber(values.othersPrice)) *
                                                (checkNumber(values.downPayment) ? Number(values.downPayment) / 100 : 1)
                                        )}{" "}
                                        INCL. TAX
                                    </h3>
                                    <hr />
                                    <h2>Terms & conditions:</h2>
                                    <div className="flex-row">
                                        <label className="middle-align">Terms & conditions:</label>
                                        <Dropdown
                                            name="termsAndConditions"
                                            value={values.termsAndConditions}
                                            options={
                                                hostStore.host.termsAndConditions?.map(tc => ({
                                                    label: tc.name,
                                                    value: tc.id
                                                })) ?? []
                                            }
                                            onChange={value => {
                                                setFieldValue("termsAndConditions", value ? value : undefined);
                                            }}
                                            invalid={errors && !!errors.termsAndConditions}
                                            placeholder="Select a terms & conditions"
                                        />
                                    </div>
                                    <hr />
                                </>
                            )}
                            {step === 2 && (
                                <>
                                    <div className="flex-row title">
                                        <h1>Step 2/ 2:</h1>
                                        <h2>Adding Terms & conditions and message to offer</h2>
                                    </div>
                                    <hr />
                                    <div className="check">
                                        <Checkbox
                                            name="check-hoverflo"
                                            onChange={event => setFieldValue("termsHoverflo", event.target.checked)}
                                            value={values.termsHoverflo}
                                            sx={{
                                                color: "white",
                                                "&.Mui-checked": {
                                                    color: themeColors.EARTH.background
                                                }
                                            }}
                                        />
                                        <label htmlFor="check-hoverflo">Terms & conditions Hoverflo</label>
                                    </div>
                                    <div className="check">
                                        <Checkbox
                                            name="check-host"
                                            onChange={event => setFieldValue("termsHost", event.target.checked)}
                                            value={values.termsHost}
                                            sx={{
                                                color: "white",
                                                "&.Mui-checked": {
                                                    color: themeColors.EARTH.background
                                                }
                                            }}
                                        />
                                        <label htmlFor="check-host">
                                            Terms & conditions {hostStore.host?.generalInfo?.name}
                                        </label>
                                    </div>
                                    <hr />
                                    <div className="flex-row agreements">
                                        <div className="col-6">
                                            <h2>Agreement</h2>
                                            <div className="agreement">
                                                <p>
                                                    <hr />
                                                    <h2>
                                                        Terms & Conditions:{" "}
                                                        {values.termsHoverflo
                                                            ? values.termsHost
                                                                ? "Hoverflo & "
                                                                : "Hoverflo"
                                                            : ""}
                                                        {values.termsHost && hostStore.host?.generalInfo?.name}
                                                    </h2>
                                                    <h2>
                                                        Date:{" "}
                                                        {formatLocalizedDate(
                                                            new Date(props.request.requestDate),
                                                            "dd LLLL yyyy",
                                                            (i18n.language as any) ?? "en"
                                                        )}
                                                    </h2>
                                                    <h2>
                                                        Location:{" "}
                                                        {
                                                            locations.find(l => l.id === values.location)?.location
                                                                ?.address?.fullAddress
                                                        }
                                                    </h2>
                                                    <hr />
                                                    {values.termsHoverflo && termHoverflo}
                                                    {values.termsHost &&
                                                        hostStore.host.termsAndConditions.find(
                                                            tc => tc.id === values.termsAndConditions
                                                        )?.agreementText}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <h2>Special message</h2>
                                            <textarea
                                                name="specialMessage"
                                                className="form-control"
                                                rows={15}
                                                cols={50}
                                                maxLength={500}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.specialMessage}
                                            ></textarea>
                                        </div>
                                    </div>
                                    <hr />
                                </>
                            )}
                            {step === 3 && (
                                <>
                                    <div className="flex-row title">
                                        <h2>Offer preview</h2>
                                    </div>
                                    <hr />
                                    <div className="flex-row">
                                        <div className="col-md-6">
                                            <div className="overview">
                                                <hr />
                                                <div className="flex-row">
                                                    <h1 className="col-md-3">Activity:</h1>
                                                    <h2 className="col-md-9">
                                                        {activities.find(a => a.id === values.activity)?.name}
                                                    </h2>
                                                </div>
                                                <div className="flex-row">
                                                    <h1 className="col-md-3">Date:</h1>
                                                    <h2 className="col-md-9">
                                                        {formatLocalizedDate(
                                                            new Date(props.request.requestDate),
                                                            "dd-MM-yyyy",
                                                            (i18n.language as any) ?? "en"
                                                        )}
                                                    </h2>
                                                </div>
                                                <div className="flex-row">
                                                    <h1 className="col-md-3">Persons:</h1>
                                                    <h2 className="col-md-9">
                                                        {personValues.find(v => v.value === values.persons)?.label}
                                                    </h2>
                                                </div>
                                                <div className="flex-row">
                                                    <h1 className="col-md-3">Notice Period:</h1>
                                                    <h2 className="col-md-9">
                                                        {
                                                            noticePeriodValues.find(
                                                                v => v.value === values.noticePeriod
                                                            )?.label
                                                        }
                                                    </h2>
                                                </div>
                                                <div className="flex-row">
                                                    <h1 className="col-md-3">Location:</h1>
                                                    <h2 className="col-md-9">
                                                        {
                                                            locations.find(l => l.id === values.location)?.location
                                                                ?.address?.fullAddress
                                                        }
                                                    </h2>
                                                </div>
                                                <div className="flex-row">
                                                    <h1 className="col-md-3">Instructor:</h1>
                                                    <h2 className="col-md-9">{values.instructor}</h2>
                                                </div>
                                                <hr />
                                                <div className="flex-row">
                                                    <h1 className="col-md-3">Package:</h1>
                                                    <h2 className="col-md-9">{values.packageName}</h2>
                                                </div>
                                                <hr />
                                                <div className="flex-row">
                                                    <h1 className="col-md-3">Lesson:</h1>
                                                    <h2 className="col-md-9">
                                                        {formatCurrency(Number(values.lessonPrice ?? "0"))}
                                                    </h2>
                                                </div>
                                                <div className="flex-row">
                                                    <h1 className="col-md-3">Material:</h1>
                                                    <h2 className="col-md-9">
                                                        {formatCurrency(Number(values.materialPrice ?? "0"))}
                                                    </h2>
                                                </div>
                                                <div className="flex-row">
                                                    <h1 className="col-md-3">Others:</h1>
                                                    <h2 className="col-md-9">
                                                        {formatCurrency(Number(values.othersPrice ?? "0"))}
                                                    </h2>
                                                </div>
                                                <hr />
                                                <div className="flex-row">
                                                    <h1 className="col-md-3">Total Price:</h1>
                                                    <h2 className="col-md-9">
                                                        {formatCurrency(
                                                            Number(values.lessonPrice ?? "0") +
                                                                Number(values.materialPrice ?? "0") +
                                                                Number(values.othersPrice ?? "0")
                                                        )}
                                                    </h2>
                                                </div>
                                                <div className="flex-row">
                                                    <h1 className="col-md-3">Expiring date:</h1>
                                                    {values.expiringDays === "0" && (
                                                        <h2 className="col-md-9">
                                                            {
                                                                expiringDaysValues.find(
                                                                    v => v.value === values.expiringDays
                                                                )?.label
                                                            }
                                                        </h2>
                                                    )}
                                                    {values.expiringDays !== "0" && (
                                                        <h2 className="col-md-9">
                                                            {formatLocalizedDate(
                                                                addDays(new Date(), Number(values.expiringDays)),
                                                                "dd-MM-yyyy",
                                                                (i18n.language as any) ?? "en"
                                                            )}{" "}
                                                            (
                                                            {
                                                                expiringDaysValues.find(
                                                                    v => v.value === values.expiringDays
                                                                )?.label
                                                            }
                                                            )
                                                        </h2>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 tc">
                                            <div className="tc-details">
                                                <div>
                                                    <strong>Dear user</strong>, Hereby a special offer based on your
                                                    request
                                                </div>
                                                <div>
                                                    <strong>Special message</strong>:{" "}
                                                    {checkEmpty(values.specialMessage, "[NO MESSAGE]")}
                                                </div>
                                                <div>
                                                    <b>TERMS & CONDITIONS:</b>
                                                    <p>
                                                        {
                                                            hostStore.host?.termsAndConditions.find(
                                                                tc => tc.id === values.termsAndConditions
                                                            )?.agreementText
                                                        }
                                                    </p>
                                                    It is a long established fact that a reader will be distracted by
                                                    the readable content of a page when looking at its layout. The point
                                                    of using Lorem Ipsum is that it has a more-or-less normal
                                                    distribution of letters, as opposed to using 'Content here, content
                                                    here', making it look like readable English. Many desktop publishing
                                                    packages and web page editors now use Lorem Ipsum as their default
                                                    model text, and a search for 'lorem ipsum' will uncover many web
                                                    sites still in their infancy. Various versions have evolved over the
                                                    years, sometimes by accident, sometimes on purpose (injected humour
                                                    and the like). It is a long established fact that a reader will be
                                                    distracted by the readable content of a page when looking at its
                                                    layout. The point of using Lorem Ipsum is that it has a more-or-less
                                                    normal distribution of letters, as opposed to using 'Content here,
                                                    content here', making it look like readable English. Many desktop
                                                    publishing packages and web page editors now use Lorem Ipsum as
                                                    their default model text, and a search for 'lorem ipsum' will
                                                    uncover many web sites still in their infancy. Various versions have
                                                    evolved over the years, sometimes by accident, sometimes on purpose
                                                    (injected humour and the like). It is a long established fact that a
                                                    reader will be distracted by the readable content of a page when
                                                    looking at its layout. The point of using Lorem Ipsum is that it has
                                                    a more-or-less normal distribution of letters, as opposed to using
                                                    'Content here, content here', making it look like readable English.
                                                    Many desktop publishing packages and web page editors now use Lorem
                                                    Ipsum as their default model text, and a search for 'lorem ipsum'
                                                    will uncover many web sites still in their infancy. Various versions
                                                    have evolved over the years, sometimes by accident, sometimes on
                                                    purpose (injected humour and the like).
                                                </div>
                                            </div>

                                            <div className="btn btn-host-rounded">TERMS & CONDITIONS HOVERFLO</div>
                                            <div className="btn btn-host-rounded">
                                                TERMS & CONDITIONS {hostStore.host?.generalInfo?.name}
                                            </div>
                                        </div>
                                    </div>
                                    <hr className="margin-top" />
                                </>
                            )}
                            <div className="save">
                                <button
                                    type="button"
                                    className="btn btn-host-rounded btn-back"
                                    onClick={step === 1 ? props.onCancel : () => setStep(prev => prev - 1)}
                                >
                                    Back
                                </button>
                                {step === 1 && (
                                    <button
                                        disabled={Object.keys(errors).length > 0}
                                        type="button"
                                        className="btn btn-orange-rounded"
                                        onClick={() => setStep(2)}
                                    >
                                        Next
                                    </button>
                                )}
                                {step > 1 && (
                                    <button
                                        disabled={Object.keys(errors).length > 0}
                                        type="button"
                                        className="btn btn-dark-host-rounded btn-back"
                                        onClick={() => onSave(values, { resetForm } as any)}
                                    >
                                        Save offer
                                    </button>
                                )}
                                {step === 2 && (
                                    <button
                                        disabled={Object.keys(errors).length > 0}
                                        type="button"
                                        className="btn btn-orange-rounded"
                                        onClick={() => setStep(3)}
                                    >
                                        Next
                                    </button>
                                )}
                                {step === 3 && (
                                    <button
                                        disabled={Object.keys(errors).length > 0}
                                        type="submit"
                                        className="btn btn-orange-rounded"
                                    >
                                        Add offer to chat
                                    </button>
                                )}
                            </div>
                        </form>
                    )}
                </Formik>
            </div>
        );
    })
);
