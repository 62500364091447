import MapComponent, { MapRef, Marker, NavigationControl } from "react-map-gl";
import { mapStylesUrl } from "../../../utils/constants";
import { config } from "@hoverflo/shared/api/utils/constants";
import { Request } from "@hoverflo/shared";
import { Stores } from "../../../models";
import React, { useEffect, useState } from "react";

interface MapProps extends Stores {
    request: Request;
}

export function Map(props: MapProps) {
    const [mapRef, setMapRef] = useState<MapRef>();
    const location = props.request.hostActivity?.locations?.[0]?.location;

    useEffect(() => {
        if (location?.coordination && mapRef) {
            mapRef.flyTo({ center: [location.coordination.longitude, location.coordination.latitude] });
        }
    }, [props.request, location]);

    if (!location?.coordination) {
        return null;
    }

    return (
        <MapComponent
            reuseMaps
            ref={r => {
                if (r && !mapRef) {
                    setMapRef(r);
                }
            }}
            mapStyle={mapStylesUrl.EARTH}
            mapboxAccessToken={config.mapboxKey}
            initialViewState={{
                latitude: location.coordination.latitude,
                longitude: location.coordination.longitude,
                zoom: 15
            }}
            scrollZoom={false}
            maxZoom={15}
        >
            <NavigationControl showCompass={false} showZoom visualizePitch />
            <Marker
                latitude={location.coordination.latitude}
                longitude={location.coordination.longitude}
                anchor="bottom"
                offset={[0, -4]}
                style={{ cursor: "pointer" }}
            >
                <img src="/assets/Icons/marker.svg" width={32} />
            </Marker>
        </MapComponent>
    );
}
