import React, { ReactElement } from "react";
import { inject, observer } from "mobx-react";
import { Stores } from "../../models";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

export const Loading = inject("rootStore")(
    observer((props: Stores) => {
        return (
            <Backdrop
                sx={{ color: "#fff", zIndex: theme => theme.zIndex.drawer + 1 }}
                open={props.rootStore?.loadingStore.loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        );
    })
);

export function LazyLoading(): ReactElement {
    return (
        <Backdrop sx={{ color: "#fff", zIndex: theme => theme.zIndex.drawer + 1 }} open>
            <CircularProgress color="inherit" />
        </Backdrop>
    );
}
