var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { action, computed, makeObservable, observable } from "mobx";
export class SessionStore {
    constructor() {
        this.session = {};
        makeObservable(this);
    }
    clearCurrentSession() {
        this.session = {};
    }
    setCurrentSession(session) {
        this.session = session;
    }
    updateUser(user) {
        this.session.user = user;
    }
    get currentSession() {
        return this.session;
    }
    get logged() {
        return this.session ? !!this.session.accessToken : false;
    }
    get isHost() {
        var _a, _b, _c, _d, _e, _f;
        return ((_c = ((_b = (_a = this.session) === null || _a === void 0 ? void 0 : _a.user) === null || _b === void 0 ? void 0 : _b.userType) === "HOST") !== null && _c !== void 0 ? _c : false) || !!((_f = (_e = (_d = this.session) === null || _d === void 0 ? void 0 : _d.user) === null || _e === void 0 ? void 0 : _e.hostDto) === null || _f === void 0 ? void 0 : _f.id);
    }
    get isUser() {
        var _a, _b, _c;
        return (_c = ((_b = (_a = this.session) === null || _a === void 0 ? void 0 : _a.user) === null || _b === void 0 ? void 0 : _b.userType) === "USER") !== null && _c !== void 0 ? _c : false;
    }
    get isAdmin() {
        var _a, _b, _c;
        return (_c = ((_b = (_a = this.session) === null || _a === void 0 ? void 0 : _a.user) === null || _b === void 0 ? void 0 : _b.userType) === "ADMIN") !== null && _c !== void 0 ? _c : false;
    }
}
__decorate([
    observable.deep
], SessionStore.prototype, "session", void 0);
__decorate([
    action
], SessionStore.prototype, "clearCurrentSession", null);
__decorate([
    action
], SessionStore.prototype, "setCurrentSession", null);
__decorate([
    action
], SessionStore.prototype, "updateUser", null);
__decorate([
    computed
], SessionStore.prototype, "currentSession", null);
__decorate([
    computed
], SessionStore.prototype, "logged", null);
__decorate([
    computed
], SessionStore.prototype, "isHost", null);
__decorate([
    computed
], SessionStore.prototype, "isUser", null);
__decorate([
    computed
], SessionStore.prototype, "isAdmin", null);
