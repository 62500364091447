import { MessageType, Offer, Request, ThemeType } from "@hoverflo/shared";
import React, { useEffect, useRef, useState } from "react";
import { inject, observer } from "mobx-react";
import { Stores } from "../../../models";
import { Chat } from "./index";
import { postMessage } from "@hoverflo/shared/api/services/chat.service";
import { nanoid } from "nanoid/non-secure";
import { useChat } from "./hooks";
import { errorHandler } from "../../../utils/errorHandler";
import { toJS } from "mobx";
import deepEqual from "deep-equal";
import Dialog from "@mui/material/Dialog";
import { OfferDetails } from "../offer-details/OfferDetails";
import { DialogContent, DialogTitle } from "@mui/material";
import { themeColors } from "@hoverflo/shared/api/utils/constants";
import { acceptOffer, rejectOffer, withdrawOffer } from "@hoverflo/shared/api/services/offer.service";

export interface ChatViewProps extends Stores {
    request: Request;
    onCreateOffer?: () => void;
}

export const ChatView = inject("rootStore")(
    observer((props: ChatViewProps) => {
        const { chatStore, sessionStore, loadingStore, messagesStore } = props.rootStore;
        const scrollRef = useRef<HTMLDivElement | null>(null);
        const [isLoading, messages, hostUserId, customerUserId] = useChat(
            props.request.id,
            props.rootStore,
            sessionStore.isHost
        );
        const [showOffer, setShowOffer] = useState(false);
        const [selectedOffer, setSelectedOffer] = useState<Offer>(undefined);

        useEffect(() => {
            chatStore.clean();
        }, []);

        useEffect(() => {
            if (!isLoading) {
                const newMessages = [
                    {
                        id: props.request.id,
                        type: MessageType.REQUEST,
                        sender: sessionStore.isHost ? hostUserId : customerUserId ?? "",
                        created: new Date(props.request.requestDate!),
                        receiver: sessionStore.isHost ? customerUserId : hostUserId ?? "",
                        requestId: props.request.id,
                        metaData: { request: props.request },
                        body: ""
                    },
                    ...messages
                ];

                if (
                    !deepEqual(
                        newMessages.map(c => c.id),
                        toJS(chatStore.messages).map(c => c.id),
                        { strict: false }
                    )
                ) {
                    chatStore.setMessages(newMessages);
                }
                loadingStore.stopLoading();
            }
        }, [isLoading, messages]);

        useEffect(() => {
            scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
            scrollRef.current?.scrollIntoView({ behavior: "smooth", block: "end" });
        }, [chatStore.messages.length]);

        return (
            <>
                <Dialog open={showOffer} onClose={() => setShowOffer(false)} sx={{ backgroundColor: "unset" }}>
                    <DialogTitle
                        sx={{
                            backgroundColor: sessionStore?.isHost
                                ? themeColors.host.backgroundTertiary
                                : themeColors.EARTH.backgroundTertiary,
                            color: "#000"
                        }}
                    >
                        <h1>OFFER DETAILS</h1>
                    </DialogTitle>
                    <DialogContent
                        sx={{
                            backgroundColor: sessionStore?.isHost
                                ? themeColors.host.backgroundTertiary
                                : themeColors.EARTH.backgroundTertiary
                        }}
                    >
                        {selectedOffer && props.request && (
                            <OfferDetails
                                isHost={sessionStore?.isHost}
                                offer={selectedOffer}
                                request={props.request}
                                onAcceptOffer={async () => {
                                    loadingStore.triggerLoading();
                                    setShowOffer(false);
                                    setSelectedOffer(undefined);
                                    try {
                                        await acceptOffer(selectedOffer);
                                        messagesStore.success("Offer successfully accepted");
                                    } catch (e) {
                                        messagesStore.error("Error while accepting offer");
                                    } finally {
                                        loadingStore.stopLoading();
                                    }
                                }}
                                onRejectOffer={async () => {
                                    setShowOffer(false);
                                    setSelectedOffer(undefined);
                                    try {
                                        await rejectOffer(selectedOffer);
                                        messagesStore.success("Offer successfully rejected");
                                    } catch (e) {
                                        messagesStore.error("Error while rejecting offer");
                                    } finally {
                                        loadingStore.stopLoading();
                                    }
                                }}
                                onWithdrawOffer={async () => {
                                    setShowOffer(false);
                                    setSelectedOffer(undefined);
                                    try {
                                        await withdrawOffer(selectedOffer);
                                        messagesStore.success("Offer successfully withdrew");
                                    } catch (e) {
                                        messagesStore.error("Error while withdrawing offer");
                                    } finally {
                                        loadingStore.stopLoading();
                                    }
                                }}
                            />
                        )}
                    </DialogContent>
                </Dialog>
                <Chat
                    ref={scrollRef}
                    messages={chatStore.messages}
                    theme={sessionStore.isHost ? "host" : ThemeType.EARTH}
                    onSend={(text: string) => {
                        const temporaryId = nanoid();

                        if (!text) {
                            return;
                        }
                        const sender = sessionStore.isHost ? hostUserId : customerUserId ?? "";
                        const receiver = sessionStore.isHost ? customerUserId : hostUserId ?? "";

                        const message = {
                            id: temporaryId,
                            sender,
                            receiver,
                            body: text,
                            created: new Date(),
                            type: MessageType.TEXT,
                            requestId: props.request.id
                        };

                        chatStore.addMessage(message);

                        postMessage({
                            body: message.body,
                            type: message.type,
                            receiver: message.receiver,
                            requestId: props.request.id
                        })
                            .then(message => {
                                chatStore.removeMessage(temporaryId);
                                chatStore.addMessage(message);
                            })
                            .catch(async e => await errorHandler(e, props.rootStore));
                    }}
                    onCreateOffer={props.onCreateOffer}
                    onClickViewOffer={offer => {
                        setSelectedOffer(offer);
                        setShowOffer(true);
                    }}
                />
            </>
        );
    })
);
