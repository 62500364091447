import { ThemeType } from "../models";
const baseUrl = (navigator && navigator.product === "ReactNative") || process.env.ENABLE_ACC_URL === "true"
    ? "https://acc.hoverflo.com"
    : `${window.location.protocol}//${window.location.hostname}`;
export const config = {
    url: baseUrl,
    chatUrl: `${baseUrl}/api/chat`,
    urlKeyCloak: `${baseUrl}/auth`,
    version: "1.0.0",
    mapboxKey: "pk.eyJ1IjoidmFkaXZlbDY1NCIsImEiOiJja3Q3aXR4Z3IwdDFtMnBqcGxiMHpyeG1kIn0.XGE2AReRzNy_zrA-Eyjd3w",
    postLogoutRedirectUrl: "hoverflo://home",
    postLoginRedirectUrl: "hoverflo://social-login"
};
export const keycloakConfig = {
    issuer: config.urlKeyCloak + "/realms/hoverflo-realm",
    realm: "hoverflo-realm",
    clientId: "hoverflo-pkce-client",
    redirectUrl: config.postLoginRedirectUrl,
    scopes: ["openid"]
};
export const themeColors = {
    [ThemeType.AIR]: {
        background: "#C6B69F",
        backgroundSecondary: "#E5DCCE",
        backgroundTertiary: "#E5DCCE",
        primary: "#333333",
        primaryDisabled: "#999",
        text: "#272727"
    },
    [ThemeType.ALPINE]: {
        background: "#4E2520",
        backgroundSecondary: "#8E4A36",
        backgroundTertiary: "#8E4A36",
        primary: "#FFF",
        primaryDisabled: "#999",
        text: "#FFF"
    },
    [ThemeType.WATER]: {
        background: "#253747",
        backgroundSecondary: "#688494",
        backgroundTertiary: "#688494",
        primary: "#FFF",
        primaryDisabled: "#999",
        text: "#FFF"
    },
    [ThemeType.EARTH]: {
        background: "#003532",
        backgroundSecondary: "#005F5E",
        backgroundTertiary: "#4E7C7C",
        primary: "#ED7931",
        primaryDisabled: "#f4ae83",
        text: "#FFF"
    },
    default: {
        background: "#333333",
        backgroundSecondary: "#5b5b5b",
        backgroundTertiary: "#5b5b5b",
        primary: "#ED7931",
        primaryDisabled: "#f4ae83",
        text: "#FFF"
    },
    host: {
        background: "#905E32",
        backgroundSecondary: "#BC8A48",
        backgroundTertiary: "#C89F6A",
        primary: "#ED7931",
        primaryDisabled: "#999",
        text: "#FFF"
    }
};
