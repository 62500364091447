var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { get, post } from "./base.service";
import { config } from "../utils/constants";
export function getCurrentUser(session) {
    return __awaiter(this, void 0, void 0, function* () {
        return get(`${config.url}/api/user/me`, session);
    });
}
export function register(body) {
    return __awaiter(this, void 0, void 0, function* () {
        return post(`${config.url}/api/register/user`, {
            firstName: body.firstName,
            lastName: body.lastName,
            email: body.email.toLowerCase(),
            displayName: body.firstName + " " + body.lastName,
            password: body.password
        });
    });
}
export function findUserByEmail(email) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            yield get(`${config.url}/api/user/email/${email}`);
            return Promise.resolve(true);
        }
        catch (e) {
            return Promise.resolve(false);
        }
    });
}
