import { Stores } from "../../../models";
import { inject, observer } from "mobx-react";
import { Host, HostTermsAndConditionsDto, Location } from "@hoverflo/shared";
import { Formik } from "formik";
import { TextBox } from "../../common/TextBox";
import { InputSearchLocation } from "../../common/input-search-location/InputSearchLocation";
import React, { useEffect, useState } from "react";
import { validateEmail } from "../../../utils/validations";
import IconButton from "@mui/material/IconButton";
import { AddCircle, DeleteForever, ModeEdit, RemoveCircle, Visibility } from "@mui/icons-material";
import { themeColors } from "@hoverflo/shared/api/utils/constants";
import { Dialog, Switch, DialogContent, DialogTitle } from "@mui/material";
import { HostImageDto } from "@hoverflo/shared/api/models/host";
import classNames from "classnames";
import {
    addImage,
    addTermsAndConditions,
    deleteImage,
    deleteTermsAndConditions,
    editImage,
    getHost,
    updateGeneralInfo,
    updateTermsAndConditions
} from "@hoverflo/shared/api/services/host.service";
import { errorHandler } from "../../../utils/errorHandler";

interface HostGeneralInfoProps extends Stores {
    disabled: boolean;
    onSuccess: () => void;
}

enum Mode {
    Add = "add",
    Edit = "edit",
    View = "view"
}

interface HostProfileInfo {
    name: string;
    subtitle: string;
    biography: string;
    address: string;
    email: string;
    phoneNumber: string;
    website: string;
    activeProfile: boolean;
}

export const HostGeneralInfo = inject("rootStore")(
    observer((props: HostGeneralInfoProps) => {
        const { hostStore, loadingStore, messagesStore } = props.rootStore;
        const [location, setLocation] = useState<Location>(undefined);
        const [images, setImages] = useState<HostImageDto[]>([]);
        const [tcs, setTcs] = useState<HostTermsAndConditionsDto[]>([]);
        const [showImage, setShowImage] = useState(false);
        const [showTc, setShowTc] = useState(false);
        const [tcMode, setTcMode] = useState<Mode>(Mode.Add);
        const [imageMode, setImageMode] = useState<Mode>(Mode.Add);
        const [url, setUrl] = useState("");
        const [name, setName] = useState("");
        const [agreementText, setAgreementText] = useState("");
        const [explanationText, setExplanationText] = useState("");
        const [tcsToBeDeleted, setTcsToBeDeleted] = useState<HostTermsAndConditionsDto[]>([]);
        const [imagesToBeDeleted, setImagesToBeDeleted] = useState<HostImageDto[]>([]);
        const [focusedTc, setFocusedTc] = useState<HostTermsAndConditionsDto>();

        const profileImage = hostStore.host?.images?.find(i => !!i.profileImage);

        const processImages = async () => {
            // Remove all necessary images
            await Promise.all(imagesToBeDeleted.map(image => deleteImage(image)));
            // Add all necessary images
            await Promise.all(
                hostStore.host?.images
                    ?.filter((image: HostImageDto & { isTemporary?: boolean }) => image.isTemporary)
                    .map(image => addImage({ ...image, id: undefined }))
            );
            // Update the profile image
            const profileImage = hostStore.host?.images?.find(
                (image: HostImageDto & { isTemporary?: boolean }) => !image.isTemporary && image.profileImage
            );
            if (profileImage) {
                await editImage(profileImage);
            }
        };

        const processTcs = async () => {
            // Remove all necessary tcs
            await Promise.all(tcsToBeDeleted.map(tc => deleteTermsAndConditions(tc)));
            // Add all necessary tcs
            await Promise.all(
                hostStore.host?.termsAndConditions
                    ?.filter((tc: HostTermsAndConditionsDto & { isTemporary?: boolean }) => tc.isTemporary)
                    .map(tc => addTermsAndConditions({ ...tc, id: undefined }))
            );
            // Edit the remaining tcs
            await Promise.all(
                hostStore.host?.termsAndConditions
                    ?.filter((tc: HostTermsAndConditionsDto & { isTemporary?: boolean }) => !tc.isTemporary)
                    .map(tc => updateTermsAndConditions(tc))
            );
        };

        const onSave = async ({
            name,
            subtitle,
            biography,
            email,
            phoneNumber,
            website,
            activeProfile
        }: HostProfileInfo) => {
            loadingStore.triggerLoading();
            try {
                const newGeneralInfo = {
                    ...hostStore.host.generalInfo!,
                    name,
                    subtitle,
                    biography,
                    contact: {
                        ...hostStore.host.generalInfo?.contact,
                        email,
                        phoneNumber,
                        website,
                        contactAddress: location?.address
                    },
                    activeProfile
                } as Host;
                await processTcs();
                await processImages();
                const generalInfo = await updateGeneralInfo(newGeneralInfo);
                const newHost = await getHost(generalInfo.id);
                hostStore.setHost(newHost);
                loadingStore.stopLoading();
                messagesStore.success("Profile successfully saved");
                props.onSuccess();
            } catch (e) {
                await errorHandler(e, props.rootStore!);
                loadingStore.stopLoading();
                messagesStore.error("Error occurred while saving profile.");
            }
        };

        useEffect(() => {
            if (!location) {
                setLocation(hostStore.host?.locations?.find(l => l.headquarter)?.location);
            }
        }, [hostStore.host, location]);

        useEffect(() => {
            setImages([...(hostStore.host?.images?.filter(i => !i.profileImage) ?? [])]);
        }, [hostStore.host?.images]);

        useEffect(() => {
            setTcs([...(hostStore.host?.termsAndConditions ?? [])]);
        }, [hostStore.host?.termsAndConditions]);

        return (
            <>
                <Dialog open={showImage} onClose={() => setShowImage(false)}>
                    <DialogTitle sx={{ backgroundColor: "#EDEDED", color: "#000" }}>
                        {imageMode === Mode.Add ? "Add image" : "Edit logo"}
                    </DialogTitle>
                    <DialogContent sx={{ backgroundColor: "#EDEDED" }}>
                        <label>Please enter an image URL:</label>
                        <TextBox
                            value={url}
                            onChange={event => setUrl(event.target.value)}
                            invalid={!url}
                            disabled={props.disabled}
                        />
                        <div className="save">
                            <button
                                onClick={() => {
                                    if (imageMode === Mode.Add) {
                                        hostStore.addImage({ imageUri: url, profileImage: false });
                                    } else {
                                        if (profileImage) {
                                            hostStore.updateImage({
                                                ...profileImage,
                                                imageUri: url,
                                                profileImage: true
                                            });
                                        } else {
                                            hostStore.addImage({ imageUri: url, profileImage: true });
                                        }
                                    }
                                    setUrl("");
                                    setShowImage(false);
                                    if (imageMode === Mode.Add) {
                                        messagesStore.success("Image successfully added");
                                    } else {
                                        messagesStore.success("Logo successfully saved");
                                    }
                                }}
                                disabled={!url}
                                type="button"
                                className="btn btn-orange-rounded"
                            >
                                SAVE
                            </button>
                        </div>
                    </DialogContent>
                </Dialog>
                <Dialog open={showTc} onClose={() => setShowTc(false)}>
                    <DialogTitle sx={{ backgroundColor: "#EDEDED", color: "#000", textTransform: "uppercase" }}>
                        {tcMode === Mode.Add ? "Add" : tcMode === Mode.Edit && "Edit"} Terms & Conditions
                    </DialogTitle>
                    <DialogContent sx={{ backgroundColor: "#EDEDED" }}>
                        <div className="flex-column" style={{ minWidth: "400px" }}>
                            <label>Name:</label>
                            <TextBox
                                value={name}
                                onChange={event => setName(event.target.value)}
                                invalid={!name}
                                disabled={props.disabled}
                            />
                            <label>Agreement:</label>
                            <textarea
                                disabled={props.disabled}
                                className={classNames("form-control", { "is-invalid": !agreementText })}
                                value={agreementText}
                                onChange={event => setAgreementText(event.target.value)}
                                rows={5}
                            />
                            <label>Short explanation:</label>
                            <textarea
                                disabled={props.disabled}
                                className={classNames("form-control", { "is-invalid": !agreementText })}
                                value={explanationText}
                                onChange={event => setExplanationText(event.target.value)}
                                rows={5}
                            />
                        </div>
                        {tcMode !== Mode.View && (
                            <div className="save">
                                <button
                                    onClick={() => {
                                        if (tcMode === Mode.Add) {
                                            hostStore.addTermsAndConditions({ name, agreementText, explanationText });
                                        } else {
                                            hostStore.updateTermsAndConditions({
                                                ...focusedTc,
                                                name,
                                                agreementText,
                                                explanationText
                                            });
                                        }
                                        setName("");
                                        setExplanationText("");
                                        setAgreementText("");
                                        setShowTc(false);
                                        if (tcMode === Mode.Add) {
                                            messagesStore.success("Terms & conditions successfully added");
                                        } else {
                                            messagesStore.success("Terms & conditions successfully updated");
                                        }
                                    }}
                                    disabled={!name || !agreementText || !explanationText}
                                    type="button"
                                    className="btn btn-orange-rounded"
                                >
                                    SAVE
                                </button>
                            </div>
                        )}
                    </DialogContent>
                </Dialog>
                <Formik
                    initialValues={{
                        name: hostStore.host.generalInfo?.name ?? "",
                        subtitle: hostStore.host.generalInfo?.subtitle ?? "",
                        biography: hostStore.host.generalInfo?.biography ?? "",
                        address: hostStore.host.locations?.find(l => l.headquarter)?.location?.name ?? "",
                        email: hostStore.host.generalInfo?.contact?.email ?? "",
                        phoneNumber: hostStore.host.generalInfo?.contact.phoneNumber ?? "",
                        website: hostStore.host.generalInfo?.contact.website ?? "",
                        activeProfile: hostStore.host.generalInfo?.activeProfile ?? false
                    }}
                    validate={values => {
                        let errors = {} as any;
                        if (!values.name) {
                            errors.name = "Required";
                        }
                        if (!values.address) {
                            errors.address = "Required";
                        }
                        if (!values.email) {
                            errors.email = "Required";
                        } else if (!validateEmail(values.email)) {
                            errors.email = "Invalid";
                        }

                        return errors;
                    }}
                    enableReinitialize={true}
                    onSubmit={onSave}
                >
                    {({ values, errors, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
                        <form className="general-info-form" onSubmit={handleSubmit}>
                            <h1>General Information:</h1>
                            <hr />
                            <h2>Company data</h2>
                            <div className="flex-row">
                                <label className="middle-align">Company Name</label>
                                <TextBox
                                    disabled={props.disabled}
                                    rounded={false}
                                    invalid={errors && !!errors.name}
                                    name="name"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.name}
                                    placeholder="Company name"
                                />
                            </div>
                            <div className="flex-row">
                                <label className="middle-align">Subtitle</label>
                                <TextBox
                                    disabled={props.disabled}
                                    rounded={false}
                                    invalid={errors && !!errors.subtitle}
                                    name="subtitle"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.subtitle}
                                    placeholder="Subtitle"
                                />
                            </div>
                            <hr />
                            <h2>Biography</h2>
                            <div className="flex-row">
                                <textarea
                                    disabled={props.disabled}
                                    value={values.biography}
                                    name="biography"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder="Type a short description"
                                    rows={4}
                                ></textarea>
                            </div>
                            <hr />
                            <h2>Contact information</h2>
                            <div className="flex-row">
                                {/* Fix for width difference between div and input */}
                                <label className="middle-align" style={{ width: "25%" }}>
                                    Address
                                </label>
                                <InputSearchLocation
                                    country="nl"
                                    disabled={props.disabled}
                                    onSelectLocation={location => {
                                        setLocation(location);
                                        setFieldValue("address", location.name);
                                    }}
                                    value={location?.name}
                                    placeholder="Headquarter address"
                                    rounded={false}
                                />
                            </div>
                            <div className="flex-row">
                                <label className="middle-align">Email</label>
                                <TextBox
                                    type="email"
                                    disabled={props.disabled}
                                    rounded={false}
                                    invalid={errors && !!errors.email}
                                    name="email"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.email}
                                    placeholder="Email"
                                />
                            </div>
                            <div className="flex-row">
                                <label className="middle-align">Phone number</label>
                                <TextBox
                                    disabled={props.disabled}
                                    rounded={false}
                                    invalid={errors && !!errors.phoneNumber}
                                    name="phoneNumber"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.phoneNumber}
                                    placeholder="Phone number"
                                />
                            </div>
                            <div className="flex-row">
                                <label className="middle-align">Website URL</label>
                                <TextBox
                                    disabled={props.disabled}
                                    rounded={false}
                                    invalid={errors && !!errors.website}
                                    name="website"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.website}
                                    placeholder="Website URL"
                                />
                            </div>
                            <hr />
                            <h2>Media</h2>
                            <div className="flex-row align-items-start">
                                <div className="flex-column">
                                    <h3>LOGO</h3>
                                    <div className="image-container">
                                        <div
                                            className="image"
                                            style={{
                                                backgroundImage: `url("${
                                                    profileImage?.imageUri ??
                                                    "/assets/design_files/logo/Hoverflo_LOGO_final_FEB21-black.jpg"
                                                }")`
                                            }}
                                            onClick={() => {
                                                if (!props.disabled) {
                                                    setUrl(profileImage?.imageUri ?? "");
                                                    setImageMode(Mode.Edit);
                                                    setShowImage(true);
                                                }
                                            }}
                                        >
                                            {!props.disabled && (
                                                <div className="image-action" title="Edit logo">
                                                    <ModeEdit htmlColor="#FFF" fontSize="large" />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="flex-column">
                                    <div className="flex-row align-items-center" style={{ marginTop: "-5px" }}>
                                        <h3>Gallery images</h3>
                                        <IconButton
                                            className="btn-add-image"
                                            disabled={props.disabled}
                                            title="Add image"
                                            onClick={() => {
                                                setUrl("");
                                                setImageMode(Mode.Add);
                                                setShowImage(true);
                                            }}
                                        >
                                            <AddCircle
                                                sx={{
                                                    color: !props.disabled ? themeColors.host.background : undefined
                                                }}
                                            />
                                        </IconButton>
                                    </div>
                                    <div className="flex-row flex-wrap">
                                        {images?.map(image => (
                                            <div
                                                key={image.id}
                                                className="image"
                                                style={{
                                                    backgroundColor: image?.imageUri ? undefined : "#EAEAEA",
                                                    backgroundImage: `url("${
                                                        image?.imageUri ??
                                                        "/assets/design_files/logo/Hoverflo_LOGO_final_FEB21-black.jpg"
                                                    }")`
                                                }}
                                                onClick={() => {
                                                    if (
                                                        !props.disabled &&
                                                        window.confirm("Are sure you want to remove this image?")
                                                    ) {
                                                        if (!(image as any).isTemporary) {
                                                            setImagesToBeDeleted(prev => [...prev, { ...image }]);
                                                        }
                                                        hostStore.removeImage(image);
                                                        messagesStore.success("Image successfully removed");
                                                    }
                                                }}
                                            >
                                                {!props.disabled && (
                                                    <div className="image-action" title="Remove image">
                                                        <DeleteForever htmlColor="#FFF" fontSize="large" />
                                                    </div>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <hr className="margin-top" />
                            <h2>
                                Terms &amp; Conditions{" "}
                                <IconButton
                                    disabled={props.disabled}
                                    title="Add terms & conditions"
                                    onClick={() => {
                                        setName("");
                                        setAgreementText("");
                                        setExplanationText("");
                                        setTcMode(Mode.Add);
                                        setFocusedTc(undefined);
                                        setShowTc(true);
                                    }}
                                >
                                    <AddCircle
                                        sx={{ color: !props.disabled ? themeColors.host.background : undefined }}
                                    />
                                </IconButton>
                            </h2>
                            <div className="flex-table">
                                <div className="flex-header flex-row align-items-center">
                                    <div className="flex-column flex-grow-1">Name</div>
                                    <div className="flex-column last-column-header">Options</div>
                                </div>
                                {tcs.map(tc => (
                                    <div className="flex-row align-items-center" key={tc.id}>
                                        <div className="flex-column flex-grow-1">{tc.name}</div>
                                        <div className="flex-column">
                                            <div className="flex-row align-items-center">
                                                <IconButton
                                                    title={`${props.disabled ? "View" : "Edit"} ${tc.name}`}
                                                    onClick={() => {
                                                        setName(tc.name);
                                                        setExplanationText(tc.explanationText);
                                                        setAgreementText(tc.agreementText);
                                                        setFocusedTc(tc);
                                                        setTcMode(props.disabled ? Mode.View : Mode.Edit);
                                                        setShowTc(true);
                                                    }}
                                                >
                                                    {props.disabled && (
                                                        <Visibility
                                                            sx={{
                                                                color: themeColors.host.background
                                                            }}
                                                        />
                                                    )}
                                                    {!props.disabled && (
                                                        <ModeEdit
                                                            sx={{
                                                                color: themeColors.host.background
                                                            }}
                                                        />
                                                    )}
                                                </IconButton>
                                                <IconButton
                                                    disabled={props.disabled}
                                                    title={`Remove ${tc.name}`}
                                                    onClick={() => {
                                                        if (
                                                            window.confirm(
                                                                "Are sure you want to remove this terms & conditions?"
                                                            )
                                                        ) {
                                                            if (!(tc as any).isTemporary) {
                                                                setTcsToBeDeleted(prev => [...prev, { ...tc }]);
                                                            }
                                                            hostStore.removeTermsAndConditions(tc);
                                                            messagesStore.success(
                                                                "Terms & conditions successfully removed"
                                                            );
                                                        }
                                                    }}
                                                >
                                                    <RemoveCircle
                                                        sx={{
                                                            color: !props.disabled
                                                                ? themeColors.host.background
                                                                : undefined
                                                        }}
                                                    />
                                                </IconButton>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <hr className="margin-top" />
                            <h2>Activate profile</h2>
                            <div className="flex-row align-items-center">
                                <Switch
                                    color="primary"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    name="activeProfile"
                                    checked={!!values.activeProfile}
                                />
                                <span className="switch-label">
                                    Your profile is currently{" "}
                                    <strong
                                        style={{
                                            color: values.activeProfile ? themeColors.EARTH.background : undefined
                                        }}
                                    >
                                        {values.activeProfile ? "available" : "unavailable"}
                                    </strong>{" "}
                                    for customers
                                </span>
                            </div>
                            <hr />
                            <div className="save">
                                <button
                                    disabled={props.disabled || !location || Object.keys(errors).length > 0}
                                    type="submit"
                                    className="btn btn-orange-rounded"
                                >
                                    Save
                                </button>
                            </div>
                        </form>
                    )}
                </Formik>
            </>
        );
    })
);
