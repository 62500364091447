var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
function setItem(key, value) {
    return __awaiter(this, void 0, void 0, function* () {
        const isApp = navigator && navigator.product === "ReactNative";
        if (isApp) {
            const AsyncStorage = require("@react-native-async-storage/async-storage").default;
            return AsyncStorage.setItem(key, value);
        }
        return Promise.resolve(window.localStorage.setItem(key, value));
    });
}
function getItem(key) {
    return __awaiter(this, void 0, void 0, function* () {
        const isApp = navigator && navigator.product === "ReactNative";
        if (isApp) {
            const AsyncStorage = require("@react-native-async-storage/async-storage").default;
            return AsyncStorage.getItem(key);
        }
        return Promise.resolve(window.localStorage.getItem(key));
    });
}
function removeItem(key) {
    return __awaiter(this, void 0, void 0, function* () {
        const isApp = navigator && navigator.product === "ReactNative";
        if (isApp) {
            const AsyncStorage = require("@react-native-async-storage/async-storage").default;
            return AsyncStorage.removeItem(key);
        }
        return Promise.resolve(window.localStorage.removeItem(key));
    });
}
export function setCurrentSession(session) {
    return __awaiter(this, void 0, void 0, function* () {
        if (session) {
            yield setItem("hoverflo-session", JSON.stringify(session));
        }
        else {
            yield removeItem("hoverflo-session");
        }
    });
}
export function getCurrentSession() {
    return __awaiter(this, void 0, void 0, function* () {
        const session = yield getItem("hoverflo-session");
        if (session) {
            return JSON.parse(session);
        }
        return null;
    });
}
