import { Offer, Request } from "@hoverflo/shared";
import { formatLocalizedDate } from "../../../utils";
import i18n from "../../../i18n";
import { formatCurrency } from "@hoverflo/shared/api/utils/formats";

import "./OfferDetails.scss";

interface OfferPreviewProps {
    isHost: boolean;
    offer: Offer;
    request: Request;
    onAcceptOffer: () => void;
    onRejectOffer: () => void;
    onWithdrawOffer: () => void;
}

export function OfferDetails(props: OfferPreviewProps) {
    return (
        <div className="offer-details">
            <hr />
            <div className="flex-row">
                <div className="overview">
                    <div className="flex-row">
                        <h1 className="col-md-3">Activity:</h1>
                        <h2 className="col-md-9">{props.request.hostActivity?.activity?.name}</h2>
                    </div>
                    <div className="flex-row">
                        <h1 className="col-md-3">Date:</h1>
                        <h2 className="col-md-9">
                            {formatLocalizedDate(
                                new Date(props.request.requestDate),
                                "dd-MM-yyyy",
                                (i18n.language as any) ?? "en"
                            )}
                        </h2>
                    </div>
                    <div className="flex-row">
                        <h1 className="col-md-3">Persons:</h1>
                        <h2 className="col-md-9">{props.offer?.persons}</h2>
                    </div>
                    <div className="flex-row">
                        <h1 className="col-md-3">Notice Period:</h1>
                        <h2 className="col-md-9">
                            {formatLocalizedDate(
                                new Date(props.offer?.noticePeriod),
                                "dd-MM-yyyy",
                                (i18n.language as any) ?? "en"
                            )}
                        </h2>
                    </div>
                    <div className="flex-row">
                        <h1 className="col-md-3">Location:</h1>
                        <h2 className="col-md-9">
                            {props.request.hostActivity?.locations[0].location?.address?.fullAddress}
                        </h2>
                    </div>
                    <div className="flex-row">
                        <h1 className="col-md-3">Instructor:</h1>
                        <h2 className="col-md-9">{props.offer?.instructor}</h2>
                    </div>
                    <hr />
                    <div className="flex-row">
                        <h1 className="col-md-3">Package:</h1>
                        <h2 className="col-md-9">{props.offer?.packageName}</h2>
                    </div>
                    <hr />
                    <div className="flex-row">
                        <h1 className="col-md-3">Lesson:</h1>
                        <h2 className="col-md-9">{formatCurrency(Number(props.offer?.lessonPrice ?? "0"))}</h2>
                    </div>
                    <div className="flex-row">
                        <h1 className="col-md-3">Material:</h1>
                        <h2 className="col-md-9">{formatCurrency(Number(props.offer?.materialPrice ?? "0"))}</h2>
                    </div>
                    <div className="flex-row">
                        <h1 className="col-md-3">Others:</h1>
                        <h2 className="col-md-9">{formatCurrency(Number(props.offer?.otherPrice ?? "0"))}</h2>
                    </div>
                    <hr />
                    <div className="flex-row">
                        <h1 className="col-md-3">Total Price:</h1>
                        <h2 className="col-md-9">
                            {formatCurrency(
                                Number(props.offer?.lessonPrice ?? "0") +
                                    Number(props.offer?.materialPrice ?? "0") +
                                    Number(props.offer?.otherPrice ?? "0")
                            )}
                        </h2>
                    </div>
                    <div className="flex-row">
                        <h1 className="col-md-3">Expiring date:</h1>
                        <h2 className="col-md-9">
                            {formatLocalizedDate(
                                new Date(props.offer?.expirationDate),
                                "dd-MM-yyyy",
                                (i18n.language as any) ?? "en"
                            )}
                        </h2>
                    </div>
                </div>
            </div>
            {!props.isHost && (
                <>
                    <hr />
                    <div className="flex-row buttons">
                        <button type="button" className="btn btn-orange-rounded" onClick={props.onAcceptOffer}>
                            Accept offer
                        </button>
                        <button type="button" className="btn btn-dark-host-rounded" onClick={props.onRejectOffer}>
                            Reject offer
                        </button>
                    </div>
                </>
            )}
            {props.isHost && (
                <>
                    <hr />
                    <div className="flex-row buttons">
                        <button type="button" className="btn btn-orange-rounded" onClick={props.onWithdrawOffer}>
                            Withdraw offer
                        </button>
                    </div>
                </>
            )}
        </div>
    );
}
