import { inject, observer } from "mobx-react";

import { Stepper, StepperContext } from "../../layout/stepper/Stepper";
import React, { useEffect, useState } from "react";
import { Step1 } from "./Step1";
import { Step2 } from "./Step2";
import { Step3 } from "./Step3";

import "./BecomeHost.scss";
import { Stores } from "../../../models";
import { getActivities } from "@hoverflo/shared/api/services/activity.service";
import { errorHandler } from "../../../utils/errorHandler";
import classNames from "classnames";

export const BecomeHost = inject("rootStore")(
    observer((props: Stores) => {
        const { onboardingStore, activityStore, loadingStore, messagesStore } = props.rootStore;
        const [currentStep, setCurrentStep] = useState(1);
        const [isRegistrationDone, setRegistrationDone] = useState(false);
        const [email, setEmail] = useState("");

        useEffect(() => {
            if (activityStore.activities.length === 0) {
                loadingStore.triggerLoading();
                getActivities()
                    .then(activities => {
                        activities.sort((a, b) => a.name.localeCompare(b.name));
                        activityStore.setActivities(activities);
                    })
                    .catch(async e => {
                        await errorHandler(e, props.rootStore);
                        messagesStore.error(e.message);
                    })
                    .finally(() => {
                        loadingStore.stopLoading();
                    });
            }
        }, [activityStore, loadingStore, messagesStore]);

        if (isRegistrationDone) {
            return (
                <div className="become-host__container">
                    <div className="container flex-column text-white">
                        <div className="sidebar--content_form">
                            <div>
                                <h2>Almost there</h2>
                            </div>

                            <div>
                                <h3>We have send you a verification email!</h3>
                            </div>

                            <div>
                                <h3>Please check {email ? email : "your email"} for host account activation.</h3>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        return (
            <>
                <div className="become-host__container">
                    <div className="container flex-column">
                        <div className="sidebar--content_form">
                            <div className="flex-row" id="become-host-root-container">
                                <div className="flex-column">
                                    <Stepper
                                        steps={3}
                                        currentStep={currentStep}
                                        activeSteps={onboardingStore.visibleSteps}
                                        onClick={step => {
                                            setCurrentStep(step);
                                        }}
                                    />
                                </div>
                                <div
                                    className={classNames("flex-column flex-grow-1 align-self-start", {
                                        "half-width": currentStep < 3
                                    })}
                                >
                                    <h2>Become a host</h2>
                                    <StepperContext.Provider
                                        value={{
                                            onChange: step => setCurrentStep(step)
                                        }}
                                    >
                                        {currentStep === 1 && <Step1 />}
                                        {currentStep === 2 && <Step2 />}
                                        {currentStep === 3 && (
                                            <Step3
                                                onComplete={email => {
                                                    setRegistrationDone(true);
                                                    setEmail(email);
                                                }}
                                            />
                                        )}
                                    </StepperContext.Provider>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="become-host__footer">
                    <h1>
                        Share
                        <br />
                        your passion
                        <br />
                        with the
                        <br />
                        hoverflo
                        <br />
                        community
                    </h1>
                </div>
            </>
        );
    })
);
