import { ChatMessage } from "@hoverflo/shared/api/models";
import { observable, action, makeAutoObservable } from "mobx";

export class ChatStore {
    @observable.deep messages: ChatMessage[] = [];

    constructor() {
        makeAutoObservable(this);
    }

    @action setMessages(messages: ChatMessage[]) {
        this.messages = messages;
    }

    @action clean() {
        this.messages = [];
    }

    @action addMessage(activity: ChatMessage) {
        this.messages.push(activity);
    }

    @action removeMessage(id: string) {
        const index = this.messages.findIndex(m => m.id === id);
        if (index > -1) {
            this.messages.splice(index, 1);
        }
    }
}
