import classNames from "classnames";

import "./Dropdown.scss";

interface DropdownProps {
    value: string;
    options: Array<{ value: string; label: string }>;
    onChange: (value: string) => void;
    placeholder?: string;
    className?: string;
    rounded?: boolean;
    invalid?: boolean;
    name: string;
    firstOptionDisabled?: boolean;
    sort?: boolean;
}

export function Dropdown(props: DropdownProps) {
    return (
        <select
            name={props.name}
            className={classNames(
                "form-control dropdown",
                { rounded: props.rounded, "is-invalid": props.invalid },
                props.className
            )}
            value={props.value}
            onChange={event => props.onChange(event.target.value)}
        >
            {props.placeholder && (
                <option value="" disabled={props.firstOptionDisabled}>
                    {props.placeholder}
                </option>
            )}
            {props.options
                .sort((a, b) => (props.sort ? a.label?.localeCompare(b.label) : 0))
                .map(option => (
                    <option key={option.value} value={option.value}>
                        {option.label.toUpperCase()}
                    </option>
                ))}
        </select>
    );
}
