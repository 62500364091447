import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import { Location } from "@hoverflo/shared/api/models";
import { getAutocompleteAddresses } from "@hoverflo/shared/api/services/address.service";
import { inject, observer } from "mobx-react";
import { Stores } from "../../../models";
import { debounce } from "../../../utils/debounce";
import { useOnClickOutside } from "../../../utils/useOnClickOutside";
import classNames from "classnames";
import { createPortal } from "react-dom";
import { errorHandler } from "../../../utils/errorHandler";
import { TextBox } from "../TextBox";

import "./InputSearchLocation.scss";

interface Props extends Stores {
    country: string;
    onSelectLocation: (location: Location) => void;
    placeholder?: string;
    disabled?: boolean;
    value?: string;
    portalId?: string;
    closeOnSelect?: boolean;
    rounded?: boolean;
}

export const InputSearchLocation = inject("rootStore")(
    observer((props: Props) => {
        const { loadingStore, messagesStore } = props.rootStore;
        const [locations, setLocations] = useState<Location[]>([]);
        const [location, setLocation] = useState(props.value ?? "");
        const inputRef = useRef<HTMLInputElement>();
        const listRef = useRef<HTMLUListElement>();
        const [show, setShow] = useState(false);
        const [loaded, setLoaded] = useState(false);
        useOnClickOutside([inputRef, listRef], () => setShow(false));

        useLayoutEffect(() => {
            setTimeout(() => setLoaded(true), 500);
        }, []);

        useEffect(() => {
            setLocation(props.value);
        }, [props.value]);

        const onSearch = useCallback(
            debounce((value: string) => onFilterLocation(value), 500),
            [props.country]
        );

        const onFilterLocation = (filter: string) => {
            if (!!filter.trim() && props.country) {
                setShow(true);
                loadingStore.triggerLoading();
                getAutocompleteAddresses(filter.trim().toLowerCase(), props.country)
                    .then(addresses => {
                        loadingStore.stopLoading();
                        setLocations(
                            addresses.map((address, id) => ({
                                id,
                                name: address.name,
                                coordination: {
                                    latitude: address.point.latitude,
                                    longitude: address.point.longitude
                                },
                                address: {
                                    city: address.name?.split(",")?.[0] ?? address.city,
                                    country: address.country ?? "",
                                    postalCode: address.zipCode ?? "",
                                    province: address.region ?? "",
                                    fullAddress: address.name ?? ""
                                }
                            }))
                        );
                    })
                    .catch(async e => {
                        await errorHandler(e, props.rootStore);
                        loadingStore.stopLoading();
                        messagesStore.error("Error while searching locations.");
                    });
            }
        };
        const list = (
            <ul className={classNames("location-list", { hidden: !show || locations.length === 0 })} ref={listRef}>
                {locations.map((location, index) => (
                    <li
                        key={`${location.coordination.latitude}_${location.coordination.longitude}_${index}`}
                        onClick={() => {
                            setLocation(location.name);
                            props.onSelectLocation(location);
                            props.closeOnSelect && setShow(false);
                        }}
                        onTouchStart={() => {
                            setLocation(location.name);
                            props.onSelectLocation(location);
                            props.closeOnSelect && setShow(false);
                        }}
                    >
                        {location.name}
                    </li>
                ))}
            </ul>
        );

        useEffect(() => {
            if (loaded) {
                const element = document.getElementById(props.portalId);
                if (element) {
                    if (show) {
                        element.classList.add("no-bottom-borders");
                    } else {
                        element.classList.remove("no-bottom-borders");
                    }
                }
            }
        }, [props.portalId, show, loaded]);
        return (
            <div className="location-wrapper">
                <TextBox
                    ref={inputRef}
                    disabled={props.disabled || !props.country}
                    className={show && location.length > 0 ? "no-bottom-borders" : ""}
                    placeholder={props.placeholder ?? "LOCATION"}
                    value={location}
                    onChange={event => {
                        setLocation(event.target.value);
                        onSearch(event.target.value);
                    }}
                    rounded={props.rounded}
                />
                {props.portalId && loaded ? createPortal(list, document.getElementById(props.portalId)) : list}
            </div>
        );
    })
);
