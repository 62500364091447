import { LoggedAdminComponent, LoggedComponent, NotLoggedComponent } from "../../../utils/LoggedComponent";
import { inject, observer } from "mobx-react";
import { Stores } from "../../../../models";
import i18n from "i18next";
import { Route } from "../../../../stores/sidebar.store";
import { setCurrentSession } from "@hoverflo/shared/api/utils/login.utils";

import "./AccountOverview.scss";

export const AccountOverview = inject("rootStore")(
    observer((props: Stores) => {
        const { sidebarStore, sessionStore, routingStore } = props.rootStore;
        const routeToLogin = () => sidebarStore?.changeRoute(Route.LOGIN);
        const routeToRegisterWith = () => sidebarStore?.changeRoute(Route.REGISTER_WITH);
        const logout = async () => {
            sessionStore.clearCurrentSession();
            await setCurrentSession(undefined);
            routingStore.pushRoute("/");
        };
        const changeLanguage = async (language: string) => {
            await i18n.changeLanguage(language);
        };
        return (
            <>
                <div className="account-container flex-column text-white">
                    <LoggedComponent>
                        <div className="mob-user-name">
                            <h1>Welcome {sessionStore?.session?.user?.displayName}</h1>
                        </div>
                    </LoggedComponent>
                    <div>
                        <div className="flex-row">
                            <img src="assets/Icons/Icon_account.svg" className="icon_style_white" />
                            <h2>account</h2>
                        </div>
                        <div>
                            <hr />
                        </div>
                        <NotLoggedComponent>
                            <ul>
                                <li>
                                    <a href="#" onClick={routeToLogin}>
                                        Login
                                    </a>
                                </li>
                                {/*<li>*/}
                                {/*    <a>Privacy</a>*/}
                                {/*</li>*/}
                            </ul>
                        </NotLoggedComponent>
                        <LoggedAdminComponent>
                            <ul>
                                <li>
                                    <a onClick={() => routingStore.pushRoute("/host-management")}>Manage Hosts</a>
                                </li>
                            </ul>
                        </LoggedAdminComponent>
                        {/*<LoggedComponent>*/}
                        {/*    <ul>*/}
                        {/*        <li>*/}
                        {/*            <a>Personal settings</a>*/}
                        {/*        </li>*/}
                        {/*        <li>*/}
                        {/*            <a>Notifications</a>*/}
                        {/*        </li>*/}
                        {/*        <li>*/}
                        {/*            <a>My favourites</a>*/}
                        {/*        </li>*/}
                        {/*    </ul>*/}
                        {/*</LoggedComponent>*/}
                    </div>

                    {/*<div>*/}
                    {/*    <div className="flex-row">*/}
                    {/*        <img src="assets/Icons/Icon_calander.svg" className="icon_style_white" />*/}
                    {/*        <h2>booking</h2>*/}
                    {/*    </div>*/}
                    {/*    <div>*/}
                    {/*        <hr />*/}
                    {/*    </div>*/}
                    {/*<NotLoggedComponent>*/}
                    {/*    <ul>*/}
                    {/*        <li>*/}
                    {/*            <a>How does it work?</a>*/}
                    {/*        </li>*/}
                    {/*    </ul>*/}
                    {/*</NotLoggedComponent>*/}
                    {/*<LoggedComponent>*/}
                    {/*    <ul>*/}
                    {/*        <li>*/}
                    {/*            <a>Dashboard</a>*/}
                    {/*        </li>*/}
                    {/*    </ul>*/}
                    {/*</LoggedComponent>*/}
                    {/*</div>*/}

                    {/*<div>*/}
                    {/*    <div className="flex-row">*/}
                    {/*        <img src="assets/Icons/Icon_wallet.svg" className="icon_style_white" />*/}
                    {/*        <h2>wallet</h2>*/}
                    {/*    </div>*/}
                    {/*    <div>*/}
                    {/*        <hr />*/}
                    {/*    </div>*/}
                    {/*    <NotLoggedComponent>*/}
                    {/*        <ul>*/}
                    {/*            <li>*/}
                    {/*                <a>Safety payments</a>*/}
                    {/*            </li>*/}
                    {/*            <li>*/}
                    {/*                <a>How does it work</a>*/}
                    {/*            </li>*/}
                    {/*            <li>*/}
                    {/*                <a>Payment Types</a>*/}
                    {/*            </li>*/}
                    {/*        </ul>*/}
                    {/*    </NotLoggedComponent>*/}
                    {/*    <LoggedComponent>*/}
                    {/*        <ul>*/}
                    {/*            <li>*/}
                    {/*                <a>Booking</a>*/}
                    {/*            </li>*/}
                    {/*            <li>*/}
                    {/*                <a>Payments</a>*/}
                    {/*            </li>*/}
                    {/*            <li>*/}
                    {/*                <a>Balance</a>*/}
                    {/*            </li>*/}
                    {/*            <li>*/}
                    {/*                <a>Settings</a>*/}
                    {/*            </li>*/}
                    {/*        </ul>*/}
                    {/*    </LoggedComponent>*/}
                    {/*</div>*/}

                    {/*<div>*/}
                    {/*    <div className="flex-row">*/}
                    {/*        <img src="assets/Icons/Icon_how%20to.svg" className="icon_style_white" />*/}
                    {/*        <h2>how to</h2>*/}
                    {/*    </div>*/}
                    {/*    <div>*/}
                    {/*        <hr />*/}
                    {/*    </div>*/}
                    {/*    <ul>*/}
                    {/*        <li>*/}
                    {/*            <a>How does Hoverflo work?</a>*/}
                    {/*        </li>*/}
                    {/*        <li>*/}
                    {/*            <a>How to become a host?</a>*/}
                    {/*        </li>*/}
                    {/*        <li>*/}
                    {/*            <a>FAQ</a>*/}
                    {/*        </li>*/}
                    {/*        <li>*/}
                    {/*            <a>Support</a>*/}
                    {/*        </li>*/}
                    {/*        <LoggedComponent>*/}
                    {/*            <li>*/}
                    {/*                <a>Ask community</a>*/}
                    {/*            </li>*/}
                    {/*        </LoggedComponent>*/}
                    {/*    </ul>*/}
                    {/*</div>*/}

                    <div>
                        <div className="flex-row">
                            <img src="assets/Icons/Icon_languages.svg" className="icon_style_white" />
                            <h2>language</h2>
                        </div>
                        <div>
                            <hr />
                        </div>
                        <ul>
                            <li>
                                <a onClick={() => changeLanguage("nl")}>Nederlands</a>
                            </li>
                            <li>
                                <a onClick={() => changeLanguage("en")}>English</a>
                            </li>
                            <li>
                                <a onClick={() => changeLanguage("de")}>Deutsche</a>
                            </li>
                        </ul>
                    </div>
                    <NotLoggedComponent>
                        <button className="btn btn-rounded" onClick={routeToLogin}>
                            log in
                        </button>
                        <button className="btn btn-rounded-dark" onClick={routeToRegisterWith}>
                            register
                        </button>
                        <button
                            type="button"
                            className="btn btn-host"
                            onClick={() => routingStore.pushRoute("/become-host")}
                        >
                            become a host
                        </button>
                    </NotLoggedComponent>
                    <LoggedComponent>
                        <button className="btn btn-rounded-dark" onClick={logout}>
                            log out
                        </button>
                    </LoggedComponent>
                </div>
            </>
        );
    })
);
