import React, { useEffect } from "react";
import { Redirect, Route, RouteProps, Switch } from "react-router-dom";
import { inject, observer } from "mobx-react";
import { Stores } from "../models";
import { Session } from "@hoverflo/shared";
import { LandingPage } from "./screens/landing-page/LandingPage";
import { Activities } from "./screens/activities/Activities";
import { Explore } from "./screens/explore/Explore";
import { Locations } from "./screens/locations/Locations";
import { Bookings } from "./screens/bookings/Bookings";
import { BecomeHost } from "./screens/become-host/BecomeHost";
import { HostProfile } from "./screens/host-profile/HostProfile";
import { HostOffers } from "./screens/host-offers/HostOffers";
import { LoggedAdminComponent, LoggedHostComponent, LoggedUserComponent } from "./utils/LoggedComponent";
import { HostBookings } from "./screens/host-bookings/HostBookings";
import { HostManagement } from "./screens/host-management/HostManagement";

export const Routes = () => {
    return (
        <Switch>
            <Route exact path="/">
                <LandingPage />
            </Route>
            <Route exact path="/email-verification">
                <LandingPage />
            </Route>
            <Route path="/activities">
                <Activities />
            </Route>
            <Route path="/explore">
                <Explore />
            </Route>
            <Route path="/locations">
                <Locations />
            </Route>
            <Route path="/become-host">
                <BecomeHost />
            </Route>
            <PrivateRoute path="/bookings">
                <LoggedUserComponent>
                    <Bookings />
                </LoggedUserComponent>
            </PrivateRoute>
            <PrivateRoute path="/host-profile">
                <LoggedHostComponent>
                    <HostProfile />
                </LoggedHostComponent>
            </PrivateRoute>
            <PrivateRoute path="/host-offers">
                <LoggedHostComponent>
                    <HostOffers />
                </LoggedHostComponent>
            </PrivateRoute>
            <PrivateRoute path="/host-bookings">
                <LoggedHostComponent>
                    <HostBookings />
                </LoggedHostComponent>
            </PrivateRoute>
            <PrivateRoute path="/host-management">
                <LoggedAdminComponent>
                    <HostManagement />
                </LoggedAdminComponent>
            </PrivateRoute>
        </Switch>
    );
};

const PrivateRoute = inject("rootStore")(
    observer(({ children, rootStore: { sessionStore, messagesStore }, ...rest }: RouteProps & Stores) => {
        // Custom check, do not remove this piece of code
        const localStorageSession = window.localStorage.getItem("hoverflo-session");
        useEffect(() => {
            const session = localStorageSession ? (JSON.parse(localStorageSession) as Session) : undefined;
            if (!sessionStore.logged && !session?.accessToken) {
                messagesStore.warning("Please login to continue");
            } else {
                sessionStore.setCurrentSession(session);
            }
        }, []);

        return (
            <Route
                {...rest}
                render={({ location }) =>
                    sessionStore.logged || localStorageSession ? (
                        children
                    ) : (
                        <Redirect
                            to={{
                                pathname: "/",
                                state: { from: location }
                            }}
                        />
                    )
                }
            />
        );
    })
);
