import { inject, observer } from "mobx-react";
import React, { useState } from "react";
import { Stores } from "../../../../models";
import { Formik, FormikHelpers } from "formik";
import { Route } from "../../../../stores/sidebar.store";
import Checkbox from "@mui/material/Checkbox";

import "./Register.scss";
import { themeColors } from "@hoverflo/shared/api/utils/constants";
import { findUserByEmail, register } from "@hoverflo/shared/api/services/user.service";
import { validateEmail } from "../../../../utils/validations";
import { TextBox } from "../../../common/TextBox";

export const Register = inject("rootStore")(
    observer((props: Stores) => {
        const { loadingStore, messagesStore, sidebarStore } = props.rootStore;
        const [isRegistrationDone, setRegistrationDone] = useState(false);
        const [email, setEmail] = useState("");
        const onSubmit = async (
            values,
            {
                setErrors
            }: FormikHelpers<{
                email: string;
                password;
                firstName: string;
                lastName: string;
                confirmPassword: string;
                agree: boolean;
            }>
        ) => {
            const duplicated = await findUserByEmail(values.email);

            if (duplicated) {
                setErrors({ email: `An user with email ${values.email} already exists.` });
                messagesStore.error(`An user with email ${values.email} already exists.`);
            } else {
                try {
                    loadingStore.triggerLoading();
                    await register({
                        email: values.email,
                        password: values.password,
                        firstName: values.firstName,
                        lastName: values.lastName
                    });
                    loadingStore.stopLoading();
                    messagesStore.success("Registration successfully done");
                    setEmail(email);
                    setRegistrationDone(true);
                } catch (e: any) {
                    loadingStore.stopLoading();
                    messagesStore.error(e.message);
                    setRegistrationDone(false);
                }
            }
        };

        const routeToAccountOverview = () => sidebarStore.changeRoute(Route.ACCOUNT_OVERVIEW);

        if (isRegistrationDone) {
            return (
                <div className="register-container flex-column text-white">
                    <div>
                        <h1>Almost there</h1>
                    </div>

                    <div>
                        <hr />
                    </div>

                    <div>
                        <h2>
                            We have send you <br />a verification email
                        </h2>
                    </div>

                    <div>
                        <h3>
                            Please check {email} <br />
                            for account activation
                            <br />
                        </h3>
                    </div>

                    <div>
                        <hr />
                    </div>
                </div>
            );
        }

        return (
            <Formik
                initialValues={{
                    firstName: "",
                    lastName: "",
                    email: "",
                    password: "",
                    confirmPassword: "",
                    agree: false
                }}
                validate={async values => {
                    let errors: any = {};
                    if (!values.firstName) {
                        // errors.firstName = t("form.errors.required");
                        errors.firstName = "Required";
                    }
                    if (!values.lastName) {
                        // errors.lastName = t("form.errors.required");
                        errors.lastName = "Required";
                    }
                    if (!values.email) {
                        // errors.email = t("form.errors.required");
                        errors.email = "Required";
                    } else if (!validateEmail(values.email)) {
                        // errors.email = t("form.errors.invalid");
                        errors.email = "Invalid";
                    }
                    if (!values.password) {
                        // errors.password = t("form.errors.required");
                        errors.password = "Required";
                    } else {
                        const hasUpperCase = /[A-Z]+/.test(values.password);
                        const hasLowerCase = /[a-z]+/.test(values.password);
                        const hasNumeric = /\d+/.test(values.password);
                        const hasMinimum8 = values.password.length >= 8;

                        const passwordValid = hasUpperCase && hasLowerCase && hasNumeric && hasMinimum8;

                        if (!passwordValid) {
                            errors.password =
                                "Your password must have at least 8 characters containing one upper case (A) and a numeric characters.";
                        }
                    }
                    if (!values.confirmPassword) {
                        // errors.confirmPassword = t("form.errors.required");
                        errors.confirmPassword = "Required";
                    } else {
                        if (values.confirmPassword !== values.password) {
                            errors.confirmPassword = "Passwords do not match";
                        }
                    }
                    if (!values.agree) {
                        // errors.agree = t("form.errors.required");
                        errors.agree = "Required";
                    }
                    return errors;
                }}
                onSubmit={onSubmit}
            >
                {({ values, errors, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
                    <div className="register-container flex-column text-white">
                        <form onSubmit={handleSubmit}>
                            <div>
                                <h1>create account</h1>
                            </div>

                            <div>
                                <hr />
                            </div>

                            <div>
                                <TextBox
                                    invalid={errors && !!errors.firstName}
                                    type="text"
                                    name="firstName"
                                    placeholder="First name"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values && values.firstName}
                                />
                            </div>

                            <div>
                                <TextBox
                                    invalid={errors && !!errors.lastName}
                                    type="text"
                                    name="lastName"
                                    placeholder="Last name"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values && values.lastName}
                                />
                            </div>

                            <div>
                                <TextBox
                                    invalid={errors && !!errors.email}
                                    errorMessage={errors.email as string}
                                    type="email"
                                    name="email"
                                    placeholder="email"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values && values.email}
                                />
                            </div>

                            <div>
                                <TextBox
                                    invalid={errors && !!errors.password}
                                    errorMessage={errors.password as string}
                                    type="password"
                                    name="password"
                                    placeholder="Password"
                                    minLength={6}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values && values.password}
                                />
                            </div>

                            <div>
                                <TextBox
                                    invalid={errors && !!errors.confirmPassword}
                                    errorMessage={errors.confirmPassword as string}
                                    type="password"
                                    name="confirmPassword"
                                    placeholder="Confirm password"
                                    minLength={6}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values && values.confirmPassword}
                                />
                            </div>

                            <div className="container flex-row agree">
                                <Checkbox
                                    name="agree"
                                    onChange={event => setFieldValue("agree", event.target.checked)}
                                    value={values.agree}
                                    sx={{
                                        color: errors.agree ? "#dc3545" : undefined,
                                        "&.Mui-checked": {
                                            color: themeColors.default.primary
                                        }
                                    }}
                                />
                                <p className="col-11">
                                    By creating a Hoverflo account,
                                    <br />
                                    I understand and agree to Hoverflo's Privacy
                                    <br />
                                    Notice and Terms of Use(Required)
                                    <br />
                                </p>
                            </div>

                            <div>
                                <button
                                    type="submit"
                                    className="btn btn-rounded"
                                    disabled={Object.keys(errors).length > 0}
                                >
                                    create account
                                </button>
                            </div>

                            <div>
                                <button className="btn btn-rounded" onClick={routeToAccountOverview}>
                                    cancel
                                </button>
                            </div>

                            <div>
                                <hr className="hr2" />
                            </div>
                        </form>
                    </div>
                )}
            </Formik>
        );
    })
);
